import {UntypedFormControl} from '@angular/forms';
import {states} from '../shared/enums/states';
import {statesEnum} from '../shared/enums/statesEnum';
import {StateNameSpace} from '../shared/enums/stateList-enum';

export class PowerSearchValidator {
    static validAddressFormat(fc: UntypedFormControl) {
        if (fc.value && fc.value !== '') {
            const addressArray = fc.value.toString().split(',');
            const streetAddress = addressArray[0].toString().split(' ');
            const streetNumber = (streetAddress[0]) ? streetAddress[0].trim() : '';
            const state = (addressArray[2]) ? addressArray[2].trim() : '';
            if (addressArray.length !== 3) {
                return ({validAddressFormat: true});
            } else if (PowerSearchValidator.validStreetAddress(streetNumber)) {
                return ({validStreetAddress: true});
            } else if (state.length < 2) {
                return ({validStateCode: true});
            } else if (state.length === 2 && !states.stateList.includes(state.toUpperCase())) {
                return ({validStateCode: true});
            } else if (state.length > 2) {
                const zip = state.slice(state.lastIndexOf(' ') + 1);
                if (parseInt(zip)) {
                    const stateName = state.substring(0, state.lastIndexOf(' '));
                    if (!states.stateList.includes(stateName.toUpperCase()) && !statesEnum.stateList.includes(stateName)) {
                        return ({validStateCode: true});
                    } else {
                        return (null);
                    }
                } else {
                    if (!states.stateList.includes(state.toUpperCase()) && !statesEnum.stateList.includes(state)) {
                        return ({validStateCode: true});
                    } else {
                        return (null);
                    }
                }
            } else {
                return (null);
            }
        }
    }

    static validVehicle(fc: UntypedFormControl) {
        if (fc.value) {
            const input = fc.value;
            const format = /[!@#$%^&*()_+\-=\[\]{};':"\\|.<>\/?]/;
            if (format.test(input)) {
                return ({validSpecialChar: true});
            } else {
                let stateEntered;
                let state;
                let inputParts;
                if (input) {
                    inputParts = input.split(",");
                }
                if (inputParts[1]) {
                    stateEntered = inputParts[1].trim();
                    if (inputParts.length > 2) {
                        for (let i = 2; i < inputParts.length; i++) {
                            stateEntered += " " + inputParts[i];
                        }
                    }
                    state = StateNameSpace.getStateByStateCode(stateEntered);
                    if (!state) {
                        state = StateNameSpace.getStateByStateName(stateEntered);
                    }
                    if (state) {
                        return (null);
                    } else {
                        return ({validVehicle: true});
                    }
                } else {
                    return (null);
                }
            }
        }
    }

    static validPhone(fc: UntypedFormControl) {
        if (fc.value) {
            const input = fc.value;
            const format = /[!@#$%^&*_,+\=\[\]{};':"\\|.<>\/?]/;
            if (format.test(input)) {
                return ({validSpecialChar: true});
            } else {
                if (input.match(/[a-z]/i)) {
                    return ({validSpecialChar: true});
                } else {
                    const number = input.replace(/[^A-Z0-9]/ig, '');
                    if (number.length>=10 && number.length<=15) {
                        return null;
                    } else {
                        return ({validNumberGreaterThan15: true});
                    }
                }
            }
        }
    }

    static validName(fc: UntypedFormControl) {
        if (fc.value) {
            const input = fc.value;
            if (!input.match(/^[a-zA-Z0-9 ]+$/)) {
                return ({validSpecialChar: true});
            }

        }

    }

    static validMiddleName(fc: UntypedFormControl) {
        if (fc.value) {
            const input = fc.value;
            if (!input.match(/^[a-zA-Z0-9 ]+$/)) {
                return ({validMiddleName: true});
            }

        }
    }

    static validDob(fc: UntypedFormControl) {

        if (fc.value) {
            let input = fc.value.toLocaleString().split("/")
            let input1 = fc.value.toLocaleString().split(",")
            let year = input[2].toLocaleString().split(",")

            if (year[0].length > 4 || year[0].length < 4 || input1[0].length < 8 || input1[0].length > 10) {

                return ({validDob: true});
            }
        } else
            return ({validDob: true});
    }

    static validSSN(fc: UntypedFormControl) {

        if (fc.value) {
            const input = fc.value.toString();
            if (input.length > 9 || input.length < 9 || !input.match("^[0-9]+$")) {
                return ({validSsn: true});
            }
        }
    }

    static validSSNData(fc: UntypedFormControl) {

        if (fc.value) {
            const input = fc.value.toString();
            if (input.length == 0) {
                return ({validSsnData: true});
            }
        }
    }

    static validPersonCity(fc: UntypedFormControl) {
        return ({validPersonCity: false});
    }

    static validStreetAddress(input: string) {
        return !/^[0-9_.-]*$/.test(input);
    }

}
