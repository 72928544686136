// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.secret {
  text-align: center;
  font-family: courier;
  border-width: 2px;
  border-radius: 8px;
  font-size: 120%;
  border-style: solid;
  margin: 0 auto;
  background-color: #bababd;
}

.form-fields-width {
  width: 100%;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.mat-button-toggle-group {
  align-items: center;
  font-size: 14px;
  height: 40px;
}

.fa-industry {
  color: blue;
}

.fa-shield-alt {
  color: red;
}

.fa-tools {
  color: green;
}`, "",{"version":3,"sources":["webpack://./src/app/organizations/update-organization/update-organization.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,oBAAA;EACA,iBAAA;EACA,kBAAA;EACA,eAAA;EACA,mBAAA;EACA,cAAA;EACA,yBAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA,6HAAA;AACA;EACE,mBAAA;EACA,eAAA;EACA,YAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,UAAA;AACF;;AAEA;EACE,YAAA;AACF","sourcesContent":[".secret {\n  text-align: center;\n  font-family: courier;\n  border-width: 2px;\n  border-radius: 8px;\n  font-size: 120%;\n  border-style: solid;\n  margin: 0 auto;\n  background-color: #bababd;\n}\n\n.form-fields-width {\n  width: 100%;\n}\n\n/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */\n.mat-button-toggle-group {\n  align-items: center;\n  font-size: 14px;\n  height: 40px;\n}\n\n.fa-industry {\n  color: blue;\n}\n\n.fa-shield-alt {\n  color: red;\n}\n\n.fa-tools {\n  color: green;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
