export enum OrgEntitlementsEnum {
    COMMERCIAL_LOCATE = 'COMMERCIAL_LOCATE',
    COMMERCIAL_ADDRESS = 'COMMERCIAL_ADDRESS',
    COMMERCIAL_PHONE = 'COMMERCIAL_PHONE',
    COMMERCIAL_VEHICLE = 'COMMERCIAL_VEHICLE',
    SAFETY_SHIELD = 'SAFETY_SHIELD',
    WEB_CRAWLING = 'WEB_CRAWLING',
    DBH = "DBH",
    HOME_DATA = "HOME_DATA",
    IOT_CREATE = "IOT_CREATE",
    IOT_GATEWAY = "IOT_GATEWAY",
    SHARE_RESULTS = "SHARE_RESULTS"
}
