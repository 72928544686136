// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fas {
  font-size: 14px;
  font-style: inherit;
  font-family: "Font Awesome 5 Free", sans-serif;
  display: revert;
  vertical-align: text-bottom;
}

.fa-search-plus {
  font-size: 24px;
}`, "",{"version":3,"sources":["webpack://./src/app/search-types/list-search-types/list-search-types.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,mBAAA;EACA,8CAAA;EACA,eAAA;EACA,2BAAA;AACF;;AAEA;EACE,eAAA;AACF","sourcesContent":[".fas {\n  font-size: 14px;\n  font-style: inherit;\n  font-family: 'Font Awesome\\ 5 Free', sans-serif;\n  display: revert;\n  vertical-align: text-bottom;\n}\n\n.fa-search-plus {\n  font-size: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
