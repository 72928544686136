import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

declare let google: any;

@Component({
    selector: 'app-map-hide-markers',
    templateUrl: './map-hide-markers.component.html',
    styleUrls: ['./map-hide-markers.component.scss']
})
export class MapHideMarkersComponent implements OnChanges {

    @Input() map: google.maps.Map;

    markerOptions: any = [{name: "hideAllMarkers", displayName: "Hide All", checked: false}, 
                        {name: "hideBusinessMarkers", displayName: "Hide Businesses", checked: true}];
    
    styles: any = {
        hideAllMarkers: { 
            featureType: "poi",
            elementType: "markers",
            stylers: [{ visibility: "off" }]
        },
        hideBusinessMarkers: { 
            featureType: "poi.business",
            elementType: "markers",
            stylers: [{ visibility: "off" }],
        }
    };

    defaultStyles: any[] = [this.styles["hideBusinessMarkers"]];
    
    constructor() { }

    ngOnChanges(changes: SimpleChanges): void {
        if(this.map) {
            this.map.setOptions({ styles: this.defaultStyles });
            this.map.controls[google.maps.ControlPosition.RIGHT_TOP].push(document.getElementById('markerOptions'));
        }
    }

    changeMarkerOption(checked, option) {
        let styles = [];
        this.markerOptions.forEach( marker => {
            if(option.name === marker.name) {
                marker.checked = checked;
            }
        });
        this.markerOptions.forEach( option => {
            if(option.checked) {
                styles.push(this.styles[option.name]);
            }
        });
        this.map.setOptions({ styles: styles });
    }

}
