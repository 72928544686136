import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SearchTypesService } from 'src/app/search-types/search-type.service';
import { Alerts } from 'src/app/shared/enums/alerts';
import { SearchTypeTo } from 'src/app/shared/models/searchTypeTo';
import { TextBlock } from 'src/app/shared/models/textBlock';
import { SharedService } from 'src/app/shared/shared.service';
import { TextBlockBase } from '../text-block-base';
import { TextBlocksService } from '../text-blocks.service';

import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
    selector: 'app-update-text-blocks',
    templateUrl: './update-text-blocks.component.html',
    styleUrls: ['./update-text-blocks.component.scss']
})
export class UpdateTextBlocksComponent extends TextBlockBase implements OnInit {

    options: UntypedFormGroup;
    resetData: TextBlock;
    textBlock: TextBlock;
    searchTypeList: Array<SearchTypeTo> = [];
    loading: boolean = false;
    
    constructor(@Inject(MAT_DIALOG_DATA) private data: TextBlock,
                private fb: UntypedFormBuilder,
                private textBlocksService: TextBlocksService, 
                public dialogRef: MatDialogRef<UpdateTextBlocksComponent>,
                protected sharedService: SharedService,
                private searchTypesService: SearchTypesService) {
        super();
        this.options = this.fb.group({
            hideRequired: false,
            floatLabel: 'auto',
        });
    }

    ngOnInit() {
        this.getAllSearchTypes();
        this.textBlock = new TextBlock(this.data);
        this.resetData = new TextBlock(this.data);
    }

    getAllSearchTypes() {
        this.loading = true;
        this.searchTypesService.getAllSearchTypes().subscribe({
            next: data => {
                this.searchTypeList = data;
                this.loading = false;
            },
            error: error => {
                this.loading = false;
            },
            complete: () => { }
        });
    }

    change(event) {
        if (event.srcElement.value !== '') {
            this.count = this.count + 1;
        } else if (event.srcElement.value === '') {
            this.count = this.count - 1;
        }
        if (this.count <= 1 || this.fieldcheck === false) {
            this.fieldcheck = !this.fieldcheck;
        }
    }

    updateTextBlock() {
        if(this.isInValidData(this.textBlock)) {
            return;
        }

        if(this.resetData.name !== this.textBlock.name || 
            this.resetData.description !== this.textBlock.description) {
            this.loading = true;

            let mappedSearchTypes: Array<string> = [];
            this.searchTypeList.forEach(searchType => {
                let isMapped: boolean = false;
                if(this.resetData.name !== this.textBlock.name) {
                    searchType.textblocksFileList.forEach(textblock => {
                        if(textblock.name === this.resetData.name) {   
                            isMapped = true;
                        }
                    });
                }
                if(isMapped) {
                    mappedSearchTypes.push(searchType.name);
                }
            });
                

            if (mappedSearchTypes.length === 0) {
                this.textBlocksService.updateTextBlock(this.textBlock).subscribe({
                    next: data => {
                        this.loading = false;
                        this.sharedService.showAlert(Alerts.SUCCESS, 'Text Block updated successfully');
                        this.dialogRef.close(true);
                    },
                    error: error => {
                        this.loading = false;
                        this.dialogRef.close(false);
                        this.sharedService.handleErrors(error,
                            [{
                                "condition": (error.status == 400 && error.error),
                                "msg": error.error
                            }],
                            "Unable to update Text Block");
                    },
                    complete: () => { }
                });
            } else {
                this.loading = false;
                this.dialogRef.close(false);
                this.sharedService.showConfirmAlert('error', "Could not update textblock as it has already been mapped to below search types, [" + mappedSearchTypes + "]");
            }
            
        } else {
            this.sharedService.showAlert(Alerts.ERROR, 'Please modify the details to update');
            this.markFormControlsAsTouched();
        }
    }

    resetTextBlock() {
        if (this.fieldcheck === true) {
            Swal.fire({
                title: 'Are you sure?',
                text: 'Your changes will be lost!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes, Reset',
                cancelButtonText: 'No, Cancel',
            }).then((result) => {
                if (result.value) {
                    this.textBlock = new TextBlock(this.resetData);
                    this.fieldcheck = false;
                }
            });
        } else {
            this.textBlock = new TextBlock(this.resetData);
        }
    }

    close() {
        if (this.fieldcheck === true) {
            Swal.fire({
                title: 'Are you sure?',
                text: 'Your changes will not be saved !',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes, Close',
                cancelButtonText: 'No, Cancel',
            }).then((result) => {
                if (result.value) {
                    this.dialogRef.close(false);
                }
            });
        } else {
            this.dialogRef.close(false);
        }
    }
}
