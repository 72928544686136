import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {NavigationService} from '../../navigation/navigation.service';
import {SharedService} from '../../shared/shared.service';
import { TextBlocksService } from '../text-blocks.service';
import { CreateTextBlockComponent } from '../create-text-block/create-text-block.component';
import { UpdateTextBlocksComponent } from '../update-text-blocks/update-text-blocks.component';
import { TextBlock } from 'src/app/shared/models/textBlock';
import { Subscription } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { SearchTypesService } from 'src/app/search-types/search-type.service';
import { SearchTypeTo } from 'src/app/shared/models/searchTypeTo';

import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
    selector: 'app-list-text-blocks',
    templateUrl: './list-text-blocks.component.html',
    styleUrls: ['./list-text-blocks.component.scss']
})
export class ListTextBlocksComponent implements OnInit {

    dialogRef;
    textBlockList: Array<TextBlock> = [];
    searchTypeList: Array<SearchTypeTo> = [];
    displayedColumns: string[] = ['name', 'options'];
    dataSource = new MatTableDataSource<TextBlock>(this.textBlockList);
    isLoading = false;
    isDeletingTextBlock = false;
    displayFailureMsg = false;
    errorMsg: Map<string, string>;
    selection = new SelectionModel<TextBlock>(true, []);
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    paginatorLength: number;
    private textBlockServiceSubscription: Subscription;

    constructor(public dialog: MatDialog, 
                public navigation: NavigationService,
                public textBlocksService: TextBlocksService,
                public sharedService: SharedService, 
                private cdref: ChangeDetectorRef,
                private searchTypesService: SearchTypesService) {
        this.textBlockServiceSubscription = this.textBlocksService.updateTextBlockList$.subscribe(
            data => {
                this.getAllTextBlocks();
            }
        );
    }

    ngOnInit() {
        this.getAllSearchTypes();
        this.getAllTextBlocks();
    }

    getAllTextBlocks() {
        this.isLoading = true;
        this.textBlocksService.getAllTextBlocks().subscribe({
            next: data => {
                this.textBlockList = data;
                this.setTextBlockList();
            },
            error: error => {
                this.displayFailureMsg = true;
                this.isLoading = false;
            },
            complete: () => { }
        });
    }

    getAllSearchTypes() {
        this.isLoading = true;
        this.searchTypesService.getAllSearchTypes().subscribe({
            next: data => {
                this.searchTypeList = data;
            },
            error: error => {
                this.displayFailureMsg = true;
                this.isLoading = false;
            },
            complete: () => { }
        });
    }

    ngAfterContentChecked() {
        this.cdref.detectChanges();
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    setTextBlockList() {
        if (this.textBlockList.length > 20) {
            this.paginatorLength = this.textBlockList.length;
        } else {
            this.paginatorLength = 20;
        }
        const currentfilter = this.dataSource.filter;
        this.dataSource = new MatTableDataSource<TextBlock>(this.textBlockList);
        this.dataSource.filter = currentfilter;
        this.dataSource.filterPredicate = (textBlock: TextBlock, filter: any) => textBlock.name.trim().toLowerCase().indexOf(filter) !== -1;
        setTimeout(() => this.dataSource.paginator = this.paginator);

        this.dataSource.sortingDataAccessor = (textBlock: TextBlock, sortHeaderId: string): string => {
            if (typeof textBlock[sortHeaderId] === 'string') {
                return textBlock[sortHeaderId].toLowerCase();
            }
            return textBlock[sortHeaderId];
        };
        this.dataSource.sort = this.sort;
        this.isLoading = false;
        this.displayFailureMsg = false;
    }

    createTextBlock() {
        this.dialogRef = this.dialog.open(CreateTextBlockComponent);
        this.dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.getAllTextBlocks();
            }
        });
    }

    editTextBlock(object) {
        this.dialogRef = this.dialog.open(UpdateTextBlocksComponent, {
            data: object
        });
        this.dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.getAllTextBlocks();
            }
        });
    }

    deleteTextBlock(object: any) {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Delete the Text Block "' + object.name + '"',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Yes, Delete',
            cancelButtonText: 'No, cancel',
            showLoaderOnConfirm: true
        }).then((result: any) => {
            if (result.value) {
                this.isDeletingTextBlock = true;

                let mappedSearchTypes: Array<string> = [];
                this.searchTypeList.forEach(searchType => {
                    let isMapped: boolean = false;
                    searchType.textblocksFileList.forEach(textblock => {
                        if (textblock.name === object.name) {
                            isMapped = true;
                        }
                    });
                    if (isMapped) {
                        mappedSearchTypes.push(searchType.name);
                    }
                });

                if (mappedSearchTypes.length === 0) {
                    this.textBlocksService.deleteTextBlock(object.name).subscribe({
                        next: data => {
                            this.isDeletingTextBlock = false;
                            this.sharedService.showAlert('success', 'Text Block "' + object.name + '" has been deleted.');
                            this.textBlocksService.updateTextBlockList.next();
                        },
                        error: error => {
                            this.isDeletingTextBlock = false;
                            this.sharedService.handleErrors(error,
                                [{
                                    "condition": (error.status == 400 && error.error),
                                    "msg": error.error
                                }],
                                "Failed to delete " + object.name + " Text Block.");
                            this.textBlocksService.updateTextBlockList.next();
                        }
                    });
                } else {
                    this.isDeletingTextBlock = false;
                    this.sharedService.showConfirmAlert('error', "Could not delete '" + object.name + "' as it has already been mapped to below search types, [" + mappedSearchTypes + "]");
                }
            }
        });
    }

    
    syncPrimaryPaginator(event: PageEvent) {
        this.paginator.pageIndex = event.pageIndex;
        this.paginator.pageSize = event.pageSize;
        this.paginator.page.emit(event);
    }

}
