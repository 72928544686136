import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, FormControl, UntypedFormGroup, Validators} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import {ProvidersService} from '../provider.service';
import {SharedService} from '../../shared/shared.service';
import {SearchTypesService} from '../../search-types/search-type.service';
import {Alerts} from '../../shared/enums/alerts';
import {ProviderTo} from 'src/app/shared/models/providerTo';
import {ProviderBase} from "../provider-base";
import { EntitlementTo } from 'src/app/shared/models/entitlementTo';
import { EntitlementsService } from 'src/app/entitlements/entitlements.service';
import { AuthType } from 'src/app/shared/enums/authType';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { SupplementalDataProviderTo } from 'src/app/shared/models/supplementalDataProviderTo';

@Component({
    selector: 'app-update-provider',
    templateUrl: './update-provider.component.html',
    styleUrls: ['./update-provider.component.scss']
})
export class UpdateProviderComponent extends ProviderBase implements OnInit {
   
    @ViewChild('autosize') autosize: CdkTextareaAutosize;

    options: UntypedFormGroup;
    resetData : ProviderTo;
    loading: boolean = false;
    preferenceList: Array<String> = [];
    entitlementList: Array<EntitlementTo> = [];

    constructor(@Inject(MAT_DIALOG_DATA) private data: any, 
                private fb: UntypedFormBuilder,
                private providersService: ProvidersService,
                public dialogRef: MatDialogRef<UpdateProviderComponent>,
                protected sharedService: SharedService,
                private searchTypesService: SearchTypesService,
                public entitlementService: EntitlementsService) {
        super(sharedService);
    }

    ngOnInit() {
        this.getAllEntitlements();   
        this.getSupplementalDataProviders(this.data.supplementalDataProviders);     
        this.provider = new ProviderTo(this.data.selectedProvider);
        this.resetData = new ProviderTo(this.data.selectedProvider);
        this.supplementalDataProvidersDataSource = new MatTableDataSource<SupplementalDataProviderTo>(this.provider.supplementalDataProviders);
    }

    getAllEntitlements() {
        this.entitlementService.getAllEntitlementDef().subscribe({
            next: data => {
                this.entitlementList = data;
                this.getAllSearchTypes();
            },
            error: error => {
                this.loading = false;
                this.dialogRef.close(false);
                this.sharedService.handleErrors(error);
            },
            complete: () => { }
        });
    }

    getSupplementalDataProviders(supplementalDataProviders: any) {
        if(supplementalDataProviders) {
            this.supplementalProviders = [];
            supplementalDataProviders.forEach(provider => {
                this.supplementalProviders.push(new ProviderTo(provider));
            });
        }
    }

    getAllSearchTypes() {
        this.searchTypesService.getAllSearchTypes().subscribe({
            next: data => {
                this.searchTypeList = data;
                this.searchTypeList.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1);
                this.getMappedPreferences(this.provider.searchTypeName);
            },
            error: error => {
                this.loading = false;
                this.dialogRef.close(false);
                this.sharedService.handleErrors(error);
            },
            complete: () => { }
        });
    }

    getMappedPreferences(selectedSearchTypeName: String) {     
        if(selectedSearchTypeName) {
            let mappedEntitlement: String;            
            this.searchTypeList.forEach(searchType => {
                if(searchType.name === selectedSearchTypeName) {
                    mappedEntitlement = searchType.entitlement;
                }
            });
          
            this.entitlementList.forEach(entitlement => {
                if(mappedEntitlement === entitlement.name) {
                    this.preferenceList = entitlement.preferences;
                }
            });                              
        }        
    }

    change(event) {
        if (event.srcElement.value !== '') {
            this.count = this.count + 1;
        } else if (event.srcElement.value === '') {
            this.count = this.count - 1;
        }
        if (this.count <= 1 || this.fieldcheck === false) {
            this.fieldcheck = !this.fieldcheck;
        }
    }

    authTypeSelectionChange(event) {
        if(event.value === AuthType.BASIC_AUTH && this.resetData.authType !== this.provider.authType) {
            this.password.setValidators([Validators.required]);
            this.username.markAsTouched();
            this.password.markAsTouched();
        } else {
            this.password.clearValidators();
            this.password.updateValueAndValidity()
        }
    }

    updateProvider() {
        if(!this.isValidData(this.provider, false)) {
            return;
        }
        this.checkAuthType();
        if (this.resetData.searchTypeName !== this.provider.searchTypeName
            || this.resetData.type !== this.provider.type
            || this.resetData.dataFoundEndpointUrl !== this.provider.dataFoundEndpointUrl
            || this.resetData.endpointUrl !== this.provider.endpointUrl
            || this.resetData.httpMethod !== this.provider.httpMethod
            || this.resetData.additionalDataEndpointUrl !== this.provider.additionalDataEndpointUrl
            || this.resetData.additionalDataHttpMethod !== this.provider.additionalDataHttpMethod
            || this.resetData.authType !== this.provider.authType
            || this.resetData.username !== this.provider.username
            || this.resetData.password !== this.provider.password
            || this.resetData.requestTemplate !== this.provider.requestTemplate
            || this.resetData.additionalDataRequestTemplate !== this.provider.additionalDataRequestTemplate
            || JSON.stringify(this.resetData.requestHeaders) !== JSON.stringify(this.provider.requestHeaders)
            || this.resetData.enabled !== this.provider.enabled
            || this.resetData.sensitiveData !== this.provider.sensitiveData 
            || this.resetData.supplementalData !== this.provider.supplementalData
            || this.resetData.oauth2TokenEndpointUrl !== this.provider.oauth2TokenEndpointUrl
            || this.resetData.clientIdPref !== this.provider.clientIdPref
            || this.resetData.clientSecretPref !== this.provider.clientSecretPref
            || this.resetData.clientId !== this.provider.clientId
            || this.resetData.clientSecret !== this.provider.clientSecret
            || this.resetData.audience !== this.provider.audience
            || JSON.stringify(this.resetData.oauth2TokenHeaders) !== JSON.stringify(this.provider.oauth2TokenHeaders)
            || this.isSupplementalDataProvidersConfigModified()) {
            JSON.parse(this.provider.requestTemplate);
            if (this.provider.additionalDataRequestTemplate) {
                JSON.parse(this.provider.additionalDataRequestTemplate);
            }
            this.loading = true;
            this.providersService.updateProvider(this.provider).subscribe({
                next: data => {
                    this.loading = false;
                    this.sharedService.showAlert(Alerts.SUCCESS, 'Provider updated successfully');
                    this.dialogRef.close(true);
                },
                error: error => {
                    this.loading = false;
                    this.sharedService.handleErrors(error,
                        [{
                            "condition": (error.status === 400 && error.error.length > 0),
                            "msg": error.error
                        }],
                        "Unable to update Provider");
                },
                complete: () => { }
            });             
        } else {
            this.sharedService.showAlert(Alerts.ERROR, 'Please modify the details to update');
            this.markFormControlsAsTouched();
        }
    }

    isSupplementalDataProvidersConfigModified(): boolean {
        return JSON.stringify(this.provider.supplementalDataProviders) !== JSON.stringify(this.resetData.supplementalDataProviders);
    }

    resetProvider() {
        if (this.fieldcheck === true) {
            Swal.fire({
                title: 'Are you sure?',
                text: 'Your changes will be lost!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes, Reset',
                cancelButtonText: 'No, Cancel',
            }).then((result) => {
                if (result.value) {
                    this.provider = new ProviderTo(this.resetData);
                    this.fieldcheck = false;
                    this.supplementalDataProvidersDataSource = new MatTableDataSource<SupplementalDataProviderTo>(this.provider.supplementalDataProviders);
                }
            });
        } else {
            this.provider = new ProviderTo(this.resetData);
            this.supplementalDataProvidersDataSource = new MatTableDataSource<SupplementalDataProviderTo>(this.provider.supplementalDataProviders);
        }
    }

    close() {
        if (this.fieldcheck === true) {
            Swal.fire({
                title: 'Are you sure?',
                text: 'Your changes will not be saved !',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes, Close',
                cancelButtonText: 'No, Cancel',
            }).then((result) => {
                if (result.value) {
                    this.dialogRef.close(false);
                }
            });
        } else {
            this.dialogRef.close(false);
        }
    }
}