import {SelectionModel} from '@angular/cdk/collections';
import {ChangeDetectorRef, Component, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {NavigationService} from '../../navigation/navigation.service';
import {UsersService} from '../users.service';
import {OrganizationsService} from '../../organizations/organizations.service';
import {HomeService} from '../../home/home.service';
import {SharedService} from '../../shared/shared.service';
import {Roles} from '../../shared/enums/roles';
import {merge, Observable, startWith, Subscription} from 'rxjs';
import {
    ListParentOrganizationsComponent
} from "../../organizations/list-parent-organizations/list-parent-organizations.component";

import Swal from 'sweetalert2/dist/sweetalert2.js';
import {NewUserTo} from "../../shared/models/newUserTo";
import {FormControl} from "@angular/forms";
import {catchError, debounceTime, map, switchMap} from "rxjs/operators";
import {SelectRolesComponent} from "../select-roles/select-roles.component";

@Component({
    selector: 'app-provision-users',
    templateUrl: './provision-users.component.html',
    styleUrls: ['./provision-users.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class ProvisionUsersComponent implements OnInit {
    readonly SELECT = 'select';
    readonly USERNAME = 'userName';
    readonly PSAPID = 'psapId';
    readonly EMAIL = 'email';
    readonly SIGNEDUPAT = 'signedUp';
    readonly FIRSTNAME = 'firstName';
    readonly LASTNAME = 'lastName';

    @Input() fromSettings: boolean;
    roles = Roles;
    userRole: string = '';
    isLoading: boolean = true;
    isDeletingUser: boolean = false;
    displayFailureMsg: boolean = false;
    dialogRef: MatDialogRef<unknown, any>;
    orgDialogRef: MatDialogRef<unknown, any>;
    All: string = 'All';
    userList: NewUserTo[] = [];
    allUsers: NewUserTo[] = [];
    sortDirection = "asc";

    displayedColumns: string[] = [this.SELECT, this.USERNAME, this.PSAPID, this.EMAIL, this.SIGNEDUPAT, this.FIRSTNAME, this.LASTNAME];
    dataSource = new MatTableDataSource<NewUserTo>(this.userList);
    selection = new SelectionModel<NewUserTo>(true, []);
    selectedOrg: any;
    selectedOrgName: string;
    selectedOrgId: string;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    paginatorLength: number;
    private userServiceSubscription: Subscription;
    columnMap: any = {"org": "name"};
    searchKeywordFilter = new FormControl();
    isDeletingUnprovisionedUser = false;
    selectedUnprovisionedUsers: Array<string> = [];
    totalData: number;

    constructor(public dialog: MatDialog,
                public navigation: NavigationService,
                public usersService: UsersService,
                public homeService: HomeService,
                public orgService: OrganizationsService,
                public sharedService: SharedService,
                private cdref: ChangeDetectorRef) {
        this.userServiceSubscription = this.usersService.updateNewUserList$.subscribe(
            data => {
                this.setupUnprovisionedUsers();
            }
        );
    }

    ngOnInit() {
        this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
        this.sort.disableClear = true;
        this.getLoggedInUserData(this.homeService.userDetails);
        this.paginator.pageSize = 10;
        this.setupUnprovisionedUsers();
    }

    ngOnDestroy() {
        this.userServiceSubscription.unsubscribe();
    }

    ngAfterContentChecked() {
        this.cdref.detectChanges();
    }

    setupUnprovisionedUsers() {
        merge(this.searchKeywordFilter.valueChanges, this.sort.sortChange, this.paginator.page)
            .pipe(
                debounceTime(500),
                startWith({}),
                switchMap(() => {
                    this.isLoading = true;
                    var filterValue = this.searchKeywordFilter.value == null ? '' : this.searchKeywordFilter.value;
                    return this.getAllUsers(
                        filterValue,
                        this.sort.active,
                        this.sort.direction,
                        this.paginator.pageIndex,
                        this.paginator.pageSize
                    ).pipe(catchError(() => new Observable(null)));
                }),
                map((data) => {
                    this.isLoading = false;
                    if (data === null) {
                        return [];
                    }
                    this.totalData = data.totalElements;
                    this.allUsers = data;
                    return data.content;
                })
            )
            .subscribe((data) => {
                this.setUserListData();
            });
    }

    selectAllUnprovisionedUsers(event: any) {
        if (event.checked) {
            this.userList.forEach(msg => {
                if (!this.selectedUnprovisionedUsers.includes(msg.email)) {
                    this.selectedUnprovisionedUsers.push(msg.email);
                }
            });
        } else {
            this.selectedUnprovisionedUsers = [];
        }
    }

    selectUnprovisionedUser(email: string, event: any) {
        const index = this.selectedUnprovisionedUsers.findIndex(id => id === email);
        if (event.checked && index === -1) {
            this.selectedUnprovisionedUsers.push(email);
        } else if (!event.checked && index !== -1) {
            this.selectedUnprovisionedUsers.splice(index, 1);
        }
    }

    selectAllOrgs() {
        if (this.selectedOrgName !== this.All) {
            this.selectedOrgName = this.All;
            this.selectedOrgId = this.All;
            this.selectedOrg = undefined
            this.getUserListData(this.selectedOrgId);
        }
    }

    selectOrg() {
        this.orgDialogRef = this.dialog.open(ListParentOrganizationsComponent, {
            data: this.selectedOrg?.clientId
        });
        this.orgDialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.selectedOrg = result;
                this.selectedOrgName = result.name;
                this.selectedOrgId = result.clientId;
                this.getUserListData(this.selectedOrgId);
            }
        });
    }

    createUser() {
        var selectedUserList: NewUserTo[] = [];
        this.userList.forEach(user => {
            if (this.selectedUnprovisionedUsers.includes(user.email)) {
                selectedUserList.push(user);
            }
        });

        this.dialogRef = this.dialog.open(SelectRolesComponent, {
            data: selectedUserList,
            width: '550px'
        });
        this.dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.setupUnprovisionedUsers();
            }
        });
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    getLoggedInUserData(userDetails) {
        this.selectedOrgName = userDetails.org.name;
        this.selectedOrg = userDetails.org;
        this.selectedOrgId = userDetails.org.clientId;
        this.userRole = (userDetails.roles.includes(this.roles.SUPER)) ?
            this.roles.SUPER : (userDetails.roles.includes(this.roles.ADMIN)) ? this.roles.ADMIN : this.roles.SEARCH;
    }

    getAllUsers(filterValue, sortActive, sortDirection, pageIndex, pageSize): Observable<any> {
        if (sortDirection != "" && this.sortDirection != sortDirection) {
            this.sortDirection = sortDirection;
        }
        return this.usersService.getAllUnprovisionedUsers(filterValue, sortActive, sortDirection, pageIndex, pageSize);
    }

    formUserListData(data, orgId) {
        this.userList = data.filter(eachUser => {
            if (this.selectedOrgName === this.All || (eachUser.psapId && eachUser.psapId === orgId)) {
                return eachUser;
            }
        });
        if (this.userList.length > 20) {
            this.paginatorLength = this.userList.length;
        } else {
            this.paginatorLength = 20;
        }
    }

    setUserListData() {
        this.formUserListData(this.allUsers, this.selectedOrgId);
        const currentfilter = this.dataSource.filter;
        this.dataSource = new MatTableDataSource<NewUserTo>(this.userList);
        this.dataSource.filter = currentfilter;
        this.dataSource.filterPredicate = (data: NewUserTo, filter: string) =>
            data.userName.trim().toLowerCase().indexOf(filter) !== -1 || data.email.trim().toLowerCase().indexOf(filter) !== -1 ||
            data.firstName.trim().toLowerCase().indexOf(filter) !== -1 || data.lastName.trim().toLowerCase().indexOf(filter) !== -1 ||
            data.psapId.trim().toLowerCase().indexOf(filter) !== -1;
        setTimeout(() => this.dataSource.paginator = this.paginator);
        this.dataSource.sortingDataAccessor = (data: NewUserTo, sortHeaderId: string): string => {
            if (typeof data[sortHeaderId] === 'string') {
                return data[sortHeaderId].toLocaleLowerCase();
            }

            if (typeof data[sortHeaderId] === 'object') {
                return data[sortHeaderId][this.columnMap[sortHeaderId]].toLocaleLowerCase();
            }

            return data[sortHeaderId];
        };
        this.dataSource.sort = this.sort;
        if ((this.userRole !== this.roles.SUPER)) {
            this.isLoading = false;
        }
    }

    deleteSelectedUnprovisionedUsers() {
        this.isDeletingUnprovisionedUser = true;
        this.selectedUnprovisionedUsers.forEach(email => {
            this.usersService.deleteUnprovisionedUser(email).subscribe({
                next: data => {
                    this.isDeletingUnprovisionedUser = false;
                    this.selectedUnprovisionedUsers = [];
                    this.sharedService.showAlert('success', 'All selected unprovisioned users have been deleted.');
                    this.usersService.updateNewUserList.next();
                },
                error: error => {
                    this.isDeletingUnprovisionedUser = false;
                    this.sharedService.handleErrors(error,
                        [{
                            "condition": (error.status == 400 && error.error),
                            "msg": error.error
                        }],
                        "Failed to delete unprovisioned user.");
                    this.usersService.updateNewUserList.next();
                },
                complete: () => {}
            });
        })
    }

    deleteUnprovisionedUsers() {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Delete all selected Unprovisioned Users',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Yes, Delete',
            cancelButtonText: 'No, cancel',
            showLoaderOnConfirm: true
        }).then((result: any) => {
            if (result.value) {
                this.deleteSelectedUnprovisionedUsers();
            }
        });
    }

    getUserListData(orgId) {
        if (orgId === this.All) {
            this.userList = this.allUsers;
            if (this.userList.length > 20) {
                this.paginatorLength = this.userList.length;
            } else {
                this.paginatorLength = 20;
            }
        } else {
            this.formUserListData(this.allUsers, orgId);
        }
        this.setUserListData();
    }

    syncPrimaryPaginator(event: PageEvent) {
        this.paginator.pageIndex = event.pageIndex;
        this.paginator.pageSize = event.pageSize;
        this.paginator.page.emit(event);
    }
}
