export class CallRecord {

    tn: string;
    imei : string;
    source: string;
    searchType: string;
    locationType: string;
    location: any;
    locationTime: string;
    timestamp: string;
    callStartTime: string;
    dbhData: any;
    iotData: any;
    securityWord: string;
    sequence : number;

    constructor(object?: any) {	
        if (object) {
            this.tn = object.tn;
            this.imei = object.imei;
            this.source = object.source;
            this.searchType = object.searchType;
            this.locationType = object.locationType;
            this.location = object.location;
            this.locationTime = object.locationTime;
            this.timestamp = object.timestamp;
            this.callStartTime = object.callStartTime;
            this.dbhData = object.dbhData;
            this.iotData = object.iotData;
            this.securityWord = object.securityWord;
        }
    }

}