import {Component, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {HomeService} from "../../home/home.service";
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import {EmailReportComponent} from "../../email-report/email-report.component";
import {BrokerSearch} from '../../shared/models/brokerSearch';
import { OrgEntitlementsEnum } from 'src/app/shared/enums/org-entitlements.enum';
import { DatePipe } from '@angular/common';

import * as moment from 'moment';
import 'moment-timezone';
import {MapInfoWindow, MapMarker} from "@angular/google-maps";

declare let google: any;

@Component({
    selector: 'app-power-data-landmark-results',
    templateUrl: './power-data-landmark-results.component.html',
    styleUrls: ['./power-data-landmark-results.component.scss']
})
export class PowerDataLandmarkResultsComponent implements OnInit {

    @Input() brokerSearch: BrokerSearch;
    @Input() resultList: BrokerSearch[];
    selectedResult: any;
    overlayList:any[] = [];
    activeOverlay : any;
    showEmailResultIcon : boolean = false;
    
    map: google.maps.Map;
    mapCenter: google.maps.LatLngLiteral;
    mapZoom : number;
    mapType: string;

    constructor(private homeService: HomeService,
                private dialog: MatDialog,
                private datepipe: DatePipe) {
    }

    ngOnInit() {
        this.overlayList = [];
        this.activeOverlay = null;
        this.checkShareResultEntitlement();

        if (this.brokerSearch) {
            this.selectedResult = this.brokerSearch.searchResult.results[0];
            this.setMapData();
        }
    }

    ngOnDestroy() {
        this.map.controls[google.maps.ControlPosition.TOP_CENTER].clear();
        this.overlayList.forEach( overlay => {
            overlay.setMap(null);
        });
    }

    setMapData() {
        this.mapZoom = 19;
        this.mapType = 'roadmap';
        this.mapCenter = {lat: +this.brokerSearch.searchRequest.location.latitude, lng: +this.brokerSearch.searchRequest.location.longitude};
    }

    onMouseOver(marker: MapMarker, infoWindow: MapInfoWindow) {
        infoWindow.open(marker)
    }

    onMouseOut(infoWindow: MapInfoWindow) {
        infoWindow.close();
    }

    onMapReady(map) {
        this.map = map;
        this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(document.getElementById('mapCenter'));
        this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(document.getElementById('floorplanDropdown'));
        this.setRasterOverlay();
    }

    setRasterOverlay() {
        if (this.overlayList) {
            this.overlayList.forEach( overlay => {
                overlay.hide();
                overlay.setMap(null);
            });
        }
        this.overlayList = [];
        this.activeOverlay = null;

        class FloorPlanOverlay extends google.maps.OverlayView {
            constructor(rasterOverlay : any) {
                super();
        
                this.lat = +rasterOverlay.anchorPoint.latitude;
                this.lng = +rasterOverlay.anchorPoint.longitude;
        
                var south = +rasterOverlay.anchorPoint.vertices?.south;
                var north = +rasterOverlay.anchorPoint.vertices?.north;
                var west = +rasterOverlay.anchorPoint.vertices?.west;
                var east = +rasterOverlay.anchorPoint.vertices?.east;
                this.bounds = new google.maps.LatLngBounds(
                    new google.maps.LatLng(south, west),
                    new google.maps.LatLng(north, east)
                );
        
                this.image = rasterOverlay.imageURL;
                this.floor =  rasterOverlay.floor;
                this.label = rasterOverlay.label
                this.rotation =  rasterOverlay.rotation;
                this.opacity = rasterOverlay.opacity;
                this.rasterOverlay = rasterOverlay;
            }
        
            getFloor() : string {
                return this.rasterOverlay.floor;
            }
        
            getLabel(): string {
                return this.rasterOverlay.label;
            }
        
            getRasterOverlay() : any {
                return this.rasterOverlay;
            }
            /**
             * onAdd is called when the map's panes are ready and the overlay has been
             * added to the map.
             */
            onAdd() {
                this.div = document.createElement("div");
                this.div.style.borderStyle = "none";
                this.div.style.borderWidth = "0px";
                this.div.style.position = "absolute"; // Create the img element and attach it to the div.
                this.div.style.visibility = "hidden";
        
                const img = document.createElement("img");
                img.src = this.image;
                img.style.width = "100%";
                img.style.height = "100%";
                img.style.position = "absolute";
                if (this.rotation) { img.style.transform = 'rotate('+this.rotation+'deg)'; }
                if (this.opacity) { img.style.opacity = this.opacity; }
                this.div.appendChild(img); // Add the element to the "overlayLayer" pane.
        
                const panes = this.getPanes();
                panes.overlayLayer.appendChild(this.div);
            }
        
            draw() {
                // We use the south-west and north-east
                // coordinates of the overlay to peg it to the correct position and size.
                // To do this, we need to retrieve the projection from the overlay.
                const overlayProjection = this.getProjection();
        
                // Retrieve the south-west and north-east coordinates of this overlay
                // in LatLngs and convert them to pixel coordinates.
                // We'll use these coordinates to resize the div.
                const sw = overlayProjection.fromLatLngToDivPixel(
                    this.bounds.getSouthWest()
                );
                const ne = overlayProjection.fromLatLngToDivPixel(
                    this.bounds.getNorthEast()
                );
        
                // Resize the image's div to fit the indicated dimensions.
                if (this.div) {
                    this.div.style.left = sw.x + "px";
                    this.div.style.top = ne.y + "px";
                    this.div.style.width = ne.x - sw.x + "px";
                    this.div.style.height = sw.y - ne.y + "px";
                }
            }
        
            // The onRemove() method will be called automatically from the API if
            // we ever set the overlay's map property to 'null'.
            onRemove() {
                if (this.div) {
                    this.div.parentNode.removeChild(this.div);
                    delete this.div;
                }
            }
        
            /**
             *  Set the visibility to 'hidden' or 'visible'.
             */
            hide() {
                if (this.div) {
                    this.div.style.visibility = "hidden";
                }
            }
        
            show() {
                if (this.div) {
                    this.div.style.visibility = "visible";
                }
            }
        
            toggle() {
                if (this.div) {
                    if (this.div.style.visibility === "hidden") {
                        this.show();
                    } else {
                        this.hide();
                    }
                }
            }
        }

        this.selectedResult.landmarkDetail.rasterOverlay?.forEach( (element) => {
            var overlay = new FloorPlanOverlay(element);
            overlay.setMap(this.map);
            this.overlayList.push(overlay);
        });
    }

    centerMap() {
        this.map.setCenter(this.mapCenter);
        this.map.setZoom(this.mapZoom);
    }

    onSearchChange(event: any){
        if (event.source.selected && this.brokerSearch.uniqueSearchId !== event.source.value.uniqueSearchId) {
            this.brokerSearch = event.source.value;
            this.selectedResult = this.brokerSearch.searchResult.results[0];
            this.setMapData();
            this.centerMap();
            this.setRasterOverlay();
        }
    }

    onResultChange(event){
        if (event.source.selected && this.selectedResult.landmarkIdentifier !== event.source.value.landmarkIdentifier) {
            this.selectedResult = event.source.value;
            this.setMapData();
            this.centerMap();
            this.setRasterOverlay();
        }
    }

    onChangeOverlay(value){
        this.activeOverlay = null;
        document.getElementById("mapOpenOverlay").style.display = "none";
        this.overlayList.forEach( overlay => {
            if (overlay.getLabel() === value) {
                this.activeOverlay = overlay;
                this.activeOverlay.show();
                document.getElementById("mapOpenOverlay").style.display = "block";
            } else {
                overlay.hide();
            }
        });
    }

    checkShareResultEntitlement() {
        this.showEmailResultIcon = false;
        if (this.homeService.userDetails && this.homeService.userDetails.org.clientId) {
            this.homeService.userDetails.org.entitlements.forEach( entitlement => {
                if (entitlement === OrgEntitlementsEnum.SHARE_RESULTS) {
                    this.showEmailResultIcon = true;
                }
            });
        }
    }

    EmailStaticReport() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            searchResultId: String(this.brokerSearch.uniqueSearchId),
            linkOnly: true
        }
        this.dialog.open(EmailReportComponent, dialogConfig);
    }

    formatDate(date: any, showTimezone: boolean): string {
        return this.datepipe.transform(new Date(date), 'MM/dd/yyyy hh:mm:ss a') + (showTimezone ? " " + moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).zoneAbbr() : "");
    }
    
    formatAddress(addresses:any): string {
        let address = "";
        addresses.forEach(element => {
            if(element.preferredFlag && address.length === 0) {
                address += this.getAddress(element)
            }
        });
        return address;
    }

    getAddress(addr): string {
        if (addr) {
            var address = "";
            if (addr.address1){
                address = address + ' ' + addr.address1;
            }
            if (addr.address2){
                address = address + ' ' + addr.address2;
            }
            if (addr.city){
                address = address + ' ' + addr.city;
            }
            if (addr.state){
                address = address + ' ' + addr.state;
            }
            if (addr.zip){
                address = address + ' ' + addr.zip;
            }
            if (addr.country){
                address = address + ' ' + addr.country;
            }
            return address.trim();
        }
    }

    openDialog(): void {
        if (this.activeOverlay) {
            const dialogRef = this.dialog.open(
                OverlayDialog, {
                    data: {"overlay": this.activeOverlay.getRasterOverlay()}
                });
        }

    }

}


@Component({
    selector: 'overlay-dialog',
    templateUrl: 'overlay-dialog.html',
})
export class OverlayDialog {

    constructor(
        public dialogRef: MatDialogRef<OverlayDialog>,
        @Inject(MAT_DIALOG_DATA) public data) {}

    cancel() {
        this.dialogRef.close();
    }
}
