import {SelectionModel} from '@angular/cdk/collections';
import {ChangeDetectorRef, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {OrganizationTo} from '../../shared/models/organizationTo';
import {OrganizationsService} from '../../organizations/organizations.service';
import {NavigationService} from '../../navigation/navigation.service';
import {HomeService} from '../../home/home.service';
import {Roles} from '../../shared/enums/roles';
import {SharedService} from '../../shared/shared.service';
import {BehaviorSubject, merge, Observable, startWith, Subscription} from 'rxjs';
import {FormControl, UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {catchError, debounceTime, map, switchMap} from "rxjs/operators";

@Component({
    selector: 'app-list-parent-organizations',
    templateUrl: './list-parent-organizations.component.html',
    styleUrls: ['./list-parent-organizations.component.scss']
})
export class ListParentOrganizationsComponent implements OnInit {

    roles = Roles;
    organizationList: OrganizationTo[] = [];
    displayedColumns: string[] = ['name', 'clientId'];
    dataSource = new MatTableDataSource<OrganizationTo>(this.organizationList);
    filterCheckboxes: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    selection = new SelectionModel<OrganizationTo>(true, []);
    isLoading = true;
    isDeletingOrg = false;
    displayFailureMsg = false;
    defaultValues = ['0','1','2'];
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    paginatorLength: number;
    totalData : number;
    isRateLimitReached = false;
    sortDirection = "asc";
    btnValue : any = 'Select';
    clientId;

    searchKeywordFilter = new FormControl();

    private orgServiceSubscription: Subscription;

    OrgTypes: Array<any> = ['INTERNAL' ,'PROVIDER','PSAP'];

    form: UntypedFormGroup;

    constructor(@Inject(MAT_DIALOG_DATA) private selectedOrgId,
                public dialog: MatDialog, 
                public navigation: NavigationService, 
                public dialogRef: MatDialogRef<ListParentOrganizationsComponent>,
                public orgService: OrganizationsService, public homeService: HomeService,
                public sharedService: SharedService, private cdref: ChangeDetectorRef,
                private fb: UntypedFormBuilder) {
        this.orgServiceSubscription = this.orgService.updateOrganizationList$.subscribe(
            data => {
                this.loadOrganizations();
            });
        this.form = this.fb.group({
            checkArray: this.fb.array([])
        })
    }

    ngOnInit() {
        this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
        this.sort.disableClear = true;
        this.sort.active = "name"
        this.paginator.pageSize = 10;

        merge(this.searchKeywordFilter.valueChanges, this.sort.sortChange, this.paginator.page)
            .pipe(
                debounceTime(500),
                startWith({}),
                switchMap(() => {
                    this.isLoading = true;
                    var filterValue = this.searchKeywordFilter.value == null ? '' : this.searchKeywordFilter.value;
                    return this.getOrganizations(
                        filterValue,
                        this.sort.active,
                        this.sort.direction,
                        this.filterCheckboxes.getValue(),
                        this.paginator.pageIndex,
                        this.paginator.pageSize
                    ).pipe(catchError(() => new Observable(null)));
                }),
                map((data) => {
                    // Flip flag to show that loading has finished.
                    this.isLoading = false;
                    this.isRateLimitReached = data === null;

                    if (data === null) {
                        return [];
                    }

                    // Only refresh the result length if there is new data. In case of rate
                    // limit errors, we do not want to reset the paginator to zero, as that
                    // would prevent users from re-triggering requests.
                    this.totalData = data.totalElements;
                    return data.content;
                })
            )
            .subscribe((data) => {
                this.organizationList = data;
                this.setOrganizationList();
            });
    }

    ngOnDestroy() {
        this.orgServiceSubscription.unsubscribe();
    }

    orgSelected(org) {
        this.selectedOrgId = org.clientId;
        this.dialogRef.close(org);
    }

    loadOrganizations() {
        if (this.homeService.userDetails.roles.includes(this.roles.SUPER)) {
            // this.getOrganizations();
        } else {
            this.organizationList = [this.homeService.userDetails.org];
            this.setOrganizationList();
        }
    }

    getOrganizations(filterValue, sortActive, sortDirection, orgTypes, pageIndex, pageSize) : Observable<any> {
        if (sortDirection != "" && this.sortDirection != sortDirection) {
            this.sortDirection = sortDirection;
        }
        return this.orgService.getOrganizations(filterValue, sortActive, this.sortDirection, orgTypes, pageIndex, pageSize);
    }

    setOrganizationList() {
        if (this.totalData > 20) {
            this.paginatorLength = this.totalData;
        } else {
            this.paginatorLength = 20;
        }
        const currentfilter = this.dataSource.filter;
        this.dataSource = new MatTableDataSource<OrganizationTo>(this.organizationList);
        this.dataSource.filter = currentfilter;
        this.dataSource.filterPredicate = this.dataSource.filterPredicate = (orgTo: OrganizationTo, filter: any) => {
            let result = false;
            if (filter.length > 0) {
                let filters = filter.split(',');
                if (!filters.includes(orgTo.type)) {
                    result = true;
                    filters.map(f => {
                        if (!this.OrgTypes.includes(f)) {
                            if (orgTo.name.trim().toLowerCase().indexOf(f) !== -1 ||
                                orgTo.clientId.trim().toLowerCase().indexOf(f) !== -1) {
                                result = true;
                            } else {
                                result = false;
                            }
                        }
                    })
                }
            }

            return result;
        }

        this.dataSource.sortingDataAccessor = (data: OrganizationTo, sortHeaderId: string): string => {
            if (typeof data[sortHeaderId] === 'string') {
                return data[sortHeaderId].toLocaleLowerCase();
            }
            return data[sortHeaderId];
        };
        this.dataSource.sort = this.sort;
        this.displayFailureMsg = false;
    }

    syncPrimaryPaginator(event: PageEvent) {
        this.paginator.pageIndex = event.pageIndex;
        this.paginator.pageSize = event.pageSize;
        this.paginator.page.emit(event);
    }

    onValChange(e) {
        const selection = parseInt(e.value, 10);
        if (this.filterCheckboxes.value.some((a: any) => a === this.OrgTypes[selection])) {
            this.filterCheckboxes.next(this.filterCheckboxes.value.filter((a: any) => a !== this.OrgTypes[selection]));
        } else {
            if (e.source._checked === false) {
                this.filterCheckboxes.next(this.filterCheckboxes.value.concat(this.OrgTypes[selection]));
            }
        }

        this.ngOnInit();
    }

    close() {
        this.dialogRef.close(false);
    }
}
