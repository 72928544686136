import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigService } from 'src/assets/config/config-svc.service';
import { TextBlock } from '../shared/models/textBlock';
import { SharedService } from '../shared/shared.service';


@Injectable({
    providedIn: 'root'
})
export class TextBlocksService {

    updateTextBlockList = new Subject<void>();
    updateTextBlockList$ = this.updateTextBlockList.asObservable();

    constructor(public http: HttpClient, public sharedService: SharedService) {
    }

    getHeaders() {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'transactionId': this.sharedService.generateUUID(),
                'reportType': 'All'
            }),
            withCredentials: true
        };
        return httpOptions;
    }

    getAllTextBlocks(): Observable<TextBlock[]> {
        return this.http.get<TextBlock[]>(ConfigService.config.userServiceUrl + '/textblock',
            this.getHeaders()).pipe(map((textBlocks => this.setTextBlocksDataInModel(textBlocks))));
    }
  
    createTextBlock(payload) {
        return this.http.post(ConfigService.config.userServiceUrl + '/textblock', payload, this.getHeaders()).pipe(map((res: any) => res));
    }

    updateTextBlock(payload) {
        return this.http.put(ConfigService.config.userServiceUrl + '/textblock', payload, this.getHeaders()).pipe(map((res: any) => res));
    }

    deleteTextBlock(name) {
        return this.http.delete(ConfigService.config.userServiceUrl + '/textblock/' + name, this.getHeaders()).pipe(map((res: any) => res));
    }

    setTextBlocksDataInModel(textBlockData) {
        let textBlocks = [];
        if (textBlockData) {
            textBlockData.forEach(element => {
                let textBlock = new TextBlock(element);
                textBlocks.push(textBlock);
            });
        }
        return textBlocks;
    }
}
