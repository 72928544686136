import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationService } from '../../navigation/navigation.service';
import { SharedService } from '../../shared/shared.service';
import { PredefinedTextMessageService } from '../predefined-text-message.service';
import { AddPredefinedTextMessageComponent } from '../add-predefined-text-message/add-predefined-text-message.component';
import { UpdatePredefinedTextMessageComponent } from '../update-predefined-text-message/update-predefined-text-message.component';
import { PredefinedTextMessage } from 'src/app/shared/models/predefinedTextMessage';
import {merge, Observable, startWith, Subscription} from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { BulkMessageUploadComponent } from '../bulk-upload/bulk-message-upload.component';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Prefix } from 'src/app/shared/models/prefix';
import {FormControl} from "@angular/forms";
import {catchError, debounceTime, map, switchMap} from "rxjs/operators";
import {OrganizationTo} from "../../shared/models/organizationTo";

@Component({
  selector: 'app-list-predefined-text-messages',
  templateUrl: './list-predefined-text-messages.component.html',
  styleUrls: ['./list-predefined-text-messages.component.scss']
})
export class ListPredefinedTextMessagesComponent implements OnInit {

    dialogRef;
    predefinedTextMessageList: PredefinedTextMessage[] = [];
    selectedMessageIdsToDelete: Array<number> = [];
    displayedColumns: string[] = ['select', 'prefix', 'description', 'message', 'options'];
    dataSource = new MatTableDataSource<PredefinedTextMessage>(this.predefinedTextMessageList);
    isLoading = false;
    isDeletingPredefinedTextMsg = false;
    displayFailureMsg = false;
    selection = new SelectionModel<PredefinedTextMessage>(true, []);
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    paginatorLength: number;
    prefixList: Prefix[] = [];
    sortDirection = "asc";
    searchKeywordFilter = new FormControl();
    totalData : number;
    predefinedTextMessageServiceSubscription: Subscription;

    constructor(public dialog: MatDialog, 
                public navigation: NavigationService,
                public predefinedTextMessageService: PredefinedTextMessageService,
                public sharedService: SharedService, 
                private cdref: ChangeDetectorRef) {
        this.predefinedTextMessageServiceSubscription = this.predefinedTextMessageService.updatePredefinedTextMessageList$.subscribe(
            data => {
                this.setupPredefinedText();
            }
        );
    }

    ngOnInit() {
        this.isLoading = true;
        this.predefinedTextMessageService.getAllPrefixes().subscribe(data =>{
            this.prefixList = data;
        });
        this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
        this.sort.disableClear = true;
        this.sort.active = "message"
        this.paginator.pageSize = 10;
        this.setupPredefinedText();
    }

    ngOnDestroy() {
        this.predefinedTextMessageServiceSubscription.unsubscribe();
    }

    setupPredefinedText() {
        merge(this.searchKeywordFilter.valueChanges, this.sort.sortChange, this.paginator.page)
            .pipe(
                debounceTime(500),
                startWith({}),
                switchMap(() => {
                    this.isLoading = true;
                    var filterValue = this.searchKeywordFilter.value == null ? '' : this.searchKeywordFilter.value;
                    return this.getAllPredefinedTextMessages(
                        filterValue,
                        this.sort.active,
                        this.sort.direction,
                        this.paginator.pageIndex,
                        this.paginator.pageSize
                    ).pipe(catchError(() => new Observable(null)));
                }),
                map((data) => {
                    this.isLoading = false;
                    if (data === null) {
                        return [];
                    }
                    this.totalData = data.totalElements;
                    return data.content;
                })
            )
            .subscribe((data) => {
                this.predefinedTextMessageList = data;
                this.setPredefinedTextMessageList();
            });
    }

    getAllPredefinedTextMessages(filterValue, sortActive, sortDirection, pageIndex, pageSize): Observable<any> {
        if (sortDirection != "" && this.sortDirection != sortDirection) {
            this.sortDirection = sortDirection;
        }
        return this.predefinedTextMessageService.getAllPredefinedTextMessages(filterValue, sortActive, sortDirection, pageIndex, pageSize);
    }

    getPrefixFromPrefixId(prefixId: String) {
        let prefix = this.prefixList.find(x => x.prefixId === prefixId);
        return prefix.prefix;        
    }

    getDescriptionFromPrefixId(prefixId: String) {
        let description = this.prefixList.find(x => x.prefixId === prefixId);
        return description.description;        
    }

    ngAfterContentChecked() {
        this.cdref.detectChanges();
    }

    setPredefinedTextMessageList() {
        if (this.totalData > 20) {
            this.paginatorLength = this.totalData;
        } else {
            this.paginatorLength = 20;
        }
        const currentfilter = this.dataSource.filter;
        this.dataSource = new MatTableDataSource<PredefinedTextMessage>(this.predefinedTextMessageList);
        this.dataSource.filter = currentfilter;
        this.dataSource.filterPredicate = (predefinedTextMessage: PredefinedTextMessage, filter: any) => predefinedTextMessage.message.trim().toLowerCase().indexOf(filter) !== -1;

        this.dataSource.sortingDataAccessor = (predefinedTextMessage: PredefinedTextMessage, sortHeaderId: string): string => {
            if (typeof predefinedTextMessage[sortHeaderId] === 'string') {
                return predefinedTextMessage[sortHeaderId].toLowerCase();
            }
            return predefinedTextMessage[sortHeaderId];
        };
        this.dataSource.sort = this.sort;
        this.displayFailureMsg = false;
    }

    addPredefinedTextMessage() {
        this.dialogRef = this.dialog.open(AddPredefinedTextMessageComponent);
        this.dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.setupPredefinedText();
            }
        });
    }

    editPredefinedTextMessage(object) {
        this.dialogRef = this.dialog.open(UpdatePredefinedTextMessageComponent, {
            data: object
        });
        this.dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.setupPredefinedText();
            }
        });
    }

    deletePredefinedTextMessage(object:PredefinedTextMessage) {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Delete the Predefined Text Message "' + object.message + '"',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Yes, Delete',
            cancelButtonText: 'No, cancel',
            showLoaderOnConfirm: true
        }).then((result: any) => {
            if (result.value) {
                this.isDeletingPredefinedTextMsg = true;
                this.predefinedTextMessageService.deletePredefinedTextMessage(object.messageId).subscribe({
                    next: data => {
                        this.isDeletingPredefinedTextMsg = false;
                        this.sharedService.showAlert('success', 'Text Message has been deleted.');
                        this.predefinedTextMessageService.updatePredefinedTextMessageList.next();
                    },
                    error: error => {
                        this.isDeletingPredefinedTextMsg = false;
                        this.sharedService.handleErrors(error,
                            [{
                                "condition": (error.status == 400 && error.error),
                                "msg": error.error
                            }],
                            "Failed to delete Text Message.");
                        this.predefinedTextMessageService.updatePredefinedTextMessageList.next();
                    },
                    complete: () => { }
                });
            }
        });
    }
    
    syncPrimaryPaginator(event: PageEvent) {
        this.paginator.pageIndex = event.pageIndex;
        this.paginator.pageSize = event.pageSize;
        this.paginator.page.emit(event);
    }

    bulkUpload() {
        const dialogRef = this.dialog.open(BulkMessageUploadComponent, {
            width: '750px',
            data: {},
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.ngOnInit();
            }
        });
    }

    selectAllMessages(event:any) {
        if(event.checked) {
            this.predefinedTextMessageList.forEach(msg => {
                if(!this.selectedMessageIdsToDelete.includes(msg.messageId)) {
                    this.selectedMessageIdsToDelete.push(msg.messageId);
                }
            });
        } else {
            this.selectedMessageIdsToDelete = [];
        }
    }

    selectMessage(messageId:number, event:any) {
        const index = this.selectedMessageIdsToDelete.findIndex(id => id === messageId);
        if(event.checked && index === -1) {
            this.selectedMessageIdsToDelete.push(messageId);
        } else if(!event.checked && index !== -1)  {
            this.selectedMessageIdsToDelete.splice(index, 1);
        }
    }

    selectMessageFromRow(messageId:number) {
        const index = this.selectedMessageIdsToDelete.findIndex(id => id === messageId);
        if(index === -1) {
            this.selectedMessageIdsToDelete.push(messageId);
        } else {
            this.selectedMessageIdsToDelete.splice(index, 1);
        }
    }

    deleteSelectedMessages() {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Delete all selected Predefined Text Messages',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Yes, Delete',
            cancelButtonText: 'No, cancel',
            showLoaderOnConfirm: true
        }).then((result: any) => {
            if (result.value) {
                this.isDeletingPredefinedTextMsg = true;
                this.predefinedTextMessageService.deleteSelectedPredefinedTextMessages(this.selectedMessageIdsToDelete).subscribe({
                    next: data => {
                        this.isDeletingPredefinedTextMsg = false;
                        this.selectedMessageIdsToDelete = [];
                        this.sharedService.showAlert('success', 'All selected text messages have been deleted.');
                        this.predefinedTextMessageService.updatePredefinedTextMessageList.next();
                    },
                    error: error => {
                        this.isDeletingPredefinedTextMsg = false;
                        this.sharedService.handleErrors(error,
                            [{
                                "condition": (error.status == 400 && error.error),
                                "msg": error.error
                            }],
                            "Failed to delete Text Messages.");
                        this.predefinedTextMessageService.updatePredefinedTextMessageList.next();
                    },
                    complete: () => { }
                });

            }
        });
    }
}
