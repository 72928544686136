import { TemplateFileTo } from "./templateFileTo";

export class TextblockFileTo extends TemplateFileTo {
   
    defaultTemplate: boolean;

    constructor(object?: any) {
        super(object);
        this.defaultTemplate = object?.defaultTemplate;
    }

}