export class DisplayAttributeTo{
    name: String;
    value: String;
    
    constructor(object?: any) {
        if (object) {
            this.name = object.name;
            this.value = object.value;
        }
    }
}