export class TextBlock {
    
    id: number;
    name: string;
    description: string;

    constructor(object?: any) {
        if(object) {
            this.id = object.id;
            this.name = object.name;
            this.description = object.description;
        }
    }
}