import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {BrowserConstants} from './enums/browser-constants';
import {Alerts} from "./enums/alerts";
import { HttpErrorResponse } from "@angular/common/http";
import { BrokerSearch } from './models/brokerSearch';
import { SearchSummary } from './models/searchSummary';
import { BehaviorSubject, Observable } from 'rxjs';

import Swal from 'sweetalert2/dist/sweetalert2.js';
import { UserTo } from './models/userTo';

@Injectable({
    providedIn: 'root'
})
export class SharedService {

    public readonly ID_TOKEN = 'id_token';
    public readonly REFRESH_TOKEN = 'refresh_token';
    public readonly tokenExpiryTitle = "Token Expired";
    public readonly tokenExpiryMsg = "Your authorization has expired, you will need to login again.";
    public readonly tokenInvalidTitle = "Token Invalid";
    public readonly tokenInvalidMsg = "Your authorization is invalid, you will need to login again."
    protected searchResults:Array<BrokerSearch> = [];
    protected searchHistoryData: Array<SearchSummary> = [];
    protected searchHistoryCriteria:any;
    private redirectUri: BehaviorSubject<string> = new BehaviorSubject<string>(null);    
    public redirectUri$: Observable<string> = this.redirectUri.asObservable();
    private currentOrgId: BehaviorSubject<any> = new BehaviorSubject<any>({'orgId': '', 'navigate':''});
    public currentOrgId$: Observable<any> = this.currentOrgId.asObservable();
    private loggedInUser: BehaviorSubject<UserTo> = new BehaviorSubject<UserTo>(null);    
    public loggedInUser$: Observable<UserTo> = this.loggedInUser.asObservable();

    constructor(public router: Router) {
    }

    setLoginRedirectUri(redirectUri: string) {
        this.redirectUri.next(redirectUri);
    }

    setCurrentOrgId(currentOrgId:any) {
        this.currentOrgId.next(currentOrgId);
    }

    setLoggedInUser(loggedInUser: UserTo) {
        this.loggedInUser.next(loggedInUser);
    }

    getLoggedInUser() {
        return this.loggedInUser.getValue();
    }

    getSearchResults(): BrokerSearch[] {
        return this.searchResults;
    }

    setSearchResults(searchResults: BrokerSearch[]) {
        this.searchResults = searchResults;
    }

    getSearchHistoryData(): any {
        return this.searchHistoryData;
    }

    setSearchHistoryData(searchHistoryData) {
        if(searchHistoryData.length > 0 ){
            searchHistoryData = []
            this.searchHistoryData = searchHistoryData;
        }
        this.searchHistoryData = searchHistoryData;
    }

    getSearchHistoryCriteria(): any {
        return this.searchHistoryCriteria;
    }

    setSearchHistoryCriteria(searchHistoryCriteria) {
        this.searchHistoryCriteria = searchHistoryCriteria;
    }

    isTokenExpired() : boolean {
        const jwt = localStorage.getItem(this.ID_TOKEN)?.split('.');
        return (jwt?.length > 1) && 1000 * (JSON.parse(atob(jwt[1])).exp || 0) < Date.now();
    }

    handleTokenExpiration(title, msg) {
        localStorage.removeItem(this.ID_TOKEN);
        Swal.fire({
            title: title,
            text: msg,
            icon: 'info',
            showCancelButton: false,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Login',
        }).then((unused) => {
            this.router.navigate(['session/login']);
        });
    }

    showConfirmAlert(type, message) {
        Swal.fire({
            width: 700,
            padding: '3em',
            icon: type,
            title: message,
            showConfirmButton: true,
        });
    }

    showAlert(type, message) {
        Swal.fire({
            width: 700,
            padding: '3em',
            icon: type,
            title: "<span style='word-break: break-word;'>"+message+"</span>",
            showConfirmButton: false,
            timer: 4000
        });
    }

    showAlertNoTimer(type, message) {
        Swal.fire({
            width: 700,
            padding: '3em',
            icon: type,
            title: message,
            showConfirmButton: false,
            timer: 0
        });
    }

    public showYesOrNo(title, text, confirmButtonText) {
        Swal.fire({
            title: title,
            text: text,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: confirmButtonText,
            cancelButtonText: 'No, cancel',
        }).then((result) => {
            return (result.value === true) ? true : false;
        });

    }

    public generateUUID() { // Public Domain/MIT
        var d = new Date().getTime();
        if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
            d += performance.now(); //use high-precision timer if available
        }
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    }

    getOSName(): string {
        let osName:string = "";
        if(navigator.userAgent.indexOf(BrowserConstants.MAC_OS) !== -1) {
            osName = BrowserConstants.MAC_OS;
        } else if(navigator.userAgent.indexOf(BrowserConstants.LINUX_OS) !== -1) {
            osName = BrowserConstants.LINUX_OS;
        } else if(navigator.userAgent.indexOf(BrowserConstants.WINDOWS_OS) !== -1) {
            osName = BrowserConstants.WINDOWS_OS;
        }
        return osName;
    }

    getBrowserName(): string {
        let browserName: string = "";
        let osName: string = this.getOSName();
        if(navigator.userAgent.indexOf(BrowserConstants.FIREFOX) !== -1) {
            browserName = BrowserConstants.FIREFOX;
        } else if(osName === BrowserConstants.MAC_OS && navigator.userAgent.indexOf(BrowserConstants.SAFARI) !== -1 
                    && navigator.userAgent.indexOf(BrowserConstants.CHROME) === -1) {
            browserName = BrowserConstants.SAFARI;
        } else if(navigator.userAgent.indexOf(BrowserConstants.EDGE) !== -1) {
            browserName = BrowserConstants.EDGE;
        } else if(navigator.userAgent.indexOf(BrowserConstants.CHROME) !== -1) {
            browserName = BrowserConstants.CHROME;
        }
        return browserName;
    }

    handleErrors(error: HttpErrorResponse, param: { msg: string; condition: boolean }[]=null, fallbackMsg: string=null, hideStatusInfo = false) {
        if (error.statusText != 'HANDLED') {
            if (param) {
                for (let item of param) {
                    if (item.condition) {
                        this.showConfirmAlert(Alerts.ERROR, item.msg)
                        return;
                    }
                }
            }
            const msg = fallbackMsg ? fallbackMsg : "Unexpected error occurred";
            const statusInfo = hideStatusInfo ? '' : `, ${error.status}/${error.statusText}`;
            this.showConfirmAlert(Alerts.ERROR, `${msg}${statusInfo}`);
        }
    }

    titleCase(sentence: string): string {
        return sentence ? sentence.toLowerCase().split('_').map(function(word) {
            return word.replace(word[0], word[0].toUpperCase());
          }).join(' ') : '';
    }

}
