// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mapFloorplanDropdown {
  margin: 10px;
}

.selectFloorplan {
  background-color: white;
  border: none;
  border-radius: 0.5;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  width: 200px;
  height: 40px;
  font-size: 16px;
  padding: 10px;
  outline: none;
}

.optionFloorplan {
  background-color: white;
  border: none;
  border-radius: 0;
  width: 150px;
  height: 40px;
  font-size: 16px;
  padding: 10px;
}

.buttonOpenFloorplan {
  color: black;
  border: none;
  margin-left: 5px;
  border-radius: 0.5;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
}

th {
  padding: 0.5rem;
}

td {
  padding: 0.5rem;
  white-space: normal;
}`, "",{"version":3,"sources":["webpack://./src/app/power-search/power-data-landmark-results/power-data-landmark-results.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAEA;EACE,uBAAA;EACA,YAAA;EACA,kBAAA;EACA,+CAAA;EACA,YAAA;EACA,YAAA;EACA,eAAA;EACA,aAAA;EACA,aAAA;AACF;;AAEA;EACE,uBAAA;EACA,YAAA;EACA,gBAAA;EACA,YAAA;EACA,YAAA;EACA,eAAA;EACA,aAAA;AACF;;AAEA;EACE,YAAA;EACA,YAAA;EACA,gBAAA;EACA,kBAAA;EACA,+CAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,eAAA;EACA,mBAAA;AACF","sourcesContent":[".mapFloorplanDropdown {\n  margin: 10px;\n}\n\n.selectFloorplan{\n  background-color: white;\n  border: none;\n  border-radius: 0.5;\n  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;    \n  width: 200px;\n  height: 40px;\n  font-size: 16px;\n  padding: 10px;\n  outline: none;\n}\n\n.optionFloorplan{\n  background-color: white;\n  border: none;\n  border-radius: 0;\n  width: 150px;\n  height: 40px;\n  font-size: 16px;\n  padding: 10px;\n}\n\n.buttonOpenFloorplan{\n  color: black;\n  border: none;\n  margin-left: 5px;\n  border-radius: 0.5;\n  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;\n}\n\nth {\n  padding: 0.5rem;\n}\n\ntd {\n  padding: 0.5rem;\n  white-space: normal;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
