//import {OrgPreferenceEnum} from './orgPreferenceEnum';
import {EntitlementTo} from './entitlementTo';

export class OrganizationTo {
    name: string;
    uuid: number;
    defaultUUID: string;
    clientId: string;
    entitlements: Array<string>;
    type: string;
    parentClientId: string;
    psapGroup: string;

    constructor(object?: any) {
        this.name = object.name;
        this.uuid = object.uuid;
        this.defaultUUID = object.defaultUUID;
        this.clientId = object.clientId;
        this.uuid = object.uuid;
        this.type = object.type;
        this.parentClientId = object.parentClientId;
        if (object.hasOwnProperty('entitlements')) {
          this.entitlements = object.entitlements;
        }
        this.psapGroup = object.psapGroup;
    }
}
