// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `mat-drawer {
  background-color: #f8f9fa;
  width: 475px;
}

mat-drawer-container {
  height: 100vh;
}

.drawer-container {
  overflow: auto;
}

.buttonMatDrawerToggle {
  position: absolute;
  top: 65px;
  left: -4px;
  border-radius: 4px;
  padding: 0;
  max-width: 30px;
  min-width: 30px;
  height: 50px;
  z-index: 4;
}

.nopadding {
  padding: 0 !important;
}

mat-button-toggle-group {
  background: white;
}

mat-button-toggle {
  color: black;
}

.mat-button-toggle-checked {
  font-weight: 500;
}

th {
  padding: 0.5rem;
}

td {
  padding: 0.5rem;
}

.mat-expansion-panel {
  box-shadow: none !important;
}

.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: inherit;
}

.mat-expansion-panel-header {
  padding: 0;
}

.mat-expansion-panel-content .mat-expansion-panel-body {
  padding: 0 !important;
}

.map {
  width: 100%;
  height: 92.5vh;
}

.autoAdjustDiv {
  background-color: white;
}

.autoAdjustCheckbox {
  font-size: 16px;
  margin-bottom: -7px;
}`, "",{"version":3,"sources":["webpack://./src/app/power-search/dbh-results/dbh-results.component.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,YAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,cAAA;AACF;;AAEA;EACE,kBAAA;EACA,SAAA;EACA,UAAA;EACA,kBAAA;EACA,UAAA;EACA,eAAA;EACA,eAAA;EACA,YAAA;EACA,UAAA;AACF;;AAEA;EACE,qBAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,2BAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,UAAA;AACF;;AAGE;EACE,qBAAA;AAAJ;;AAIA;EACE,WAAA;EACA,cAAA;AADF;;AAIA;EACE,uBAAA;AADF;;AAIA;EACE,eAAA;EACA,mBAAA;AADF","sourcesContent":["mat-drawer {\n  background-color: #f8f9fa;\n  width: 475px;\n}\n\nmat-drawer-container {\n  height: 100vh;\n}\n\n.drawer-container {\n  overflow: auto;\n}\n\n.buttonMatDrawerToggle{\n  position: absolute;\n  top: 65px;\n  left: -4px;\n  border-radius: 4px;    \n  padding: 0;\n  max-width: 30px;\n  min-width: 30px;\n  height: 50px;\n  z-index: 4;\n}\n\n.nopadding {\n  padding: 0 !important;\n}\n\nmat-button-toggle-group {\n  background: white;\n}\n\nmat-button-toggle {\n  color: black;\n}\n\n.mat-button-toggle-checked {\n  font-weight: 500;\n}\n\nth {\n  padding: 0.5rem;\n}\n\ntd {\n  padding: 0.5rem;\n}\n\n.mat-expansion-panel {\n  box-shadow: none !important;\n}\n\n.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {\n  background: inherit;\n}\n\n.mat-expansion-panel-header {\n  padding: 0;\n}\n\n.mat-expansion-panel-content {\n  .mat-expansion-panel-body {\n    padding: 0 !important;\n  }\n}\n\n.map {\n  width: 100%;\n  height: 92.5vh;\n}\n\n.autoAdjustDiv {\n  background-color: white;\n}\n\n.autoAdjustCheckbox {\n  font-size: 16px; \n  margin-bottom: -7px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
