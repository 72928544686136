import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SharedService } from '../../shared/shared.service';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv'
import { PredefinedTextMessage } from 'src/app/shared/models/predefinedTextMessage';
import { PredefinedTextMessageService } from '../predefined-text-message.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Prefix } from 'src/app/shared/models/prefix';

@Component({
    selector: 'app-bulk-message-upload',
    templateUrl: './bulk-message-upload.component.html',
    styleUrls: ['./bulk-message-upload.component.scss']
})

export class BulkMessageUploadComponent implements OnInit {
    header: string = "Bulk Upload";
    title: string;
    isFileAdded: boolean = false;
    isLoading: boolean = false;
    isError: boolean = false;
    isStatus: boolean = true;
    fileName: string;
    files;
    noFile: boolean = false;
    statusMsg: Array<any> = [];
    noFileStatus: string;
    csv: string;
    totalLines: Array<any> = [];
    emptyFile: boolean = false;
    wrongFormat: boolean = false;
    dataSource = new MatTableDataSource<Object>(this.statusMsg);
    displayedColumns: string[] = ['prefix','message', 'status', 'comment'];
    successCount: Array<any> = [];
    failureCount: Array<any> = [];
    fileHeader: string;
    predefinedTextMsg: PredefinedTextMessage = new PredefinedTextMessage();
    csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '',
        decimalseparator: '.',
        showLabels: true,
        showTitle: false,
        title: '',
        useBom: false,
        noDownload: false,
        headers: ["Prefix", "Message"]
    };
    sampleData: any;
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;
    prefixList: Prefix[] = [];
    showText: boolean = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
                private dialogRef: MatDialogRef<BulkMessageUploadComponent>,
                private predefinedTextMessageService: PredefinedTextMessageService) {
    }

    ngOnInit() {
        this.sampleData = [ ["!#", "Test Message: BALTIMORE CITY 9-1-1 WHAT IS THE ADDRESS OF YOUR EMERGENCY"], 
                            ["#?", "Test Message: 9-1-1 what is the address of your emergency?"] 
                        ];
        this.predefinedTextMessageService.getAllPrefixes().subscribe(data =>{
            this.prefixList = data;
        });
    }

    onFilesAdded(files) {
        if (files && files.length > 0) {
            this.fileName = files[0].name;
            this.files = files;
        }
    }

    downloadFiles() {
        new AngularCsv(this.sampleData, "Sample-File", this.csvOptions);
    }

    uploadFiles() {
        if (this.files) {
            Swal.fire({
                text: 'Do you want to upload?',
                icon: 'info',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
            }).then((result) => {
                if (result.value) {
                    this.isFileAdded = true;
                    this.isLoading = true;
                    const file: File = this.files.item(0);
                    const reader: FileReader = new FileReader();
                    reader.readAsText(file);
                    reader.onload = (e) => {
                        this.csv = reader.result as string;
                        this.addFileData();
                    };
                }
            });
        } else {
            this.noFile = true;
        }
    }

    addFileData() {
        const lines = this.csv.match(/[^\r\n]*/g);
        const newLines = lines.filter((line) => {
            return line !== ',,,';
        });
        this.totalLines = newLines.filter((line) => {
            return line !== '';
        });
        if (this.totalLines.length !== 0) {
            this.fileHeader = this.totalLines[0].split(',');
        }
        if (this.totalLines.length > 1) {
            if (this.fileHeader[0] === "Prefix" || this.fileHeader[1] === "Message") {
                for (let i = 1; i < this.totalLines.length; i++) {
                    let line = [];
                    line = this.totalLines[i].split(/,(.*)/s);
                    this.predefinedTextMsg.prefixId = line[0].trim();
                    this.predefinedTextMsg.message = line[1].trim();
                    this.addTextMessage(this.predefinedTextMsg);
                }
            } else {
                this.isLoading = false;
                this.isStatus = false;
                this.wrongFormat = true;
                this.noFileStatus = 'Invalid File Format, Please check the given sample file format and upload.';
            }
        } else if (this.totalLines.length === 1 && (this.fileHeader[0] !== "Prefix"  || this.fileHeader[1] !== "Message" )) {
            this.isLoading = false;
            this.isStatus = false;
            this.wrongFormat = true;
            this.noFileStatus = 'Invalid File Format, Please check the given sample file format and upload.';
        } else {
            this.isLoading = false;
            this.isStatus = false;
            this.emptyFile = true;
            this.noFileStatus = 'Please check the uploaded file, No Messages to Create';
        }
    }

    addTextMessage(predefinedTextMsg: PredefinedTextMessage) {
        const status: Object = {
            'prefix' : '',
            'message': '',
            'status': '',
            'comment': '',
        };
        status['prefix'] = predefinedTextMsg.prefixId;
        status['message'] = predefinedTextMsg.message;
        let prefix = this.prefixList.find(x => x.prefix === this.predefinedTextMsg.prefixId);
        this.predefinedTextMsg.prefixId = prefix ? prefix.prefixId : undefined;
        if (predefinedTextMsg.message.length < 2 || predefinedTextMsg.message.length > 500) {
            status['status'] = "Failure";
            status['comment'] = "Message name must be between 2 and 500 characters";
            this.recordResponse(status);
        } else if (!predefinedTextMsg.prefixId) {
            status['status'] = "Failure";
            status['comment'] = "Invalid prefix";
            this.recordResponse(status);
        } else if (predefinedTextMsg.prefixId.length < 1 ) {
            status['status'] = "Failure";
            status['comment'] = "Prefix must not be empty";
            this.recordResponse(status);
        } else  {
            this.predefinedTextMessageService.addPredefinedTextMessage(predefinedTextMsg).subscribe({
                next: data => {
                    status['status'] = 'Success';
                    status['comment'] = 'Added successfully';
                    this.recordResponse(status);
                },
                error: error => {
                    status['status'] = 'Failure';
                    if (error.error) {
                        status['comment'] = error.error;
                    } else {
                        status['comment'] = '"Unable to add text message"';
                    }
                    this.recordResponse(status);
                },
                complete: () => { }
            });
        }
    }

    recordResponse(status:Object) {
        this.statusMsg.push(status);
        if (this.statusMsg.length === (this.totalLines.length - 1)) {
            this.statusMsg.forEach(data => {
                if (data.status === 'Failure') {
                    this.failureCount.push(data);
                } else if (data.status === 'Success') {
                    this.successCount.push(data);
                }
            });
            this.dataSource = new MatTableDataSource<any>(this.statusMsg);
            setTimeout(() => this.dataSource.paginator = this.paginator);
            setTimeout(() => this.dataSource.sort = this.sort);
            this.isLoading = false;
            this.isStatus = false;
            this.predefinedTextMessageService.updatePredefinedTextMessageList.next();
        }
        this.title = this.totalLines
            ? `${this.statusMsg.length} out of ${this.totalLines.length-1} messages are processed`
            : '';
    }

    close() {
        if (this.statusMsg.length >= 1) {
            this.dialogRef.close(true);
        } else {
            this.dialogRef.close(false);
        }
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

}
