export class TemplateFileTo {
    name : string
    sourceFilename: string;
    sourceFile: File;
    destinationFilename: string;
    defaultTemplate: boolean;

    constructor(object?: any) {
        this.name = object?.name;
        this.sourceFilename = object?.sourceFilename;
        this.sourceFile = object?.sourceFile;
        this.destinationFilename = object?.destinationFilename;
        this.defaultTemplate = object?.defaultTemplate;
    }

}
