import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Alerts } from 'src/app/shared/enums/alerts';
import { PredefinedTextMessage } from 'src/app/shared/models/predefinedTextMessage'; 
import { SharedService } from 'src/app/shared/shared.service';
import { PredefinedTextMessageBase } from '../predefined-text-message-base';
import { PredefinedTextMessageService } from '../predefined-text-message.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Prefix } from 'src/app/shared/models/prefix';

@Component({
    selector: 'app-update-predefined-text-message',
    templateUrl: './update-predefined-text-message.component.html',
    styleUrls: ['./update-predefined-text-message.component.scss']
})
export class UpdatePredefinedTextMessageComponent extends PredefinedTextMessageBase implements OnInit {

    options: FormGroup;
    resetData: PredefinedTextMessage;
    predefinedTextMessage: PredefinedTextMessage;
    loading: boolean = false;
    prefixList: Prefix[] = [];
    
    constructor(@Inject(MAT_DIALOG_DATA) private data: PredefinedTextMessage,
                private fb: FormBuilder,
                private predefinedTextMessageService: PredefinedTextMessageService, 
                public dialogRef: MatDialogRef<UpdatePredefinedTextMessageComponent>,
                protected sharedService: SharedService) {
        super();
        this.options = this.fb.group({
            hideRequired: false,
            floatLabel: 'auto',
        });
    }

    ngOnInit() {
        this.getAllPrefix();
    }

    change(event) {
        if (event.srcElement.value !== '') {
            this.count = this.count + 1;
        } else if (event.srcElement.value === '') {
            this.count = this.count - 1;
        }
        if (this.count <= 1 || this.fieldcheck === false) {
            this.fieldcheck = !this.fieldcheck;
        }
    }

    getAllPrefix() {
        this.loading = true;
        this.predefinedTextMessageService.getAllPrefixes().subscribe(data => {
            this.prefixList = data;
            this.prefixList.sort((a,b) => a.description.toLocaleLowerCase() < b.description.toLocaleLowerCase() ? -1 : 1);
            this.predefinedTextMessage = new PredefinedTextMessage(this.data);
            this.resetData = new PredefinedTextMessage(this.data);
            this.loading = false;
        });
    }

    updatePredefinedTextMessage() {
        if(this.isInValidData()) {
            return;
        }

        if(this.resetData.message !== this.predefinedTextMessage.message.trim() ||
           this.resetData.prefixId !== this.predefinedTextMessage.prefixId ) {
            this.loading = true;
            this.predefinedTextMessage.message = this.predefinedTextMessage.message.trim();
            this.predefinedTextMessageService.updatePredefinedTextMessage(this.predefinedTextMessage).subscribe({
                next: data => {
                    this.loading = false;
                    this.sharedService.showAlert(Alerts.SUCCESS, 'Predefined textMessage updated successfully');
                    this.dialogRef.close(true);
                },
                error: error => {
                    this.loading = false;
                    this.sharedService.handleErrors(error,
                        [{
                            "condition": (error.status == 400 && error.error),
                            "msg": error.error
                        }],
                        "Failed to update text Message");
                },
                complete: () => { }
            });
        } else {
            this.sharedService.showAlert(Alerts.ERROR, 'Please modify the details to update');
            this.markFormControlsAsTouched();
        }
    }

    resetPredefinedTextMessage() {
        if (this.fieldcheck === true) {
            Swal.fire({
                title: 'Are you sure?',
                text: 'Your changes will be lost!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes, Reset',
                cancelButtonText: 'No, Cancel',
            }).then((result) => {
                if (result.value) {
                    this.predefinedTextMessage = new PredefinedTextMessage(this.resetData);
                    this.fieldcheck = false;
                }
            });
        } else {
            this.predefinedTextMessage = new PredefinedTextMessage(this.resetData);
        }
    }

    close() {
        if (this.fieldcheck === true) {
            Swal.fire({
                title: 'Are you sure?',
                text: 'Your changes will not be saved !',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes, Close',
                cancelButtonText: 'No, Cancel',
            }).then((result) => {
                if (result.value) {
                    this.dialogRef.close(false);
                }
            });
        } else {
            this.dialogRef.close(false);
        }
    }

}
