import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SharedService } from './../shared/shared.service'
import { ConfigService } from '../../assets/config/config-svc.service';
import { BrowserConstants } from './../shared/enums/browser-constants';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Injectable({
    providedIn: 'root'
})
export class VersionCheckService {
    
    currentVersion: string = require('../../../package.json').version;
    isUserNotified: boolean = false;
    
    constructor(private http: HttpClient, private sharedService: SharedService) {}

    /**
     * Checks in every set frequency the version of application.
     * frequency - in milliseconds, defaults to 1 minute
     */
    initVersionCheck(frequency: number = ConfigService.config.versionCheckFrequency) {
        setInterval(() => {
            this.checkVersion();
        }, frequency);    
    }

    checkVersion() {
        // timestamp this request to invalidate cache
        this.http.get('/assets/config.json?t=' + new Date().getTime()).subscribe({
            next: (response: any) => {
                const newVersion = response.version;
                const versionChanged = this.hasVersionChanged(this.currentVersion, newVersion);

                // If new version found, notify user.
                if (versionChanged && !this.isUserNotified) {
                    this.isUserNotified = true;
                    const reloadCommand: string = this.getReloadCommand();
                    Swal.fire({
                        title: 'Version Upgrade Alert',
                        icon: 'info',
                        width: 600,
                        padding: '2em',
                        html: 
                            '<div class="mt-2" style="text-align:left; font-size: 1rem;">' + 
                            '<p>A new version of PowerData is available. To update now, reload your browser as follow:</p>' +
                            '<p>Press '+ reloadCommand + '</p>'+
                            '</div>'
                    });
                }
                
                this.currentVersion = newVersion;
            },
            error: (error: any) => {
                console.error(error, 'Could not get version');
            },
            complete: () => {}
      });
    }

    hasVersionChanged(currentVersion:string, newVersion:string): boolean {
        if (!currentVersion) {
            return false;
        }
        return currentVersion !== newVersion;
    }

    getReloadCommand(): string {
        const browserName = this.sharedService.getBrowserName();
        const osName = this.sharedService.getOSName();

        if((osName === BrowserConstants.WINDOWS_OS || osName === BrowserConstants.LINUX_OS) 
                && (browserName === BrowserConstants.CHROME || browserName === BrowserConstants.FIREFOX)) {
            return '<code>' + BrowserConstants.CHROME_FIREFOX_RELOAD_CMD_FOR_WINDOWS + '</code>';
        } else if(osName === BrowserConstants.MAC_OS && (browserName === BrowserConstants.CHROME || browserName === BrowserConstants.FIREFOX)) {
            return '<code>' + BrowserConstants.CHROME_FIREFOX_RELOAD_CMD_FOR_MAC  + '</code>';
        } else if(osName === BrowserConstants.MAC_OS && browserName === BrowserConstants.SAFARI) {
            return '<code>' + BrowserConstants.SAFARI_EMPTY_CACHE_CMD_FOR_MAC + '</code> and then <code>' + BrowserConstants.SAFARI_RELOAD_CMD_FOR_MAC  + '</code>';
        } else {
            return '<code>' + BrowserConstants.OTHER_BROWSER_RELOAD_CMD  + '</code>';
        }
    }
}