import { Injectable } from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse} from '@angular/common/http';
import {config, Observable, throwError} from 'rxjs';
import {ConfigService} from '../../assets/config/config-svc.service';
import {catchError} from "rxjs/operators";
import {SharedService} from "../shared/shared.service";
import {Alerts} from "../shared/enums/alerts";
import {Router} from '@angular/router';
import {LoginService} from '../login/login.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(public sharedService: SharedService,
                private loginService: LoginService
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add auth header with jwt if user is logged in and request is to api url

        if (typeof ConfigService.config !== 'undefined' && this.needsAuth(request.url)) {
            const token = localStorage.getItem("id_token");
            if (!token || token === 'null') {
                this.loginService.signOut();
            }
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`
                }
            });
        }

        return next.handle(request)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    let errorMsg = '';
                    let handled = false;
                    if (error.error instanceof ErrorEvent) {
                        console.log('client side error: ');
                        errorMsg = `Error: ${error.error.message}`;
                        this.sharedService.showAlert(Alerts.ERROR, 'Unexpected client error occurred.');
                        handled =true;
                    } else {
                        console.log('server side error');
                        errorMsg = `Error Code: ${error.status}, Message: ${error.message}`;
                        if (this.sharedService.isTokenExpired()) {
                            this.sharedService.handleTokenExpiration(this.sharedService.tokenExpiryTitle, this.sharedService.tokenExpiryMsg);
                            handled = true;
                        } else if (error.status >= 500) {
                            this.sharedService.showAlert(Alerts.ERROR, 'Unexpected error occurred.');
                            handled = true;
                        }
                    }
                    console.log(errorMsg);
                    console.log("handled: " + handled);
                    return throwError(handled ? new HttpErrorResponse({error: error.error, statusText: 'HANDLED'}) : error);
                })
            );
    }



    needsAuth(url:string) : boolean {
        if (url.includes("/restapi/")) {
            return false;
        }
        if (url.includes("/noauth/")) {
            return false;
        }
        if (url.includes(ConfigService.config.powerdataDomain)) {
            return true;
        }
        if (url.includes(ConfigService.config.brokerServiceUrl)) {
            return true;
        }
        return false;
    }
}

