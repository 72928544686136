import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, Validators, ValidatorFn} from '@angular/forms';
import {PowerSearchService} from './power-search.service';
import {SharedService} from '../shared/shared.service';
import {PowerSearchValidator} from './power-search-validators';
import {HomeService} from '../home/home.service';
import {Point} from '../shared/models/point';
import {BrokerSearchRequestTo} from '../shared/models/BrokerSearchRequestTo';
import {CivicAddressTo} from '../shared/models/CivicAddressTo';
import {StateNameSpace} from '../shared/enums/stateList-enum';
import {VehicleSearchRequestTo} from "../shared/models/vehicleSearchRequest";
import {Roles} from "../shared/enums/roles";
import {SearchTypeTo} from '../shared/models/searchTypeTo';
import {UsageEnum} from '../shared/enums/usageEnum';
import {SearchTypesService} from '../search-types/search-type.service';
import { ProvidersService } from '../providers/provider.service';
import { ProviderTo } from '../shared/models/providerTo';
import Swal from 'sweetalert2/dist/sweetalert2.js';
var parseAddress = require('parse-address');

enum Fields {
    PHONE_NUMBER,
    LATITUDE,
    LONGITUDE,
    ALTITUDE,
    VEHICLE,
    ADDRESS,
    SEARCH_TYPE,
    OPTIONAL_FIELD
}

enum ErrorValues {
    REQUIRED = 'You must enter a value',
    ONE_FIELD_REQUIRED = 'You must fill at least one input field - ',
    SEARCHTYPE_REQUIRED = 'You must select at least one search type',
    PHONEPATTERN = 'Invalid characters in phone number',
    PHONENUMBERDIGIT = 'Phone number must be between 10 and 15 digits',
    VEHICELPATTERN = ' is not a valid 2-digit state code or a valid state name',
    VEHICLEREQUIRED = 'Please enter either a comma or space separated license tag number and 2-digit state code, or a VIN',
    VALIDATESPECIALCHAR = 'Invalid characters included in VIN',
    INVALIDADDRES = 'Invalid Address -- address does not exist',
    LATITUDE = '39.376129',
    LONGITUDE = '-76.603939',
    ALTITUDE = '100 meters',
    ADDRESSPATTERN = 'Enter a comma separated address including street address,city, and state plus optional ZIP code',
    STREETADDRESSPATTERN = 'Please enter a valid street address.',
    STATECODEPATTERN = 'Please include a valid state name or 2-letter state code'
}

enum Validation {
    REQUIRED = 'required',
    PATTERN = 'pattern',
    VALIDATEVEHICLEVIN = 'validVehicle',
    VALIDATESPECIALCHAR = 'validSpecialChar',
    VALIDNUMBERGREATERTHAN15 = 'validNumberGreaterThan15',
    VALIDATEADDRESSFORMAT = 'validAddressFormat',
    VALIDSTREETADDRESS = 'validStreetAddress',
    VALIDSTATECODE = 'validStateCode'
}

@Component({
    selector: 'app-power-search',
    templateUrl: './power-search.component.html',
    styleUrls: ['./power-search.component.scss']
})
export class PowerSearchComponent implements OnInit {
    isSearchEnabled: boolean;
    isEntitlementsMapped: boolean;
    isPowerSearchLoading: boolean = false;
    searchTypeError: boolean = false;
    isAllSearchTypeChecked: boolean = false;
    optionalFieldError: boolean = false;
    searchTypeHavingError: SearchTypeTo = null;
    searchTypes: Array<SearchTypeTo> = [];
    allProviders: Array<ProviderTo> = []
    selectedSearchTypes: Array<SearchTypeTo> = [];
    webCrawlerData: any;
    fields = Fields;

    phoneNumberValidators: ValidatorFn[] = [PowerSearchValidator.validPhone];
    latitudeValidators: ValidatorFn[] = [Validators.min(-90), Validators.max(90)];
    longitudeValidators: ValidatorFn[] = [Validators.min(-180), Validators.max(180)];
    addressValidators: ValidatorFn[] = [PowerSearchValidator.validAddressFormat];
    vehicleNumberValidators: ValidatorFn[] = [PowerSearchValidator.validVehicle];

    phoneNumber = new UntypedFormControl();
    latitude = new UntypedFormControl();
    longitude = new UntypedFormControl();
    altitude = new UntypedFormControl();
    address = new UntypedFormControl();
    vehicleNumber = new UntypedFormControl();

    constructor(public powerSearchService: PowerSearchService,
                public sharedService: SharedService, 
                private homeService: HomeService,
                private searchTypesService: SearchTypesService,
                private providersService: ProvidersService) {
    }

    ngOnInit() {
        this.isSearchEnabled = this.homeService.userDetails.roles.includes(Roles.SEARCH) || this.homeService.userDetails.roles.includes(Roles.SUPER);
        if(this.homeService.userDetails.org.entitlements.length > 0) {
            this.isEntitlementsMapped = true;
            this.getAllProviders();
        }
    }

    getAllProviders() {
        this.isPowerSearchLoading = true;
        this.providersService.getAllProviders().subscribe({
            next: data => {
                this.allProviders = data;
                this.loadSearchTypes();
            },
            error: error => {
                this.isPowerSearchLoading = false;
                this.sharedService.handleErrors(error);
            },
            complete: () => { }
        });
    }

    loadSearchTypes(): void {
        this.isPowerSearchLoading = true;
        this.searchTypesService.fetchAvailableSearchTypes().subscribe({
            next: data => {
                this.searchTypes = data;
                this.searchTypes.sort((a, b) => a.name < b.name ? -1 : (a.name > b.name ? 1 : 0));
                this.isPowerSearchLoading = false;
            },
            error: error => {
                this.isPowerSearchLoading = false;
                this.sharedService.handleErrors(error);
            },
            complete: () => { }
        });
    }

    hasSensitiveDataProvider(searchTypeName: string) {
        return this.allProviders.filter(p => p.enabled && p.searchTypeName === searchTypeName && p.sensitiveData).length > 0;
    }

    changeAllSearchType(value:boolean): void {
        this.isAllSearchTypeChecked = value;
        this.searchTypes.forEach(searchType => {
            this.changeSearchType(value, searchType);
        });
    }

    changeSearchType(value:boolean, searchType:SearchTypeTo): void {
        if (value) {
            this.searchTypeError = false;
            if (this.selectedSearchTypes.length === 0) {
                this.selectedSearchTypes.push(searchType);
                this.addValidators(searchType);
            } else {
                const index = this.selectedSearchTypes.findIndex(s => s.name === searchType.name);
                if (index === -1) {
                    this.selectedSearchTypes.push(searchType);
                    this.addValidators(searchType);
                }
            }
            if (this.selectedSearchTypes.length === this.searchTypes.length) {
                this.isAllSearchTypeChecked = true;
            }
        } else {
            this.isAllSearchTypeChecked = false;
            const index = this.selectedSearchTypes.findIndex(s => s.name === searchType.name);
            if (index >= 0) {
                this.selectedSearchTypes.splice(index, 1);
                this.removeValidators();
            }
        }
    }

    addValidators(searchType: SearchTypeTo): void {
        if(searchType.telephoneUsage === UsageEnum.MANDATORY) {
            this.phoneNumber.setValidators([Validators.required, Validators.compose(this.phoneNumberValidators)]);
        }
        if(searchType.telephoneUsage === UsageEnum.OPTIONAL && !this.phoneNumber.validator) {
            this.phoneNumber.setValidators([Validators.compose(this.phoneNumberValidators)]);
        }

        if(searchType.locationUsage === UsageEnum.MANDATORY) {
            this.latitude.setValidators([Validators.required, Validators.compose(this.latitudeValidators)]);
            this.longitude.setValidators([Validators.required, Validators.compose(this.longitudeValidators)]);
        }
        if(searchType.locationUsage === UsageEnum.OPTIONAL && !this.latitude.validator && !this.longitude.validator) {
            this.latitude.setValidators([Validators.compose(this.latitudeValidators)]);
            this.longitude.setValidators([Validators.compose(this.longitudeValidators)]);
        }

        if(searchType.addressUsage === UsageEnum.MANDATORY) {
            this.address.setValidators([Validators.required, Validators.compose(this.addressValidators)]);
        }
        if(searchType.addressUsage === UsageEnum.OPTIONAL && !this.address.validator) {
            this.address.setValidators([Validators.compose(this.addressValidators)]);
        }
    }

    removeValidators(): void {
        this.phoneNumber.clearValidators();
        this.phoneNumber.updateValueAndValidity();
        this.latitude.clearValidators();
        this.latitude.updateValueAndValidity();
        this.longitude.clearValidators();
        this.longitude.updateValueAndValidity();
        this.address.clearValidators();
        this.address.updateValueAndValidity();
        this.vehicleNumber.clearValidators();
        this.vehicleNumber.updateValueAndValidity();

        this.selectedSearchTypes.forEach(searchType => {
            this.addValidators(searchType);
        });
    }

    getErrorMessage(field: number): string {
        if (field === Fields.PHONE_NUMBER) {
            return this.phoneNumber.hasError(Validation.REQUIRED) ? ErrorValues.REQUIRED :
                this.phoneNumber.hasError(Validation.VALIDATESPECIALCHAR) ? ErrorValues.PHONEPATTERN :
                    this.phoneNumber.hasError(Validation.VALIDNUMBERGREATERTHAN15) ? ErrorValues.PHONENUMBERDIGIT : '';

        }
        if (field === Fields.VEHICLE) {
            let state:string;
            if (this.vehicleNumber.value) {
                state = this.vehicleNumber.value.split(',');
            }
            return this.vehicleNumber.hasError(Validation.REQUIRED) ? ErrorValues.REQUIRED :
                    this.vehicleNumber.hasError(Validation.VALIDATESPECIALCHAR) ? ErrorValues.VALIDATESPECIALCHAR :
                        this.vehicleNumber.hasError(Validation.VALIDATEVEHICLEVIN) ? state[1] + ' ' + ErrorValues.VEHICELPATTERN : '';

        }
        if (field === Fields.ADDRESS) {
            return this.address.hasError(Validation.REQUIRED) ? ErrorValues.REQUIRED :
                this.address.hasError(Validation.VALIDATEADDRESSFORMAT) ? ErrorValues.ADDRESSPATTERN :
                    this.address.hasError(Validation.VALIDSTREETADDRESS) ? ErrorValues.STREETADDRESSPATTERN :
                        this.address.hasError(Validation.VALIDSTATECODE) ? ErrorValues.STATECODEPATTERN : '';
        }
        if (field === Fields.LATITUDE) {
            return this.latitude.hasError(Validation.REQUIRED) ? ErrorValues.REQUIRED : ErrorValues.LATITUDE;
        }
        if (field === Fields.LONGITUDE) {
            return this.longitude.hasError(Validation.REQUIRED) ? ErrorValues.REQUIRED : ErrorValues.LONGITUDE;
        }
        if (field === Fields.SEARCH_TYPE && this.searchTypeError) {
            return ErrorValues.SEARCHTYPE_REQUIRED;
        }
        if(field === Fields.OPTIONAL_FIELD && this.optionalFieldError) {
            let errorText = "";
            if(this.searchTypeHavingError.telephoneUsage === UsageEnum.OPTIONAL){
                errorText += errorText.length > 0 ?  " | " : "";
                errorText += "Phone Number";
            }
            if(this.searchTypeHavingError.locationUsage === UsageEnum.OPTIONAL){
                errorText += errorText.length > 0 ?  " | " : "";
                errorText += "Location (Latitude & Longitude)";
            }
            if(this.searchTypeHavingError.addressUsage === UsageEnum.OPTIONAL){
                errorText += errorText.length > 0 ?  " | " : "";
                errorText += "Address";
            }
            return this.searchTypeHavingError.name + " : " + ErrorValues.ONE_FIELD_REQUIRED + errorText;
        }
    }

    doPowerSearch(): void {
        if(this.validateForm()) {
            this.isPowerSearchLoading = true;
            const payload: BrokerSearchRequestTo = this.createPayload();
            this.powerSearchService.doPowerSearch(payload, (result: any) => {
                if (result && result.length > 0 && this.isPowerSearchLoading) {
                    this.isPowerSearchLoading = false;
                    this.sharedService.setSearchResults(result);
                    this.sharedService.setSearchHistoryData([]);
                    this.homeService.navigate.next('searchResults');
                }
            });
        }
    }

    validateForm(): boolean {
        this.searchTypeHavingError = null;
        this.optionalFieldError = false;
        this.markFormControlsAsTouched();
        if(this.selectedSearchTypes.length === 0) {
            this.searchTypeError = true;
        }
        
        for(let searchType of this.selectedSearchTypes) {
            let anyMandatoryField = false;
            if(searchType.telephoneUsage === UsageEnum.MANDATORY || 
                searchType.locationUsage === UsageEnum.MANDATORY || 
                searchType.addressUsage === UsageEnum.MANDATORY) {
                    anyMandatoryField = true;
            }
            if(!anyMandatoryField && this.isAllOptionalFieldEmpty(searchType)) {
                this.optionalFieldError = true;
                this.searchTypeHavingError = searchType;
                return false;
            }
        }

        if(this.phoneNumber.valid && this.longitude.valid && this.latitude.valid && this.address.valid 
                && this.vehicleNumber.valid && !this.searchTypeError && !this.optionalFieldError) {
            return true;
        } else {
            return false;
        }
    }

    isAllOptionalFieldEmpty(searchType: SearchTypeTo): boolean {
        let isEmpty: boolean = false;
        if(searchType.telephoneUsage === UsageEnum.OPTIONAL || 
            searchType.locationUsage === UsageEnum.OPTIONAL || 
            searchType.addressUsage === UsageEnum.OPTIONAL) {
            isEmpty = true;
        }
        if((searchType.telephoneUsage === UsageEnum.OPTIONAL && this.phoneNumber.value) ||
            (searchType.locationUsage === UsageEnum.OPTIONAL && this.latitude.value && this.longitude.value) || 
            (searchType.addressUsage === UsageEnum.OPTIONAL && this.address.value)) {
                isEmpty = false;
        }
        return isEmpty;
    }

    createPayload(): BrokerSearchRequestTo {
        const payload = new BrokerSearchRequestTo();
        payload.searchTypes = [];
        this.selectedSearchTypes.forEach(searchType => {
            payload.searchTypes.push(searchType.name);
        });

        if(this.phoneNumber.value) {
            payload.phone = this.phoneNumber.value.replace(/[^A-Z0-9]/ig, '');
        }

        if(this.latitude.value && this.longitude.value) {
            payload.location = new Point(this.latitude.value, this.longitude.value, this.altitude.value);
        }

        if(this.address.value) {
            payload.address = new CivicAddressTo(parseAddress.parseLocation(this.address.value));
        }

        if(this.vehicleNumber.value) {
            payload.vehicle = new VehicleSearchRequestTo({});
            const input = this.vehicleNumber.value.split(',');
            if (input[1]) {
                const inputtrimmed = input[1].replace(/\s/g, '');
                payload.vehicle.state = StateNameSpace.getStateByStateCode(inputtrimmed)
                    || StateNameSpace.getStateByStateName(inputtrimmed);
                payload.vehicle.plate = input[0].replace(/\s/g, '');
    
            } else {
                payload.vehicle.vin = input[0].replace(/\s/g, '');
            }
        }
        return payload;
    }

    clearPowerSearch() : void {
        this.phoneNumber.reset();
        this.phoneNumber.setErrors(null);
        this.latitude.reset();
        this.latitude.setErrors(null);
        this.longitude.reset();
        this.longitude.setErrors(null);
        this.address.reset();
        this.address.setErrors(null);
        this.vehicleNumber.reset();
        this.vehicleNumber.setErrors(null);
        this.changeAllSearchType(false);
        this.searchTypeError = false;
        this.optionalFieldError = false;
    }

    dismissPowerSearch(): void {
        this.isPowerSearchLoading = false;
    }
    
    valueChange() {
        this.webCrawlerData = null;
    }

    markFormControlsAsTouched() {
        this.phoneNumber.markAsTouched();
        this.phoneNumber.updateValueAndValidity();
        this.latitude.markAsTouched();
        this.latitude.updateValueAndValidity();
        this.longitude.markAsTouched();
        this.longitude.updateValueAndValidity();
        this.address.markAsTouched();
        this.address.updateValueAndValidity();
        this.vehicleNumber.markAsTouched();
        this.vehicleNumber.updateValueAndValidity();
    }

}
