// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.listBlock {
  margin: 0px -15px;
}

.credentialsList {
  overflow-y: auto;
  max-height: 60vh;
  padding: 0;
}

.addCredentials {
  flex-direction: column;
  display: flex;
  width: 50vh;
}

.mat-mdc-form-field {
  font-size: inherit !important;
}`, "",{"version":3,"sources":["webpack://./src/app/organizations/client-credentials/client-credentials.component.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,gBAAA;EACA,UAAA;AACJ;;AAEA;EACI,sBAAA;EACA,aAAA;EACA,WAAA;AACJ;;AAEA;EACI,6BAAA;AACJ","sourcesContent":[".listBlock {\n    margin: 0px -15px;\n}\n\n.credentialsList {\n    overflow-y: auto;\n    max-height: 60vh;\n    padding: 0;\n}\n\n.addCredentials {\n    flex-direction: column;\n    display: flex;\n    width: 50vh;\n}\n\n.mat-mdc-form-field {\n    font-size: inherit !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
