// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ngx-dropzone {
  margin: 20px;
}
ngx-dropzone.custom-dropzone {
  height: 50px;
  background: #333;
  color: #fff;
  border: 5px dashed rgb(235, 79, 79);
  border-radius: 5px;
  font-size: 20px;
}
ngx-dropzone.custom-dropzone.hovered {
  border-style: solid;
}

.msgStatus {
  display: block;
  overflow-y: auto;
  max-height: 35vh;
}

.message-overflow {
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100px;
}

.headerPosition {
  float: right;
  font-size: 14px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/iot-gateway/bulk-upload/bulk-message-upload.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;AACE;EACE,YAAA;EACA,gBAAA;EACA,WAAA;EACA,mCAAA;EACA,kBAAA;EACA,eAAA;AACJ;AACI;EACE,mBAAA;AACN;;AAIA;EACE,cAAA;EACA,gBAAA;EACA,gBAAA;AADF;;AAIA;EACE,2BAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;AADF;;AAIA;EACE,YAAA;EACA,0BAAA;AADF","sourcesContent":["ngx-dropzone {\n  margin: 20px;\n\n  &.custom-dropzone {\n    height: 50px;\n    background: #333;\n    color: #fff;\n    border: 5px dashed rgb(235, 79, 79);\n    border-radius: 5px;\n    font-size: 20px;\n\n    &.hovered {\n      border-style: solid;\n    }\n  }\n}\n\n.msgStatus {\n  display: block;\n  overflow-y: auto;\n  max-height: 35vh;\n}\n\n.message-overflow {\n  overflow: hidden !important;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  max-width: 100px;\n}\n\n.headerPosition {\n  float: right;\n  font-size: 14px !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
