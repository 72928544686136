import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MapMarker, MapInfoWindow } from '@angular/google-maps';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { EmailReportComponent } from 'src/app/email-report/email-report.component';
import { HomeService } from 'src/app/home/home.service';
import { OrganizationsService } from 'src/app/organizations/organizations.service';
import { OrgEntitlementsEnum } from 'src/app/shared/enums/org-entitlements.enum';
import { BrokerSearch } from 'src/app/shared/models/brokerSearch';

declare let google: any;

@Component({
    selector: 'app-medical-data-results',
    templateUrl: './medical-data-results.component.html',
    styleUrls: ['./medical-data-results.component.scss']
})
export class MedicalDataResultsComponent implements OnInit {

    @Input() brokerSearch: BrokerSearch;
    @Input() resultList: BrokerSearch[];
    showEmailResultIcon : boolean = false;
    mapCenter : any = {lat: 0, lng: 0};
    mapZoom : number;
    mapType: String;
    map: any;
    displayMap: boolean = false;

    constructor(private homeService : HomeService,
                private activeRouter: ActivatedRoute,
                private orgService : OrganizationsService,
                private dialog: MatDialog) { }

    ngOnInit() {
        this.initialize();
        this.checkShareResultEntitlement();
    }

    initialize() {
        this.mapZoom = 18;
        this.mapType = 'roadmap';
        if(this.brokerSearch.searchResult.caller_location?.location) {
            this.displayMap = true;
            this.mapCenter = {lat: +this.brokerSearch.searchResult.caller_location.location.latitude, lng: +this.brokerSearch.searchResult.caller_location.location.longitude};
        } else {
            this.displayMap = false;
        }
    }

    checkShareResultEntitlement() {
        this.showEmailResultIcon = false;
        if (this.homeService.userDetails && this.homeService.userDetails.org.clientId) {
            this.homeService.userDetails.org.entitlements.forEach( entitlement => {
                if (entitlement === OrgEntitlementsEnum.SHARE_RESULTS) {
                    this.showEmailResultIcon = true;
                }
            });
        } else if (this.activeRouter.snapshot.params['orgId']) {
            let clientId = this.activeRouter.snapshot.params['orgId']
            this.orgService.getServiceEntitlementsForOrg(clientId).subscribe(data => {
                Object.values(data).forEach(entitlement => {
                    if (entitlement === OrgEntitlementsEnum.SHARE_RESULTS) {
                        this.showEmailResultIcon = true;
                    }
                });
            });
        }
    }

    onMouseOver(marker: MapMarker, infoWindow: MapInfoWindow) {
        infoWindow.open(marker);
    }

    onMouseOut(infoWindow: MapInfoWindow) {
        infoWindow.close();
    }

    onMapReady(map) {
        this.map = map;
        this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(document.getElementById('mapCenter'));
    }

    centerMap() {
        this.map.setCenter(this.mapCenter);
        this.map.setZoom(this.mapZoom);
    }

    formatPhone(number: string): string {
        let formattedNumber = number.replace("+","").trim();
        if(formattedNumber.startsWith("1")) {
            return '1 (' + formattedNumber.substring(1, 4) + ') ' + formattedNumber.substring(4, 7) + '-' + formattedNumber.substring(7, 11);
        } else {
            return '(' + formattedNumber.substring(0, 3) + ') ' + formattedNumber.substring(3, 6) + '-' + formattedNumber.substring(6, 10);
        }
    }

    EmailStaticReport() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            searchResultId: String(this.brokerSearch.uniqueSearchId)
        };
        this.dialog.open(EmailReportComponent, dialogConfig);
    }
}
