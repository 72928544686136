import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Alerts} from '../../shared/enums/alerts';
import {HomeService} from '../../home/home.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {EmailReportComponent} from '../../email-report/email-report.component';
import {BrokerSearch} from '../../shared/models/brokerSearch';
import {PhoneTo} from '../../shared/models/phoneTo';
import { CommercialSearchDetailsDialog } from './details/commercial-details-dialog';
import { OrgEntitlementsEnum } from 'src/app/shared/enums/org-entitlements.enum';

import * as moment from 'moment';
import 'moment-timezone';
import { DatePipe } from '@angular/common';
import {MapInfoWindow, MapMarker} from "@angular/google-maps";

declare let google: any;
/**
 *   This component displays results for based on Commercial Search Providers (eg: Lexis-Nexis).   This includes
 *   PowerLocate, VehicleSearch, AddressSearch and PhoneSearch.
 */
@Component({
    selector: 'app-commercial-locate-results',
    templateUrl: './commercial-locate-results.component.html',
    styleUrls: ['./commercial-locate-results.component.scss']
})
export class CommercialLocateResultsComponent implements OnInit {

    @Input() brokerSearch: BrokerSearch;
    @Input() resultList: BrokerSearch[];
    selectedResult: any;
    mapCenter: any;
    mapZoom: number;
    mapType: string;
    map: any;

    scenario = Alerts.SEARCH_SCENARIO_APPROXIMATE;
    backgroundColor = 'alert alert-danger';
    _911CallerLat;
    _911CallerLng;
    distanceInFeet;
    distanceInMiles;
    showEmailResultIcon : boolean = false;

    constructor(private homeService: HomeService,
                private dialog: MatDialog,
                private datepipe: DatePipe) {
    }

    ngOnInit() {
        this.initialize();
        this.checkShareResultEntitlement();
    }

    initialize() {
        if (this.brokerSearch) {
            var lat = this.brokerSearch.searchRequest.location.latitude;
            var lng = this.brokerSearch.searchRequest.location.longitude;
            var zoom = 16;

            this.isGeocodedAddressPrimary();

            if (this.brokerSearch.searchResult.address && !this.brokerSearch.searchResult?.associatedAddress) {
                if (this.brokerSearch.searchResult.address.geocodedAddress &&
                    this.brokerSearch.searchResult.address.geocodedAddress.latitude !== 0.0) {
                    this._911CallerLat = this.brokerSearch.searchResult.address.geocodedAddress.latitude;
                    this._911CallerLng = this.brokerSearch.searchResult.address.geocodedAddress.longitude;
                    const point = new google.maps.LatLng(lat, lng);
                    const point2 = new google.maps.LatLng(this._911CallerLat, this._911CallerLng);
                    this.distanceInFeet = (google.maps.geometry.spherical.computeDistanceBetween(point, point2) * 3.28084).toFixed(0);
                    this.distanceInMiles = (this.distanceInFeet / 5280).toFixed(2);
                    const scale = google.maps.geometry.spherical.computeDistanceBetween(point, point2) / 500;
                    if (this.distanceInMiles < 1.0) {
                        zoom = (16 - Math.log(scale) / Math.log(2));
                    }
                }
            }

            if (this.brokerSearch?.searchResult?.associatedAddress) {
                const plAddress = this.brokerSearch.searchResult.associatedAddress;
                if (plAddress?.geocodedAddress?.latitude && plAddress.geocodedAddress.latitude !== 0.0) {
                    this._911CallerLat = plAddress.geocodedAddress.latitude;
                    this._911CallerLng = plAddress.geocodedAddress.longitude;
                    const point = new google.maps.LatLng(lat, lng);
                    const point2 = new google.maps.LatLng(this._911CallerLat, this._911CallerLng);
                    this.distanceInFeet = (google.maps.geometry.spherical.computeDistanceBetween(point, point2) * 3.28084).toFixed(0);
                    this.distanceInMiles = (this.distanceInFeet / 5280).toFixed(2);
                    const scale = google.maps.geometry.spherical.computeDistanceBetween(point, point2) / 500;
                    if (this.distanceInMiles < 1.0) {
                        zoom = (16 - Math.log(scale) / Math.log(2));
                    }
                }
            }

            this.mapZoom = zoom;
            this.mapType = 'roadmap';
            this.mapCenter = {lat: lat, lng: lng};
        }
    }

    onSearchChange(event: any){
        if (event.source.selected && this.brokerSearch.uniqueSearchId !== event.source.value.uniqueSearchId) {
            this.brokerSearch = event.source.value;
            this.initialize();
        }
    }

    onMapReady(map) {
        this.map = map;
        this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(document.getElementById('mapCenter'));
    }

    centerMap() {
        this.map.setCenter(this.mapCenter);
        this.map.setZoom(this.mapZoom);
    }

    onMouseOver(marker: MapMarker, infoWindow: MapInfoWindow) {
        infoWindow.open(marker);
    }

    onMouseOut(infoWindow: MapInfoWindow) {
        infoWindow.close();
    }

    getPhone(phone: PhoneTo) {
        if (phone) {
            return this.formatPhone(phone.number);
        } else {
            return ' ';
        }
    }

    formatDate(date: any, showTimezone: boolean): string {
        return this.datepipe.transform(new Date(date), 'MM/dd/yyyy hh:mm:ss a') + (showTimezone ? " " + moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).zoneAbbr() : "");
    }

    formatPhone(number: string): string {
        return '(' + number.substring(0, 3) + ') ' + number.substring(3, 6) + '-' + number.substring(6, 10);
    }

    getAddress(addr): string {
        if (addr) {
            var address = "";
            if (addr.hno){
                address = address + ' ' + addr.hno;
            }
            if (addr.hns){
                address = address + ' ' + addr.hns;
            }
            if (addr.prd){
                address = address + ' ' + addr.prd;
            }
            if (addr.rd){
                address = address + ' ' + addr.rd;
            }
            if (addr.sts){
                address = address + ' ' + addr.sts;
            }
            if (addr.pod){
                address = address + ' ' + addr.pod;
            }
            if (addr.unit){
                address = address + ', ' + addr.unit;
            }
            if (addr.loc){
                address = address + ' ' + addr.loc;
            }
            if (addr.a3){
                address = address + ', ' + addr.a3;
            }
            if (addr.a1){
                address = address + ', ' + addr.a1;
            }
            if (addr.pc){
                address = address + ' ' + addr.pc;
            }
            return address.trim();
        }
    }

    callerName(name) {
        return [name.firstName, name.middleName, name.lastName, name.nameSuffix]
            .filter((val) => val).join(' ');
    }

    isGeocodedAddressPrimary() {
        if (this.brokerSearch.searchResult) {
            if (this.brokerSearch.searchResult.phoneOwnerClosestAddress) {
                this.scenario = Alerts.SEARCH_SCENARIO_HOME_ADDRESS;
                this.backgroundColor = 'alert alert-success';
            } else if (this.brokerSearch.searchResult.associatedPerson) {
                this.scenario = Alerts.SEARCH_SCENARIO_ASSOCIATE_ADDRESS;
                this.backgroundColor = 'alert alert-success';
            }
        }
    }

    checkShareResultEntitlement() {
        this.showEmailResultIcon = false;
        if (this.homeService.userDetails && this.homeService.userDetails.org.clientId) {
            this.homeService.userDetails.org.entitlements.forEach( entitlement => {
                if (entitlement === OrgEntitlementsEnum.SHARE_RESULTS) {
                    this.showEmailResultIcon = true;
                }
            });
        }
    }

    EmailStaticReport() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            searchResultId: String(this.brokerSearch.uniqueSearchId)
        };
        this.dialog.open(EmailReportComponent, dialogConfig);
    }

    openDetails() {
        const dialogRef = this.dialog.open(
            CommercialSearchDetailsDialog, {
            data: {"brokerSearch": this.brokerSearch}
          });

    }
}
