export class DBHRecord {

    tn: string;
    source: string;
    locationType: string;
    location: any;
    locationTime: string;
    timestamp: string;
    dbhData: any;
    sequence : number;
    imei : string;

    constructor(object?: any) {	
        if (object) {
            this.imei = object.imei;
            this.tn = object.tn;
            this.source = object.source;
            this.locationType = object.locationType;
            this.location = object.location;
            this.locationTime = object.locationTime;
            this.timestamp = object.timestamp;
            this.dbhData = object.dbhData;
        }
    }

}