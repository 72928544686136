import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { EmailReportComponent } from 'src/app/email-report/email-report.component';
import { HomeService } from 'src/app/home/home.service';
import { OrganizationsService } from 'src/app/organizations/organizations.service';
import { OrgEntitlementsEnum } from 'src/app/shared/enums/org-entitlements.enum';
import { BrokerSearch } from 'src/app/shared/models/brokerSearch';

import * as moment from 'moment';
import 'moment-timezone';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { PowerSearchService } from '../power-search.service';
import { SharedService } from 'src/app/shared/shared.service';
import { Alerts } from 'src/app/shared/enums/alerts';
import { CdkDrag } from '@angular/cdk/drag-drop';

@Component({
    selector: 'app-mms-search-results',
    templateUrl: './mms-search-results.component.html',
    styleUrls: ['./mms-search-results.component.scss']
})
export class MmsSearchResultsComponent implements OnInit {

    @Input() brokerSearch: BrokerSearch;
    @Input() resultList: BrokerSearch[];
    fileList: Array<any> = [];
    additionDataEventSource: any;
    showEmailResultIcon : boolean = false;
    selectedFile: any;
    src: any;
    loading: boolean;
    
    @ViewChild(CdkDrag, { static: true }) cdkDrag: CdkDrag;
    fullscreen: boolean;
    rotation: number = 0;
    scale: number = 1;
    imageConfig: any = {
        zoomFactor: 0.15,
        wheelZoom: false
    };

    constructor(private powerSearchService : PowerSearchService,
                private homeService : HomeService,
                private activeRouter: ActivatedRoute,
                private orgService : OrganizationsService,
                private sharedService: SharedService,
                private datepipe: DatePipe,
                private dialog: MatDialog,
                private sanitizer: DomSanitizer) { }

    ngOnInit() {
        this.initialize();
        this.checkShareResultEntitlement();
    }

    ngOnDestroy() {
        this.additionDataEventSource?.close();
    }

    initialize() {
        if(this.brokerSearch.searchResult?.results) {
            this.fileList = this.brokerSearch.searchResult?.results;
        }
        this.fetchAdditionalData();
    }

    checkShareResultEntitlement() {
        this.showEmailResultIcon = false;
        if (this.homeService.userDetails && this.homeService.userDetails.org.clientId) {
            this.homeService.userDetails.org.entitlements.forEach( entitlement => {
                if (entitlement === OrgEntitlementsEnum.SHARE_RESULTS) {
                    this.showEmailResultIcon = true;
                }
            });
        } else if (this.activeRouter.snapshot.params['orgId']) {
            let clientId = this.activeRouter.snapshot.params['orgId']
            this.orgService.getServiceEntitlementsForOrg(clientId).subscribe(data => {
                Object.values(data).forEach(entitlement => {
                    if (entitlement === OrgEntitlementsEnum.SHARE_RESULTS) {
                        this.showEmailResultIcon = true;
                    }
                });
            });
        }
    }

    formatPhone(number: string): string {
        let formattedNumber = number.replace("+","").trim();
        if(formattedNumber.startsWith("1")) {
            return '1 (' + formattedNumber.substring(1, 4) + ') ' + formattedNumber.substring(4, 7) + '-' + formattedNumber.substring(7, 11);
        } else {
            return '(' + formattedNumber.substring(0, 3) + ') ' + formattedNumber.substring(3, 6) + '-' + formattedNumber.substring(6, 10);
        }
    }

    formatDate(date: any, showTimezone: boolean): string {
        return this.datepipe.transform(new Date(date), 'MM/dd/yyyy hh:mm:ss a') + (showTimezone ? " " + moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).zoneAbbr() : "");
    }

    formatFileSize(fileSize: number) {
        if (fileSize < 1000) {
            return fileSize + " Bytes";
        } else if (fileSize < 1000 * 1000) {
            fileSize = fileSize / 1000;
            return (Math.round(fileSize * 100) / 100).toFixed(2) + " KB";
        } else if (fileSize < 1000 * 1000 * 1000) {
            fileSize = fileSize / 1000 / 1000;
            return (Math.round(fileSize * 100) / 100).toFixed(2) + " MB";
        } else {
            fileSize = fileSize / 1000 / 1000 / 1000;
            return (Math.round(fileSize * 100) / 100).toFixed(2) + " GB";
        }
    }

    onSearchChange(event: any){
        if (event.source.selected && this.brokerSearch.uniqueSearchId !== event.source.value.uniqueSearchId) {
            this.brokerSearch = event.source.value;
            if(this.brokerSearch.searchResult?.results) {
                this.fileList = JSON.parse(JSON.stringify(this.brokerSearch.searchResult?.results));
            }
            this.fetchAdditionalData();
        }
    }

    fetchAdditionalData() {
        if (this.brokerSearch.additionalDataAvailable) {
            this.additionDataEventSource?.close();
            this.additionDataEventSource = this.powerSearchService.additionalDataSearch(this.brokerSearch.uniqueSearchId, -1, result => {
                let index = this.fileList.findIndex(file => file.id === result.id);
                if(index === -1) {
                    this.fileList.push(result);
                }
            });
        }
    }

    EmailStaticReport() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            searchResultId: String(this.brokerSearch.uniqueSearchId)
        };
        this.dialog.open(EmailReportComponent, dialogConfig);
    }

    bypassAndSanitize(url: string): SafeResourceUrl {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    openFile(file: any) {
        this.rotation = 0;
        this.scale = 1;
        this.loading = true;
        this.src = null;
        this.fetchFileData(file);
        this.selectedFile = file;
    }

    fetchFileData(file: any) {
        this.powerSearchService.fetchFileData(file.url).subscribe({
            next: blob => {
                this.src = this.bypassAndSanitize(URL.createObjectURL(blob));
                //console.log('DataURL:', this.src);
                this.loading = false;
            },
            error: error => {
                this.loading = false;
                this.sharedService.showAlert(Alerts.ERROR, "File not found");
            },
            complete: () => { }
        });
    }

    zoomIn() {
        this.scale *= (1 + this.imageConfig.zoomFactor);
        this.transformImage(false);
    }

    zoomOut() {
        if (this.scale > this.imageConfig.zoomFactor) {
            this.scale /= (1 + this.imageConfig.zoomFactor);
        }
        this.transformImage(false);
    }

    rotateLeft() {
        this.rotation -= 90; 
        this.transformImage(false);
    }

    rotateRight() {
        this.rotation += 90; 
        this.transformImage(false);
    }

    resetImage() {
        this.rotation = 0;
        this.scale = 1;
        this.transformImage(true);
    }

    scrollZoom(evt) {
        if (this.imageConfig.wheelZoom) {
            evt.deltaY > 0 ? this.zoomOut() : this.zoomIn();
            return false;
        } 
    }

    toggleFullscreen() {
        this.fullscreen = !this.fullscreen;
        if (!this.fullscreen) {
            this.resetImage();
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else {
                console.log('FullScreen Cancel Request Method Not Supported on this browser.');
            }
        } else {
            const element: any = document.getElementById("fileDiv");
            if (element.requestFullscreen) {
                element.requestFullscreen();
            } else if (element.mozRequestFullScreen) { /* Firefox */
                element.mozRequestFullScreen();
            } else if (element.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
                element.webkitRequestFullscreen();
            } else if (element.msRequestFullscreen) { /* IE/Edge */
                element.msRequestFullscreen();
            } else {
              console.log('FullScreen Request Method Not Supported on this browser.');
            }
        }
    }

    transformImage(isReset: boolean) {
        let fileElement = document.getElementById("file");
        if(isReset) {
            let blockElement = document.getElementById("fileBlock");
            blockElement.style.transform = '';
        } 
        fileElement.style.transform = `rotate(${this.rotation}deg) scale(${this.scale})`;
    }

}
