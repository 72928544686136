import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder,  UntypedFormGroup} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import {SharedService} from '../../shared/shared.service';
import {Alerts} from '../../shared/enums/alerts';
import {SearchTypesService } from '../search-type.service';
import { OrganizationsService } from 'src/app/organizations/organizations.service';
import { SearchTypeTo } from 'src/app/shared/models/searchTypeTo';
import { DisplayAttributeTo } from 'src/app/shared/models/displayAttributeTo';
import { SearchTypeBase } from '../search-type-base';
import { TemplateFileTo } from 'src/app/shared/models/templateFileTo';
import { TextBlocksService } from 'src/app/text-blocks/text-blocks.service';
import { TextblockFileTo } from 'src/app/shared/models/textblockFileTo';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
    selector: 'app-create-search-type',
    templateUrl: './create-search-type.component.html',
    styleUrls: ['./create-search-type.component.scss']
})
export class CreateSearchTypeComponent extends SearchTypeBase implements OnInit{
    options: UntypedFormGroup;
    loading: boolean = false;
    count = 1;

    constructor(private fb: UntypedFormBuilder, 
                public dialogRef: MatDialogRef<CreateSearchTypeComponent>,
                private searchTypesService: SearchTypesService, 
                protected sharedService: SharedService,
                private organizationService: OrganizationsService,
                private textBlocksService: TextBlocksService) {
        super(sharedService);
        this.options = this.fb.group({
            hideRequired: false,
            floatLabel: 'auto',
        });
    }

    ngOnInit() {
        this.organizationService.getAllEntitlementDef().subscribe(
            data => {
                data.forEach(entitlement => this.entitlementList.push(entitlement));
                this.entitlementList.sort((a, b) => a.displayName < b.displayName ? -1 : 1);
            }
        );
        this.getAllTextBlocks();
        this.searchTypeData = new SearchTypeTo();
        this.searchTypeData.responseSchemaFile = new TemplateFileTo({name:'ResponseSchema', sourceFilename:'', destinationFilename:'', sourceFile: null});
        this.searchTypeData.uiTemplateFile = new TemplateFileTo({name:'UITemplate', sourceFilename:'', destinationFilename:'', sourceFile: null});
        //Add default displayAttributes
        this.searchTypeData.displayAttributeList = [];
        this.searchTypeData.displayAttributeList.push(new DisplayAttributeTo({name:'telephonePath', value:''}));
        this.searchTypeData.displayAttributeList.push(new DisplayAttributeTo({name:'resultTitlePath', value:''}));
        this.searchTypeData.displayAttributeList.push(new DisplayAttributeTo({name:'resultTitleDetailsPath', value:''}));
        this.searchTypeData.displayAttributeList.push(new DisplayAttributeTo({name:'resultTimePath', value:''}));
        this.searchTypeData.displayAttributeList.push(new DisplayAttributeTo({name:'latitudePath', value:''}));
        this.searchTypeData.displayAttributeList.push(new DisplayAttributeTo({name:'longitudePath', value:''}));
        this.searchTypeData.displayAttributeList.push(new DisplayAttributeTo({name:'mapZoom', value:'19'}));
        this.searchTypeData.displayAttributeList.push(new DisplayAttributeTo({name:'mapType', value:'roadmap'}));
        //Add attribute path list
        this.searchTypeData.attributePathList = {}
        this.searchTypeData.attributePathList['imei'] = '';
        this.searchTypeData.attributePathList['telephone'] = '';
        this.searchTypeData.attributePathList['name'] = '';
        this.searchTypeData.attributePathList['latitude'] = '';
        this.searchTypeData.attributePathList['longitude'] = '';
        this.searchTypeData.attributePathList['altitude'] = '';
        this.searchTypeData.attributePathList['uncertainty'] = '';
        this.searchTypeData.attributePathList['confidencePdf'] = '';
        this.searchTypeData.attributePathList['confidenceValue'] = '';
        this.searchTypeData.attributePathList['civicAddress'] = '';
        this.searchTypeData.attributePathList['eventTime'] = '';
        this.searchTypeData.attributePathList['eventUpdateTime'] = '';
        this.searchTypeData.attributePathList['summary'] = '';
        this.searchTypeData.attributePathList['result'] = '';
        //Add default template
        this.searchTypeData.templateFileList = [];
        this.searchTypeData.templateFileList.push(new TemplateFileTo({name:'AuthToken', sourceFilename:'', destinationFilename:'', sourceFile: null}));
        this.searchTypeData.templateFileList.push(new TemplateFileTo({name:'AdrProviderInfo', sourceFilename:'', destinationFilename:'', sourceFile: null}));
        this.searchTypeData.templateFileList.push(new TemplateFileTo({name:'AdrServiceInfo', sourceFilename:'', destinationFilename:'', sourceFile: null}));
        this.searchTypeData.templateFileList.push(new TemplateFileTo({name:'AdrComment', sourceFilename:'', destinationFilename:'', sourceFile: null}));
        this.searchTypeData.templateFileList.push(new TemplateFileTo({name:'AdrDeviceInfo', sourceFilename:'', destinationFilename:'', sourceFile: null}));
        this.searchTypeData.templateFileList.push(new TemplateFileTo({name:'AdrSubscriberInfo', sourceFilename:'', destinationFilename:'', sourceFile: null}));
        //Add default textblocks
        this.searchTypeData.textblocksFileList = [];
        this.textblocksFileDataSource = new MatTableDataSource<TextblockFileTo>(this.searchTypeData.textblocksFileList);
    }

    verifyAndCreateSearchType() {
        if(!this.isValidData(true)) {
            return;
        }
        this.loading = true;
        this.createSearchtype();
    }

    getAllTextBlocks() {
        this.textBlocksService.getAllTextBlocks().subscribe({
            next: data => {
                this.textBlockList = data;
            },
            error: error => {
                this.dialogRef.close(false);
                this.sharedService.handleErrors(error, null, 'Unable to retrieve Text Blocks');
            },
            complete: () => { }
        });
    }

    createSearchtype() { 
        var fd = new FormData();
        this.searchTypeData.templateFileList.forEach(element => {
           element.destinationFilename = element.sourceFilename;
        });
        fd.append('SearchTypeTo', JSON.stringify(this.searchTypeData));
        if (this.searchTypeData.responseSchemaFile.sourceFile) {
            fd.append(this.searchTypeData.responseSchemaFile.name, 
                        this.searchTypeData.responseSchemaFile.sourceFile, 
                        this.searchTypeData.responseSchemaFile.destinationFilename);
        }
        if (this.searchTypeData.uiTemplateFile.sourceFile) {
            fd.append(this.searchTypeData.uiTemplateFile.name, 
                        this.searchTypeData.uiTemplateFile.sourceFile, 
                        this.searchTypeData.uiTemplateFile.destinationFilename);
        }
        this.searchTypeData.templateFileList.forEach(element => {
            if (element.sourceFile) {
                fd.append(element.name, element.sourceFile, element.destinationFilename);
            }
        });
        this.searchTypeData.textblocksFileList.forEach(element => {
            if (element.sourceFile && element.name && element.destinationFilename) {
                fd.append(element.name, element.sourceFile, element.destinationFilename);
            }
        });
        this.searchTypesService.createSearchType(fd).subscribe({
            next: data => {
                this.sharedService.showAlert(Alerts.SUCCESS, 'SearchType created successfully');
                this.loading = false;
                this.dialogRef.close(true);
            },
            error: error => {
                this.loading = false
                let msg;
                if (typeof (error.error) == 'string') {
                    msg = error.error;
                } else if (Object.keys(error.error).length > 0) {
                    msg = error.error[Object.keys(error.error)[0]];
                } else {
                    msg = '';
                }
                msg = typeof (error.error) == 'string' ? error.error : error.error[Object.keys(error.error)[0]];
                this.sharedService.handleErrors(error,
                    [{ "condition": error.status === 400, "msg": msg }],
                    "Unable to create searchType");
            },
            complete: () => { }
        });
    }

    resetSearchType() {
        if (this.fieldcheck) {
            Swal.fire({
                title: 'Are you sure?',
                text: 'Reset the values?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes, Reset',
                cancelButtonText: 'No, Cancel',
            }).then((result) => {
                if (result.value) {
                    this.searchTypeData = new SearchTypeTo();
                    this.fieldcheck = false;
                }
            });
        } else {
            this.searchTypeData = new SearchTypeTo();
        }
    }

    change(event) {
        if (event.srcElement.value !== '') {
            this.count = this.count + 1;
        } else if (event.srcElement.value === '') {
            this.count = this.count - 1;
        }
        if (this.count <= 1 || !this.fieldcheck) {
            this.fieldcheck = true;
        }
    }


    close() {
        if (this.fieldcheck) {
            Swal.fire({
                title: 'Are you sure?',
                text: 'Close the create SearchType dialog box?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes, Close',
                cancelButtonText: 'No, Cancel',
            }).then((result) => {
                if (result.value) {
                    this.dialogRef.close(false);
                }
            });
        } else {
            this.dialogRef.close(false);
        }

    }

}
