import {Component, OnInit} from '@angular/core';
import { UntypedFormBuilder} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import { LoginService } from '../login/login.service';
import { SharedService } from '../shared/shared.service';



@Component({
    selector: 'app-short-web-link',
    templateUrl: './short-web-link.component.html',
    styleUrls: ['./short-web-link.component.scss']
})

export class ShortWebLinkComponent implements OnInit {
    loading: Boolean = false;
    authenticated:Boolean = false;

    constructor(public router: Router,
                public loginService: LoginService,
                public activeRouter: ActivatedRoute,
                public sharedService: SharedService,
                public formBuilder: UntypedFormBuilder) {
    }

    ngOnInit() {
        let jwtToken: string;
        let refreshToken: string;
        let fragment: string = this.activeRouter.snapshot.fragment;
        if(fragment) {
            let params: Map<string, string> = new Map<string, string>();
            if(fragment.includes("?")) {
                fragment.substring(fragment.indexOf("?")+1).split("&").forEach(param => {
                    params.set(param.split("=")[0], param.split("=")[1])
                });
                fragment = fragment.substring(0, fragment.indexOf("?"));
                jwtToken = params.get("token");
            }
        } 
        if(this.activeRouter.snapshot.queryParamMap?.has("token")) {
            jwtToken = this.activeRouter.snapshot.queryParamMap.get("token");
        }

        if(this.activeRouter.snapshot.queryParamMap?.has("refresh-token")) {
            refreshToken = this.activeRouter.snapshot.queryParamMap.get("refresh-token");
        }
        if (refreshToken != null) {
            localStorage.setItem(this.sharedService.REFRESH_TOKEN, refreshToken);
        }

        let redirectUrl: string = location.pathname;
        if(fragment) {
            redirectUrl += "#"+fragment;
        }
        this.sharedService.setLoginRedirectUri(encodeURIComponent(redirectUrl));

        //Check if token is present in Url(redirect from login)
        if (jwtToken) {
            this.authenticated = true;           
            localStorage.setItem(this.sharedService.ID_TOKEN, jwtToken);
            this.router.navigate([], {
                queryParams: {
                    'token': null,
                    'refresh-token': null
                },
                fragment: fragment,
                queryParamsHandling: 'merge'
            });
        } else {
            this.loading = false;
            //verify if already loggedin
            var token = localStorage.getItem(this.sharedService.ID_TOKEN);
            if(token && token!='null') {
                this.authenticated = true;                           
            } else {                            
                this.router.navigate(['session/login']);
            }
        }

        
    }
}
