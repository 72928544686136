import {SelectionModel} from '@angular/cdk/collections';
import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {CreateProviderComponent} from '../create-provider/create-provider.component';
import {UpdateProviderComponent} from '../update-provider/update-provider.component';
import {ProviderTo} from '../../shared/models/providerTo';
import {SharedService} from '../../shared/shared.service';
import {Subscription} from 'rxjs';
import { ProvidersService } from '../provider.service';
import { SearchTypesService } from 'src/app/search-types/search-type.service';
import { SearchTypeTo } from 'src/app/shared/models/searchTypeTo';
import Swal from 'sweetalert2/dist/sweetalert2.js';
let that;

@Component({
    selector: 'app-list-providers',
    templateUrl: './list-providers.component.html',
    styleUrls: ['./list-providers.component.scss']
})

export class ListProvidersComponent implements OnInit {
    isLoading = true;
    isDeletingRecord = false;
    displayFailureMsg = false;
    dialogRef;
    All = 'ALL';
    searchTypeList: SearchTypeTo[] = [];
    providerList: ProviderTo[] = [];
    allProviders: ProviderTo[] = [];
    supplementalDataProviders: ProviderTo[] = [];
    displayedColumns: string[] = ['name', 'type', 'searchTye', 'enabled', 'supplementalData', 'options'];
    dataSource = new MatTableDataSource<ProviderTo>(this.providerList);
    selection = new SelectionModel<ProviderTo>(true, []);
    selectedSearchType =  'ALL';
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    paginatorLength: number;
    private providerServiceSubscription: Subscription;

    constructor(private dialog: MatDialog, 
                private providersService: ProvidersService, 
                private searchTypesService: SearchTypesService,
                private sharedService: SharedService, 
                private cdref: ChangeDetectorRef) {
        that = this;
        this.providerServiceSubscription = this.providersService.updateProviderList$.subscribe(
            data => {
                this.getAllProviders();
            }
        );
    }

    ngOnInit() {
        this.getAllProviders();
        this.getAllSearchTypes();
    }

    ngAfterContentChecked() {
         this.cdref.detectChanges();
    }

    createProvider() {
        this.dialogRef = this.dialog.open(CreateProviderComponent, {
            data: {
                supplementalDataProviders : this.supplementalDataProviders,
                selectedSearchTypeName: this.selectedSearchType
            }
        });
        this.dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.getAllProviders();
            }
        });
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    editProvider(object) {
        this.dialogRef = this.dialog.open(UpdateProviderComponent, {
            data: {
                supplementalDataProviders : this.supplementalDataProviders,
                selectedProvider: object
            }
        });
        this.dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.getAllProviders();
            }
        });
    }

    ngOnDestroy() {
        this.providerServiceSubscription.unsubscribe();
    }


    getAllProviders() {
        this.providersService.getAllProviders().subscribe({
            next: data => {
                this.allProviders = data;
                this.supplementalDataProviders = [];
                data.forEach(provider => {
                    if (provider.supplementalData && provider.enabled) {
                        this.supplementalDataProviders.push(provider);
                    }
                });
                this.displayFailureMsg = false;
                this.getProviderListData(this.selectedSearchType);
            },
            error: error => {
                this.displayFailureMsg = true;
                this.isLoading = false;
            },
            complete: () => { }
        });
    }

    formProviderListData(data, searchTypeName) {
        this.providerList = data.filter(eachProvider => {
            if (eachProvider.searchTypeName === searchTypeName) {
                return eachProvider;
            }
        });
        if (this.providerList.length > 20) {
            this.paginatorLength = this.providerList.length;
        } else {
            this.paginatorLength = 20;
        }
        this.getAllSearchTypes();
    }

    setProviderListData() {
        const currentfilter = this.dataSource.filter;
        this.dataSource = new MatTableDataSource<ProviderTo>(this.providerList);
        this.dataSource.filter = currentfilter;
        this.dataSource.filterPredicate = (data: ProviderTo, filter: string) =>
            (data.name && data.name.trim().toLowerCase().indexOf(filter) !== -1) ||
            (data.type && data.type.trim().toLowerCase().indexOf(filter) !== -1) ||
            (data.searchTypeName && data.searchTypeName.trim().toLowerCase().indexOf(filter) !== -1) ||
            (data.endpointUrl && data.endpointUrl.trim().toLowerCase().indexOf(filter) !== -1) || 
            (data.httpMethod && data.httpMethod.trim().toLowerCase().indexOf(filter) !== -1) ||
            (data.additionalDataEndpointUrl && data.additionalDataEndpointUrl.trim().toLowerCase().indexOf(filter) !== -1) || 
            (data.additionalDataHttpMethod && data.additionalDataHttpMethod.trim().toLowerCase().indexOf(filter) !== -1) ||
            (data.authType && data.authType.trim().toLowerCase().indexOf(filter) !== -1) || 
            data.enabled.toString().trim().toLowerCase().indexOf(filter) !== -1 ||
            data.supplementalData.toString().trim().toLowerCase().indexOf(filter) !== -1 ;
        setTimeout(() => this.dataSource.paginator = this.paginator);
        this.dataSource.sortingDataAccessor = (data: ProviderTo, sortHeaderId: string): string => {
            if (typeof data[sortHeaderId] === 'string') {
                return data[sortHeaderId].toLocaleLowerCase();
            }

            return data[sortHeaderId];
        };
        this.dataSource.sort = this.sort;
        this.isLoading = false;
    }

    getAllSearchTypes() {
        this.searchTypesService.getAllSearchTypes().subscribe({
            next: data => {
                this.searchTypeList = data;
                this.searchTypeList.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1);
                this.isLoading = false;
                this.displayFailureMsg = false;
            },
            error: error => {
                this.displayFailureMsg = true;
                this.isLoading = true;
            },
            complete: () => { }
        });
    }

    deleteProvider(object:any) {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Delete the Provider "' + object.name + '"',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Yes, Delete',
            cancelButtonText: 'No, cancel',
            showLoaderOnConfirm: true
        }).then((result:any) => {
            if (result.value) {
                this.isDeletingRecord = true;
                this.providersService.deleteProvider(object.name).subscribe({
                    next: data => {
                        this.isDeletingRecord = false;
                        this.sharedService.showAlert('success', 'Provider ' + object.name + ' has been deleted.');
                        this.providersService.updateProviderList.next();
                    },
                    error: error => {
                        this.isDeletingRecord = false;
                        if (error.error) {
                            this.sharedService.showAlert('error', error.error);
                        } else {
                            this.sharedService.showAlert('error', 'Provider ' + object.name + ' has not been deleted.');
                        }
                        this.providersService.updateProviderList.next();
                    },
                    complete: () => {}
                 });
            }
        });
    }


    getProviderListData(searchTypeName) {
        if (searchTypeName === this.All) {
            this.providerList = this.allProviders;
            if (this.providerList.length > 20) {
                this.paginatorLength = this.providerList.length;
            } else {
                this.paginatorLength = 20;
            }
        } else {
            this.formProviderListData(this.allProviders, searchTypeName);
        }
        this.setProviderListData();
    }

    syncPrimaryPaginator(event: PageEvent) {
        this.paginator.pageIndex = event.pageIndex;
        this.paginator.pageSize = event.pageSize;
        this.paginator.page.emit(event);
    }

}
