import {UntypedFormControl, Validators} from "@angular/forms";
import {Patterns} from "../shared/enums/patterns";
import {HttpRequestEnum} from "../shared/enums/HttpRequestEnum";
import {AuthType} from "../shared/enums/authType";
import {SearchTypeTo} from "../shared/models/searchTypeTo";
import {ProviderType} from "../shared/enums/provider-type.enum";
import { ProviderTo } from "../shared/models/providerTo";
import { Alerts } from "../shared/enums/alerts";
import { SharedService } from "../shared/shared.service";
import { RequestHeaderTo } from "../shared/models/requestHeaderTo";
import { SupplementalDataProviderTo } from "../shared/models/supplementalDataProviderTo";
import { MatTableDataSource } from "@angular/material/table";
import { SupplementalDataProviderConfigTo } from "../shared/models/supplementalDataProviderConfigTo";

/**
 * Common base class for create and update provider components.
 */
export abstract class ProviderBase {
    type = new UntypedFormControl('', [Validators.required]);
    name = new UntypedFormControl('', [Validators.minLength(2), Validators.maxLength(20)]);
    searchType = new UntypedFormControl('', [Validators.required]);
    dataFoundEndpointUrl = new UntypedFormControl('', [Validators.pattern(Patterns.URL)]);
    endpointUrl = new UntypedFormControl('', [Validators.pattern(Patterns.URL)]);
    httpMethod = new UntypedFormControl('');
    authType = new UntypedFormControl('');
    username = new UntypedFormControl('');
    password = new UntypedFormControl('');
    addDataEndpointUrl = new UntypedFormControl('', [Validators.pattern(Patterns.URL)]);
    requestTemplate = new UntypedFormControl('', [Validators.required]);
    oauth2TokenEndpointUrl = new UntypedFormControl('', [Validators.pattern(Patterns.URL)]);
    clientIdPref =  new UntypedFormControl('');
    clientSecretPref = new UntypedFormControl('');
    clientId =  new UntypedFormControl('', [Validators.required]);
    clientSecret = new UntypedFormControl('', [Validators.required]);
    audience =  new UntypedFormControl('', [Validators.required]);

    httpMethodList: string[] = [HttpRequestEnum.GET, HttpRequestEnum.POST];
    authTypeList: string[] = [AuthType.NONE, AuthType.BASIC_AUTH, AuthType.JWT, AuthType.OAUTH2];
    searchTypeList: SearchTypeTo[] = [];
    types : string[] = [ProviderType.PULL, ProviderType.PUSH];
    count = 1;
    provider: ProviderTo;

    supplementalProviders: ProviderTo[] = [];
    selectedSupplementalProvider: ProviderTo;
    selectedSupplementalDataProvider: SupplementalDataProviderTo;
    showSupplementalProviderConfig: boolean = false;
    supplementalProviderDisplayedColumns: string[] = ['name', 'options'];
    supplementalDataProvidersDataSource;


    fieldcheck: boolean = false;
    tab1Error: boolean = false;
    tab2Error: boolean = false;
    tab3Error: boolean = false;
    tab4Error: boolean = false;
    
    readonly NAME = 'name';
    readonly SEARCHTYPE = 'searchType';
    readonly TYPE = 'TYPE';
    readonly ENDPOINTURL = 'endpointUrl';
    readonly DATAFOUNDENDPOINTURL = 'dataFoundEndpointUrl';
    readonly HTTPMETHOD = 'httpMethod';
    readonly AUTHTYPE = 'authType';
    readonly USERNAME = 'username';
    readonly PASSWORD = 'password';
    readonly BASIC_AUTH = AuthType.BASIC_AUTH;
    readonly ADDDATAENDPOINTURL = 'AddDataEndpointUrl';
    readonly REQUESTTEMPLATE = 'requestTemplate';
    readonly OAUTH2TOKENENDPOINTURL = 'oauth2TokenEndpointUrl';
    readonly CLIENTIDPREF = 'clientIdPref';
    readonly CLIENTSECRETPREF = 'clientSecretPref';
    readonly CLIENTID = 'clientId';
    readonly CLIENTSECRET = 'clientSecret';
    readonly AUDIENCE = 'audience';
    readonly OAUTH2 = AuthType.OAUTH2;
    
    constructor(protected sharedService: SharedService) {
    };

    getErrorMessage(formControl) {
        if (formControl === this.NAME) {
            return this.name.hasError('required') ? 'You must enter a value' :  'Not a valid name';
        } else if (formControl === this.TYPE) {
            return this.type.hasError('required') ? 'You must select a type' :  '';
        } else if (formControl === this.SEARCHTYPE) {
            return this.searchType.hasError('required') ? 'You must select a SearchType ' :  '';
        } else if (formControl === this.DATAFOUNDENDPOINTURL) {
            return this.dataFoundEndpointUrl.hasError('required') ? 'You must enter a value' : 'Enter a valid URL';
        } else if (formControl === this.ENDPOINTURL) {
            return this.endpointUrl.hasError('required') ? 'You must enter a value' : 'Enter a valid URL';
        } else if (formControl === this.HTTPMETHOD) {
            return this.httpMethod.hasError('required') ? 'You must select a HTTP Method' :  '';
        } else if (formControl === this.AUTHTYPE) {
            return this.authType.hasError('required') ? 'You must select a type' :  '';
        } else if (formControl === this.USERNAME) {
            return this.username.hasError('required') ? 'You must enter a value' :  '';
        } else if (formControl === this.PASSWORD) {
            return this.password.hasError('required') ? 'You must enter a value' :  '';
        } else if (formControl === this.ADDDATAENDPOINTURL) {
            return this.addDataEndpointUrl.hasError('required') ? 'You must enter a value' : 'Enter a valid URL';
        } else if (formControl === this.REQUESTTEMPLATE) {
            return this.requestTemplate.hasError('required') ? 'You must enter a value ' :  '';
        } else if (formControl === this.OAUTH2TOKENENDPOINTURL) {
            return this.oauth2TokenEndpointUrl.hasError('required') ? 'You must enter a value' : 'Enter a valid URL';
        } else if (formControl === this.CLIENTIDPREF) {
            return this.clientIdPref.hasError('required') ? 'You must select a client id preference' :  '';
        } else if (formControl === this.CLIENTSECRETPREF) {
            return this.clientSecretPref.hasError('required') ? 'You must select a client secret preference' :  '';
        } else if (formControl === this.CLIENTID) {
            return this.clientId.hasError('required') ? 'You must enter a value' :  'Not a valid client id';
        } else if (formControl === this.CLIENTSECRET) {
            return this.clientSecret.hasError('required') ? 'You must enter a value' :  'Not a valid client secret';
        } else if (formControl === this.AUDIENCE) {
            return this.audience.hasError('required') ? 'You must enter a value' :  'Not a valid audience';
        }
    }

    markFormControlsAsTouched() {
        this.name.markAsTouched();
        this.type.markAsTouched();
        this.searchType.markAsTouched();
        if(this.provider.sensitiveData) {
            this.dataFoundEndpointUrl.markAsTouched();
        }
        this.endpointUrl.markAsTouched();
        this.httpMethod.markAsTouched();
        this.authType.markAsTouched();
        this.addDataEndpointUrl.markAsTouched();
        this.requestTemplate.markAsTouched();
        this.username.markAsTouched();
        this.password.markAsTouched();
        this.oauth2TokenEndpointUrl.markAsTouched();
        this.clientIdPref.markAsTouched();
        this.clientSecretPref.markAsTouched();
        this.clientId.markAsTouched();
        this.clientSecret.markAsTouched();
        this.audience.markAsTouched();
    }

    isValidData(provider: ProviderTo, isCreateAction: boolean): boolean {
        this.markFormControlsAsTouched();
        this.tab1Error = this.name.invalid || this.type.invalid || this.searchType.invalid || (this.provider.sensitiveData && this.dataFoundEndpointUrl.invalid) || 
                         this.endpointUrl.invalid || this.httpMethod.invalid || this.addDataEndpointUrl.invalid || this.authType.invalid || 
                         (this.authType.value === AuthType.BASIC_AUTH && (this.username.invalid || this.password.invalid)) ||
                         (this.authType.value === AuthType.OAUTH2 && (this.oauth2TokenEndpointUrl.invalid || 
                            (this.provider.credentialsPerPSAP && (this.clientIdPref.invalid || this.clientSecretPref.invalid)) ||
                            (!this.provider.credentialsPerPSAP && (this.clientId.invalid || this.clientSecret.invalid || this.audience.invalid))));
        
        for(let header of provider.oauth2TokenHeaders) {
            if(!header.name || !header.value || header.name.trim().length === 0 || header.value.trim().length === 0) {
                this.tab1Error = true;
                break;
            }
        }
        this.tab2Error = this.requestTemplate.invalid;

        try {
            if (provider.requestTemplate) {
                JSON.parse(provider.requestTemplate);
            }
            if (provider.additionalDataRequestTemplate) {
                JSON.parse(provider.additionalDataRequestTemplate);
            }
        } catch(e) {
            this.sharedService.showAlert(Alerts.ERROR, 'Please enter valid request template');
            this.tab2Error = true;
            return false;
        }

        this.tab3Error = false;
        for(let header of provider.requestHeaders) {
            if(!header.name || !header.value || header.name.trim().length === 0 || header.value.trim().length === 0) {
                this.tab3Error = true;
                break;
            }
        }

        this.tab4Error = this.isSupplementalDataProvidersInvalid();

        if(this.tab1Error || this.tab2Error || this.tab3Error || this.tab4Error) {
            if(isCreateAction) {
                this.sharedService.showAlert(Alerts.ERROR, 'Please provide the correct details to create provider');
            } else {
                this.sharedService.showAlert(Alerts.ERROR, 'Please provide the correct details to update provider');
            }
            this.markFormControlsAsTouched();
            return false;
        }
        return true;
    }

    isSupplementalDataProvidersInvalid(): boolean {
        let isInvalid: boolean = false;
        this.provider.supplementalDataProviders.forEach( (supplementalDataProvider) => {
            if (!supplementalDataProvider.supplementalDataProviderConfigs || supplementalDataProvider.supplementalDataProviderConfigs?.length === 0) {
                isInvalid = true;
            }
            supplementalDataProvider.supplementalDataProviderConfigs.forEach(config => {
                if(!config.requestTemplate || !config.destinationPath || (config.inputSourceArray && !config.sourcePath)) {
                    isInvalid = true;
                }
            }); 
        });
        return isInvalid;
    }

    addHeader() {
        this.provider.requestHeaders.push(
            new RequestHeaderTo({
                name: "",
                value: ""
            })
        );
    }

    removeHeader(index: number) {
        if (index > -1) {
            this.provider.requestHeaders.splice(index, 1);
        }
    }

    addTokenHeader() {
        this.provider.oauth2TokenHeaders.push(
            new RequestHeaderTo({
                name: "",
                value: ""
            })
        );
    }

    removeTokenHeader(index: number) {
        if (index > -1) {
            this.provider.oauth2TokenHeaders.splice(index, 1);
        }
    }

    checkAuthType() {
        if(this.provider.authType === AuthType.BASIC_AUTH) {
            this.provider.clientIdPref = undefined;
            this.provider.clientSecretPref = undefined;
            this.provider.oauth2TokenEndpointUrl = undefined;
            this.provider.clientId = undefined;
            this.provider.clientSecret = undefined;
            this.provider.audience = undefined;
            this.provider.oauth2TokenHeaders = undefined;
        } else if(this.provider.authType === AuthType.OAUTH2) {
            this.provider.username = undefined;
            this.provider.password = undefined;
            if(this.provider.credentialsPerPSAP) {
                this.provider.clientId = undefined;
                this.provider.clientSecret = undefined;
                this.provider.audience = undefined;
                this.provider.oauth2TokenHeaders = [];
            } else {
                this.provider.clientIdPref = undefined;
                this.provider.clientSecretPref = undefined;
            }
        } else {
            this.provider.clientIdPref = undefined;
            this.provider.clientSecretPref = undefined;
            this.provider.oauth2TokenEndpointUrl = undefined;
            this.provider.username = undefined;
            this.provider.password = undefined;
            this.provider.clientId = undefined;
            this.provider.clientSecret = undefined;
            this.provider.audience = undefined;
            this.provider.oauth2TokenHeaders = [];
        }
    }

    addSupplementalDataProvider() {
        if(this.selectedSupplementalProvider) {
            let index = this.provider.supplementalDataProviders.findIndex(s => s.name === this.selectedSupplementalProvider.name);
            if(index === -1) {
                this.provider.supplementalDataProviders.push(
                    new SupplementalDataProviderTo({
                        name: this.selectedSupplementalProvider.name,
                        supplementalDataProviderConfigs: []
                    })
                );
                this.supplementalDataProvidersDataSource = new MatTableDataSource<SupplementalDataProviderTo>(this.provider.supplementalDataProviders);
                this.selectedSupplementalProvider = null;
            } else {
                this.sharedService.showAlert(Alerts.ERROR, "'"+this.selectedSupplementalProvider.name+"' is already added");
            }
        }
    }

    deleteSupplementalDataProvider(supplementalDataProvider: SupplementalDataProviderTo) {
        const index = this.provider.supplementalDataProviders.findIndex(s => s.name === supplementalDataProvider.name);
        if (index > -1) {
            this.provider.supplementalDataProviders.splice(index, 1);
        }
        this.supplementalDataProvidersDataSource = new MatTableDataSource<SupplementalDataProviderTo>(this.provider.supplementalDataProviders);
    }

    configureSupplementalDataProvider(supplementalDataProvider: SupplementalDataProviderTo) {
        this.selectedSupplementalDataProvider = supplementalDataProvider;
        if(this.selectedSupplementalDataProvider.supplementalDataProviderConfigs.length === 0) {
            this.addSupplementalProviderConfig();
        }
        this.showSupplementalProviderConfig = true;
    }

    back() {
        this.showSupplementalProviderConfig = false;
        this.selectedSupplementalDataProvider = null;
    }

    addSupplementalProviderConfig() {
        let requestTemplate: string;
        this.supplementalProviders.forEach(provider => {
            if(provider.name === this.selectedSupplementalDataProvider.name) {
                requestTemplate = provider.requestTemplate;
            }
        });
        this.selectedSupplementalDataProvider.supplementalDataProviderConfigs.push(
            new SupplementalDataProviderConfigTo({
                requestTemplate: requestTemplate
            })
        );
    }

    deleteSupplementalProviderConfig(index: number) {
        this.selectedSupplementalDataProvider.supplementalDataProviderConfigs.splice(index, 1);
    }
    
}
