import {SelectionModel} from '@angular/cdk/collections';
import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {SearchTypeTo} from '../../shared/models/searchTypeTo';
import {CreateSearchTypeComponent} from '../create-search-type/create-search-type.component';
import {UpdateSearchTypeComponent} from '../update-search-type/update-search-type.component';
import {SharedService} from '../../shared/shared.service';
import { SearchTypesService } from '../search-type.service';
import { ProvidersService } from 'src/app/providers/provider.service';
import { Alerts } from 'src/app/shared/enums/alerts';
import { OrganizationsService } from 'src/app/organizations/organizations.service';

import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
    selector: 'app-list-search-types',
    templateUrl: './list-search-types.component.html',
    styleUrls: ['./list-search-types.component.scss']
})
export class ListSearchTypesComponent implements OnInit {

    dialogRef;
    searchTypeList: SearchTypeTo[] = [];
    displayedColumns: string[] = ['name', 'entitlement', 'locationUsage', 'telephoneUsage', 'addressUsage', 'incidentUsage', 'enabled', 'options'];
    dataSource = new MatTableDataSource<SearchTypeTo>(this.searchTypeList);
    selection = new SelectionModel<SearchTypeTo>(true, []);
    isLoading = true;
    isDeletingRecord = false;
    displayFailureMsg = false;
    errorMsg: Map<string, string>;
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    paginatorLength: number;
    providerList: any;
    entitlementList: any[] = [];

    constructor(private dialog: MatDialog, 
                protected sharedService: SharedService, 
                private cdref: ChangeDetectorRef,
                private searchTypesService: SearchTypesService,
                private providersService: ProvidersService,
                private organizationService: OrganizationsService) {
        this.searchTypesService.updateSearchTypeList$.subscribe(
            data => {
                this.loadSearchTypes();
            });
    }

    ngOnInit() {
        this.organizationService.getAllEntitlementDef().subscribe(
            data => {
                data.forEach(entitlement => this.entitlementList.push(entitlement));
                this.loadSearchTypes();
            }
        );
    }

    loadSearchTypes() {
        this.getAllSearchTypes(); 
    }

    getAllSearchTypes() {
        this.searchTypesService.getAllSearchTypes().subscribe({
            next: data => {
                this.setSearchTypeList(data);
            }, 
            error: e => {
                this.displayFailureMsg = true;
                this.isLoading = false;
            },
            complete: () => {}
        });
    }

    showEntitlementDisplayName(name: string) {
        let entitlement = this.entitlementList.find(e => e.name === name);
        return entitlement.displayName;
    }

    ngAfterContentChecked() {
        this.cdref.detectChanges();
    }

     setSearchTypeList(searchTypes: SearchTypeTo[]) {
        this.searchTypeList = searchTypes;
        if (this.searchTypeList.length > 20) {
            this.paginatorLength = this.searchTypeList.length;
        } else {
            this.paginatorLength = 20;
        }
        const currentFilter = this.dataSource.filter;
        this.dataSource = new MatTableDataSource<SearchTypeTo>(this.searchTypeList);
        this.dataSource.filter = currentFilter;
        this.dataSource.filterPredicate = (data: SearchTypeTo, filter: string) =>
            (data.name && data.name.trim().toLowerCase().indexOf(filter) !== -1) || 
            (data.entitlement && this.showEntitlementDisplayName(data.entitlement).trim().toLowerCase().indexOf(filter) !== -1) || 
            (data.locationUsage && data.locationUsage.toString().trim().toLowerCase().indexOf(filter) !== -1) ||
            (data.telephoneUsage && data.telephoneUsage.toString().trim().toLowerCase().indexOf(filter) !== -1) ||
            (data.addressUsage && data.addressUsage.toString().trim().toLowerCase().indexOf(filter) !== -1) ||
            data.enabled.toString().trim().toLowerCase().indexOf(filter) !== -1;
        setTimeout(() => this.dataSource.paginator = this.paginator);
        this.dataSource.sortingDataAccessor = (data: SearchTypeTo, sortHeaderId: string): string => {
            if (typeof data[sortHeaderId] === 'string') {
                return data[sortHeaderId].toLocaleLowerCase();
            }
            return data[sortHeaderId];
        };
        this.dataSource.sort = this.sort;
        this.isLoading = false;
        this.displayFailureMsg = false;
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    createSearchType() {
        this.dialogRef = this.dialog.open(CreateSearchTypeComponent);
        this.dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.loadSearchTypes();
            }
        });
    }

    editSearchType(object) {
        this.dialogRef = this.dialog.open(UpdateSearchTypeComponent, {
            data: object
        });
        this.dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.loadSearchTypes();
            }
        });
    }

    deleteSearchType(object: any) {
        this.providersService.getProviders(object.name).subscribe({
            next: data => {
                this.providerList = data;
                if (this.providerList.length > 0) {
                    this.sharedService.showAlert(Alerts.ERROR, 'Please delete the Providers associated to that Searchtype');
                } else {
                    Swal.fire({
                        title: 'Are you sure?',
                        text: 'Delete the SearchType "' + object.name + '"',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#DD6B55',
                        confirmButtonText: 'Yes, Delete',
                        cancelButtonText: 'No, cancel',
                        showLoaderOnConfirm: true
                    }).then((result: any) => {
                        if (result.value) {
                            this.isDeletingRecord = true;
                            this.searchTypesService.deleteSearchType(object.name).subscribe({
                                next: data => {
                                    this.isDeletingRecord = false;
                                    this.sharedService.showAlert('success', 'SearchType ' + object.name + ' has been deleted.');
                                    this.searchTypesService.updateSearchTypeList.next();
                                },
                                error: error => {
                                    this.isDeletingRecord = false;
                                    if (error.error) {
                                        this.sharedService.showAlert('error', error.error);
                                    } else {
                                        this.sharedService.showAlert('error', 'SearchType ' + object.name + ' has not been deleted.');
                                    }
                                    this.searchTypesService.updateSearchTypeList.next();
                                },
                                complete: () => { }
                            });
                        }
                    });
                }
            },
            error: error => {
                this.sharedService.handleErrors(error,
                    [{
                        "condition": (error.status === 400 && Object.keys(error.error).length > 0),
                        "msg": error.error[Object.keys(error.error)[0]]
                    }],
                    "Unable to update Provider");
            },
            complete: () => { }
        });
    }

    syncPrimaryPaginator(event: PageEvent) {
        this.paginator.pageIndex = event.pageIndex;
        this.paginator.pageSize = event.pageSize;
        this.paginator.page.emit(event);
    }
}
