import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigService } from 'src/assets/config/config-svc.service';
import { EntitlementTo } from '../shared/models/entitlementTo';
import { PreferenceTo } from '../shared/models/preferenceTo';
import { SharedService } from '../shared/shared.service';


@Injectable({
    providedIn: 'root'
})
export class EntitlementsService {

    updateEntitlementList = new Subject<void>();
    updateEntitlementList$ = this.updateEntitlementList.asObservable();

    constructor(public http: HttpClient, public sharedService: SharedService) {
    }

    getHeaders() {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'transactionId': this.sharedService.generateUUID(),
                'reportType': 'All'
            }),
            withCredentials: true
        };
        return httpOptions;
    }

    public getAllEntitlementDef() {
        return this.http.get<EntitlementTo[]>(ConfigService.config.userServiceUrl + '/entitlementDefs',
            this.getHeaders()).pipe(map((res => (res))));
    }

    createEntitlement(payload) {
        return this.http.post(ConfigService.config.userServiceUrl + '/entitlement/create', payload, this.getHeaders()).pipe(map((res: any) => res));
    }

    updateEntitlement(payload) {
        return this.http.put(ConfigService.config.userServiceUrl + '/entitlement/update', payload, this.getHeaders()).pipe(map((res: any) => res));
    }

    deleteEntitlement(name) {
        return this.http.delete(ConfigService.config.userServiceUrl + '/entitlement/' + name, this.getHeaders()).pipe(map((res: any) => res));
    }

    getAllPreferences() {
        return this.http.get<PreferenceTo[]>(ConfigService.config.userServiceUrl + '/preferences',
            this.getHeaders()).pipe(map((res => (res))));
    }
}
