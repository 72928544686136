import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {APP_INITIALIZER, NgModule, ErrorHandler, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {DatePipe, TitleCasePipe} from '@angular/common';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { DateTimeModule } from 'nxt-pick-datetime';
import { NativeDateTimeModule } from 'nxt-pick-datetime/native-adapter';
import { MomentDateTimeModule } from 'nxt-pick-datetime/moment-adapter';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {HomeComponent} from './home/home.component';
import {HelpClass, NavigationComponent} from './navigation/navigation.component';
import {GoogleMapsModule} from '@angular/google-maps';
import {LoginComponent} from './login/login.component';
import {ConfigService} from '../assets/config/config-svc.service';
import {LoginService} from './login/login.service';
import {ListUsersComponent} from './users/list-users/list-users.component';
import {CreateUserComponent} from './users/create-user/create-user.component';
import {UpdateUserComponent} from './users/update-user/update-user.component';
import {BulkUploadComponent} from './users/bulk-upload/bulk-upload.component';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {ListOrganizationsComponent} from './organizations/list-organizations/list-organizations.component';
import {ListParentOrganizationsComponent} from './organizations/list-parent-organizations/list-parent-organizations.component';
import {CreateOrganizationComponent} from './organizations/create-organization/create-organization.component';
import {UpdateOrganizationComponent} from './organizations/update-organization/update-organization.component';
import {OrgEntitlementsComponent, SearchPipe} from './organizations/org-entitlements/org-entitlements.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {OrganizationSettingsComponent} from './organizations/organization-settings/organization-settings.component';
import {CdkTableModule} from '@angular/cdk/table';
import {PowerSearchComponent} from './power-search/power-search.component';
import {CommercialResultsComponent} from './power-search/commercial-results/commercial-results.component';
import {SearchHistoryComponent} from './power-search/search-history/search-history.component';
import {AdvancedSearchDialog} from './power-search/search-history/search-history-advanced-search.component';
import {MatRadioModule} from '@angular/material/radio';
import {SafeHtmlPipe} from './shared/safe-html.pipe';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {EmailReportComponent} from './email-report/email-report.component';
import { HTTP_INTERCEPTORS} from '@angular/common/http';
import {JwtInterceptor} from './_helpers/jwt.interceptor';
import { PowerDataResultsComponent } from './power-search/power-data-results/power-data-results.component';
import { OverlayDialog, PowerDataLandmarkResultsComponent} from './power-search/power-data-landmark-results/power-data-landmark-results.component';
import { DbhResultsComponent } from './power-search/dbh-results/dbh-results.component';
import { GenericResultsComponent } from './power-search/generic-results/generic-results.component';
import { CommercialSearchDetailsDialog } from "./power-search/commercial-locate-results/details/commercial-details-dialog";
import { GlobalErrorHandler } from './error/error-handler';
import { CommercialLocateResultsComponent } from './power-search/commercial-locate-results/commercial-locate-results.component';
import { ShortWebLinkComponent } from './short-web-link/short-web-link.component';
import { CreateSearchTypeComponent } from './search-types/create-search-type/create-search-type.component';
import { UpdateSearchTypeComponent } from './search-types/update-search-type/update-search-type.component';
import { ListSearchTypesComponent } from './search-types/list-search-types/list-search-types.component';
import { ListProvidersComponent } from './providers/list-providers/list-providers.component';
import { CreateProviderComponent } from './providers/create-provider/create-provider.component';
import { UpdateProviderComponent } from './providers/update-provider/update-provider.component';
import { HomeDataResultsComponent } from './power-search/home-data-results/home-data-results.component';
import { HomeDataPeopleDetailsDialog } from './power-search/home-data-results/details/people-details-dialog';
import { HomeDataLocationDetailsDialog } from './power-search/home-data-results/details/location-details-dialog';
import { PdDialogComponent } from './pd-dialog/pd-dialog.component';
import { MAT_DIALOG_DEFAULT_OPTIONS } from "@angular/material/dialog";
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { ClientCredentialsComponent } from './organizations/client-credentials/client-credentials.component';
import { CreateTextBlockComponent } from './text-blocks/create-text-block/create-text-block.component';
import { ListTextBlocksComponent } from './text-blocks/list-text-blocks/list-text-blocks.component';
import { UpdateTextBlocksComponent } from './text-blocks/update-text-blocks/update-text-blocks.component';
import { ListEntitlementsComponent } from './entitlements/list-entitlements/list-entitlements.component';
import { CreateEntitlementsComponent } from './entitlements/create-entitlements/create-entitlements.component';
import { UpdateEntitlementsComponent } from './entitlements/update-entitlements/update-entitlements.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MedicalDataResultsComponent } from './power-search/medical-data-results/medical-data-results.component';
import { ListPredefinedTextMessagesComponent } from './iot-gateway/list-predefined-text-messages/list-predefined-text-messages.component';
import { AddPredefinedTextMessageComponent } from './iot-gateway/add-predefined-text-message/add-predefined-text-message.component';
import { UpdatePredefinedTextMessageComponent } from './iot-gateway/update-predefined-text-message/update-predefined-text-message.component';
import { BulkMessageUploadComponent } from './iot-gateway/bulk-upload/bulk-message-upload.component';
import { ExcludeValuePipe } from './pipe/excludeValue';
import { ZeroTouchComponent } from './zero-touch/zero-touch.component';
import { CreatePolygonComponent } from './create-polygon/create-polygon.component';
import { DownloadKmlFileComponent } from './create-polygon/download-kml-file/download-kml-file.component';
import { ListGeofenceComponent } from './geofence/list-geofence/list-geofence.component';
import { MapHideMarkersComponent } from './map-hide-markers/map-hide-markers.component';
import { MmsSearchResultsComponent } from './power-search/mms-search-results/mms-search-results.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AddGeofenceComponent } from './geofence/add-geofence/add-geofence.component';
import { ProvisionUsersComponent } from './users/provision-users/provision-users.component';
import { SelectRolesComponent} from './users/select-roles/select-roles.component';
import { SafeUrlPipe } from './shared/safe-url-pipe';
import { UpdateGeofenceComponent } from './geofence/update-geofence/update-geofence.component';
import { ShowResultComponent } from './geofence/show-result/show-result.component';

export function initializeApp(configService: ConfigService) {
    return () => configService.getConfig();
}

@NgModule({
    declarations: [
        AppComponent,
        ListUsersComponent,
        HomeComponent,
        NavigationComponent,
        CreateUserComponent,
        LoginComponent,
        UpdateUserComponent,
        BulkUploadComponent,
        ListParentOrganizationsComponent,
        ListOrganizationsComponent,
        CreateOrganizationComponent,
        UpdateOrganizationComponent,
        OrgEntitlementsComponent,
        OrganizationSettingsComponent,
        PowerSearchComponent,
        CommercialResultsComponent,
        CommercialLocateResultsComponent,
        SearchHistoryComponent,
        AdvancedSearchDialog,
        HelpClass,
        SafeHtmlPipe,
        SafeUrlPipe,
        SearchPipe,
        EmailReportComponent,
        PowerDataResultsComponent,
        PowerDataLandmarkResultsComponent,
        OverlayDialog,
        DbhResultsComponent,
        GenericResultsComponent,
        CommercialSearchDetailsDialog,
        ShortWebLinkComponent,
        ListSearchTypesComponent,
        CreateSearchTypeComponent,
        UpdateSearchTypeComponent,
        HomeDataResultsComponent,
        ListProvidersComponent,
        CreateProviderComponent,
        UpdateProviderComponent,
        HomeDataPeopleDetailsDialog,
        HomeDataLocationDetailsDialog,
        PdDialogComponent,
        PagenotfoundComponent,
        ClientCredentialsComponent,
        CreateTextBlockComponent,
        ListTextBlocksComponent,
        UpdateTextBlocksComponent,
        ListEntitlementsComponent,
        CreateEntitlementsComponent,
        UpdateEntitlementsComponent,
        DashboardComponent,
        MedicalDataResultsComponent,
        ListPredefinedTextMessagesComponent,
        AddPredefinedTextMessageComponent,
        UpdatePredefinedTextMessageComponent,
        BulkMessageUploadComponent,
        ExcludeValuePipe,
        ZeroTouchComponent,
        CreatePolygonComponent,
        DownloadKmlFileComponent,
        ListGeofenceComponent,
        MapHideMarkersComponent,
        MmsSearchResultsComponent,
        AddGeofenceComponent,
        ProvisionUsersComponent,
        SelectRolesComponent,
        UpdateGeofenceComponent,
        ShowResultComponent
    ],
    bootstrap: [AppComponent], imports: [GoogleMapsModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        MatCardModule,
        MatProgressSpinnerModule,
        MatMenuModule,
        MatIconModule,
        MatToolbarModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatSortModule,
        MatTableModule,
        MatCheckboxModule,
        MatPaginatorModule,
        BrowserAnimationsModule,
        MatDialogModule,
        MatGridListModule,
        MatProgressBarModule,
        MatSnackBarModule,
        MatListModule,
        MatChipsModule,
        ScrollingModule,
        MatTabsModule,
        MatBadgeModule,
        DragDropModule,
        CdkTableModule,
        MatAutocompleteModule,
        MatSlideToggleModule,
        MatTooltipModule,
        MatChipsModule,
        MatExpansionModule,
        MatButtonToggleModule,
        MatRadioModule,
        MatDatepickerModule,
        MatNativeDateModule,
        NgbTooltipModule,
        MatSidenavModule,
        DateTimeModule,
        NativeDateTimeModule,
        MomentDateTimeModule,
        FontAwesomeModule
    ],
    providers: [ConfigService, LoginService, TitleCasePipe, DatePipe,
        {provide: APP_INITIALIZER, useFactory: initializeApp, deps: [ConfigService], multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
        {provide: ErrorHandler, useClass: GlobalErrorHandler},
        {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {disableClose: true, hasBackdrop: true}}, provideHttpClient(withInterceptorsFromDi())] })
export class AppModule {
}
