import {Component, Inject, NgZone, OnInit} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Alerts } from 'src/app/shared/enums/alerts';
import { Patterns } from 'src/app/shared/enums/patterns';
import { SharedService } from 'src/app/shared/shared.service';
import { GeofenceService } from '../geofence.service';
import { JSONParser } from '@streamparser/json';
import Swal from 'sweetalert2/dist/sweetalert2.js';

enum Mode {SELECTING, COUNTING, PROCESSING}
@Component({
    selector: 'app-add-geofence',
    templateUrl: './add-geofence.component.html',
    styleUrls: ['./add-geofence.component.scss']
})
export class AddGeofenceComponent {
    threadCnt = 15;
    remainingThreadCnt = 0;
    geofenceFileFC = new UntypedFormControl('', [Validators.required, Validators.pattern(Patterns.JSON_FILE)]);
    geofenceFile: File;
    geofenceFileName: string;
    featureCount = 0;
    progress: number = 0;
    canceled = false;
    processedGeofenceCnt = 0;
    allModes = Mode;
    mode = Mode.SELECTING;
    fieldcheck: boolean = false;
    readonly GEOFENCE_FILE: string = 'geofenceFileFC';
    processTime;

    constructor(@Inject(MAT_DIALOG_DATA) private data,
                public dialogRef: MatDialogRef<AddGeofenceComponent>,
                public dialog: MatDialog,
                private sharedService: SharedService,
                private ngZone: NgZone,
                private geofenceService: GeofenceService) {
    }

    onFileUpload(files: any) {
        if (files && files.length === 1) {
            this.geofenceFileName = files[0].name;
            this.geofenceFile = files.item(0);
        }
        if (files && files.length > 1) {
            this.sharedService.showAlert(Alerts.ERROR, 'Please do not upload multiple files.');
        }
    }

    getErrorMessage(formControl) {
        if (formControl === this.GEOFENCE_FILE) {
            return this.geofenceFileFC.hasError('required') ? 'You must upload a geofence file' : 'Upload a valid response geofence file (.json)';
        }
    }

    markFormControlsAsTouched() {
        this.geofenceFileFC.markAsTouched();
    }

    isValidData() {
        this.markFormControlsAsTouched();
        return this.geofenceFileFC.valid;
    }

    onCountComplete() {
        console.log('Final count: ' + this.featureCount);
        this.mode = Mode.PROCESSING;
        this.streamFeatures();
    }

    async uploadFile() {
        if (this.isValidData()) {
            try {
                this.featureCount = 0;
                this.mode = Mode.COUNTING;
                this.countChunks(this.getReader());
                setTimeout(async () => {
                }, 500);
            } catch (error) {
                console.log(error);
                this.sharedService.showAlert(Alerts.ERROR, 'Invalid file');
            }
        }
    }

    countChunks(stream) {
        stream.read().then(({done, value}) => {
            if (done) {
                this.onCountComplete();
            } else {
                ++this.featureCount;
                setTimeout(() => this.countChunks(stream), 0);
            }
        });
    }

    getReader() {
        return  this.geofenceFile.stream()
            .pipeThrough(new TextDecoderStream())
            .pipeThrough(this.parse())
            .getReader();
    }

    streamFeatures() {
        setTimeout(() => {
            const result: Array<any> = [];
            const startTime = performance.now();
            const reader = this.geofenceFile.stream()
                .pipeThrough(new TextDecoderStream())
                .pipeThrough(this.parse())
                .getReader();
            this.ngZone.runOutsideAngular(() => {
                let i = this.threadCnt;
                this.remainingThreadCnt = this.threadCnt;
                while (i-- > 0) {
                    nextFeature(this);
                }
            });

            function nextFeature(that) {
                return reader.read().then(({ done, value }) => {
                    if (done) {
                        console.log('rtc: ' + that.remainingThreadCnt);
                        if (--that.remainingThreadCnt <= 0) {
                            that.dialogRef.close(result);
                            that.remainingThreadCnt = 0;
                        }
                        return;
                    }
                    if (value) {
                        that.geofenceService.uploadGeofenceFile(value.value).subscribe({
                            next: data => {
                                result.push(data);
                                that.processedGeofenceCnt = result.length;
                                that.processTime = ((performance.now() - startTime) / 1000).toFixed(0);
                                that.rate = (that.processedGeofenceCnt / that.processTime).toFixed(1);
                                that.progress = 100 * result.length / that.featureCount;
                                that.processedThreadCnt++;
                                if (!that.canceled) {
                                    return nextFeature(that);
                                }
                            },
                            error: error => {
                                that.sharedService.showAlert(Alerts.ERROR, error.error.message ? error.error.message : error.error.detail);
                                that.dialogRef.close(false);
                                return;
                            },
                            complete: () => { }
                        });
                    }
                });
            }
        }, 300);
    }

    parse() {
        const jsonparser = new JSONParser({
            paths: ["$.features.*"],
            keepStack: false
        });

        let super_controller;

        jsonparser.onValue = (feature) => {
            super_controller.enqueue(feature);
        };

        return new TransformStream({
            transform(chunk, controller) {
                super_controller = controller;
                jsonparser.write(chunk);
            }
        });
    }

    close() {
        if (this.fieldcheck) {
            Swal.fire({
                title: 'Are you sure?',
                text: 'Your changes will not be saved !',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes, Close',
                cancelButtonText: 'No, Cancel',
            }).then((result) => {
                if (result.value) {
                    this.dialogRef.close(false);
                }
            });
        } else {
            this.dialogRef.close(false);
        }
        this.canceled = true;
    }

    change(event) {
        /*
        if (event.srcElement.value !== '') {
            this.count = this.count + 1;
        } else if (event.srcElement.value === '') {
            this.count = this.count - 1;
        }
        if (this.count <= 1 || !this.fieldcheck) {
            this.fieldcheck = true;
        }
         */
    }
}
