export enum StateList {
    "AL" = "Alabama",
    "AK" = "Alaska",
    "AZ" = "Arizona",
    "AR" = "Arkansas",
    "CA" = "California",
    "CO" = "Colorado",
    "CT" = "Connecticut",
    "DE" = "Delaware",
    "DC" = "District of Columbia",
    "FL" = "Florida",
    "GA" = "Georgia",
    "HI" = "Hawaii",
    "ID" = "Idaho",
    "IL" = "Illinois",
    "IA" = "Iowa",
    "IN" = "Indiana",
    "KS" = "Kansas",
    "KY" = "Kentucky",
    "LA" = "Louisiana",
    "ME" = "Maine",
    "MD" = "Maryland",
    "MA" = "Massachusettes",
    "MI" = "Michigan",
    "MN" = "Minnesota",
    "MS" = "Mississippi",
    "MO" = "Missouri",
    "MT" = "Montana",
    "NE" = "Nebraska",
    "NV" = "Nevada",
    "NH" = "New Hampshire",
    "NM" = "New Mexico",
    "NJ" = "New Jersey",
    "NY" = "New York",
    "NC" = "North Carolina",
    "ND" = "North Dakota",
    "OH" = "Ohio",
    "OK" = "Oklahoma",
    "OR" = "Oregon",
    "PA" = "Pennsylvania",
    "PR" = "Puerto Rico",
    "RI" = "Rhode Island",
    "SC" = "South Carolina",
    "SD" = "South Dakota",
    "TN" = "Tennessee",
    "TX" = "Texas",
    "UT" = "Utah",
    "VT" = "Vermont",
    "VI" = "Virgin Islands",
    "VA" = "Virginia",
    "WA" = "Washington",
    "WV" = "West Virginia",
    "WI" = "Wisconsin",
    "WY" = "Wyoming",
    "AB" = "Alberta",
    "BC" = "British Columbia",
    "MB" = "Manitoba",
    "NB" = "New Brunswick",
    "NL" = "Newfoundland",
    "NS" = "Nova Scotia",
    "NT" = "Northwest Territories",
    "NU" = "Nunavut",
    "ON" = "Ontario",
    "PE" = "Prince Edward Island",
    "QC" = "Quebec",
    "SK" = "Saskatchewan",
    "YT" = "Yuko"
}

export namespace StateNameSpace {
    export function getStateByStateCode(stateEntered) {
        let stateList = StateList;
        try {
            return stateList[stateEntered.toUpperCase()];
        } catch (IllegalArgumentException) {
            return null;
        }
    }

    export function getStateByStateName(stateEntered) {
        let stateList = StateList;
        try {
            for (let value in StateList) {
                if (stateList[value].toString() === stateEntered) {
                    return stateList[value];
                }
            }
            return null;
        } catch (IllegalArgumentException) {
            return null;
        }
    }
}