// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-block {
  background: #13294B;
  display: grid;
  position: absolute;
  height: 100%;
  overflow: auto;
}

.desc-block {
  color: white;
  float: right;
  height: 100%;
}

.title-div {
  color: #8CE2D0;
  text-align: center;
  font-size: 48px;
  font-weight: 700;
  margin-top: 175px;
}

p {
  padding: 25px 25px 25px 0px;
  line-height: 24px;
  text-align: center;
  font-size: 16px;
}

button {
  margin: 0 auto;
  display: block;
}

.mat-mdc-raised-button.mat-unthemed {
  background-color: #00677F;
  color: white;
  font-size: 16px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-weight: 500;
  height: 55px;
  border-radius: 8px;
}

:host ::ng-deep .mdc-button__label {
  padding: 0px 25px !important;
}
@media (max-width: 375px) {
  :host ::ng-deep .mdc-button__label {
    padding: 0px !important;
  }
}

.foot-logo {
  width: 230px;
  position: fixed;
  bottom: 10px;
  right: 20px;
  float: right;
}
@media (max-width: 375px) {
  .foot-logo {
    width: 150px;
  }
}
@media (min-width: 376px) and (max-width: 767px) {
  .foot-logo {
    width: 180px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/login/login.component.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,aAAA;EACA,kBAAA;EACA,YAAA;EACA,cAAA;AACF;;AAEA;EACE,YAAA;EACA,YAAA;EACA,YAAA;AACF;;AAEA;EACE,cAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AACF;;AAEA;EACE,2BAAA;EACA,iBAAA;EACA,kBAAA;EACA,eAAA;AACF;;AAEA;EACE,cAAA;EACA,cAAA;AACF;;AAEA;EACE,yBAAA;EACA,YAAA;EACA,eAAA;EACA,iDAAA;EACA,gBAAA;EACA,YAAA;EACA,kBAAA;AACF;;AAEA;EACE,4BAAA;AACF;AAAE;EAFF;IAGI,uBAAA;EAGF;AACF;;AAAA;EACE,YAAA;EACA,eAAA;EACA,YAAA;EACA,WAAA;EACA,YAAA;AAGF;AADE;EAPF;IAQI,YAAA;EAIF;AACF;AAHE;EAVF;IAWI,YAAA;EAMF;AACF","sourcesContent":[".container-block {\n  background: #13294B; \n  display: grid; \n  position: absolute; \n  height: 100%;\n  overflow: auto;\n}\n\n.desc-block {\n  color: white;\n  float: right;\n  height: 100%;\n}\n\n.title-div {\n  color: #8CE2D0; \n  text-align: center; \n  font-size: 48px; \n  font-weight: 700; \n  margin-top: 175px;\n}\n\np {\n  padding: 25px 25px 25px 0px;\n  line-height: 24px;\n  text-align: center;\n  font-size: 16px;\n}\n\nbutton {\n  margin: 0 auto;\n  display: block;\n}\n\n.mat-mdc-raised-button.mat-unthemed {\n  background-color: #00677F;\n  color: white;\n  font-size: 16px;\n  font-family: Roboto, 'Helvetica Neue', sans-serif;\n  font-weight: 500;\n  height: 55px;\n  border-radius: 8px;\n}\n\n:host ::ng-deep .mdc-button__label {\n  padding: 0px 25px !important;\n  @media (max-width: 375px) {\n    padding: 0px !important;\n  }\n}\n\n.foot-logo {\n  width: 230px;\n  position: fixed;\n  bottom: 10px;\n  right: 20px;\n  float: right;\n\n  @media (max-width: 375px) {\n    width: 150px;\n  }\n  @media (min-width: 376px) and ( max-width: 767px) {\n    width: 180px;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
