import {Component, Inject, OnInit, Pipe, PipeTransform} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {Roles} from '../../shared/enums/roles';
import {OrganizationsService} from '../organizations.service';
import {HomeService} from '../../home/home.service';
import {EntitlementTo} from '../../shared/models/entitlementTo';
import {SharedService} from '../../shared/shared.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
    selector: 'app-org-entitlements',
    templateUrl: './org-entitlements.component.html',
    styleUrls: ['./org-entitlements.component.scss']
})

export class OrgEntitlementsComponent implements OnInit {
    selectAll: boolean = false;
    iotCreateEntitlement;
    iotSendLinkEntitlement;
    iotSendTextEntitlement;
    userRole = [];
    entitlementData = [];
    dirty = false;
    loading: boolean;
    roles = Roles;
    searchText: string;
    header = "Organization Entitlements";
    title;

    constructor(@Inject(MAT_DIALOG_DATA) public data,
                public dialogRef: MatDialogRef<OrgEntitlementsComponent>,
                public organizationsService: OrganizationsService, public homeService: HomeService, public sharedService: SharedService) {
    }

    ngOnInit() {
        this.iotCreateEntitlement = this.data.entitlements.find(e => e.name === "IOT_CREATE");
        this.iotSendLinkEntitlement = this.data.entitlements.find(e => e.name === "IOT_SEND_LINK");
        this.iotSendTextEntitlement = this.data.entitlements.find(e => e.name === "IOT_SEND_TEXT");
        this.getOrg();
    }

    /** Initialize org on init. */
    getOrg() {
        this.loading = true;
        this.organizationsService.getOrg(this.data.org.uuid).subscribe({
            next: data => {
                this.loading = false;
                this.data.org = JSON.parse(JSON.stringify(data));
                this.data.entitlements.sort((a, b) => a.displayName < b.displayName ? -1 : 1);
                this.data.entitlements.forEach(e => {
                    if (e.orgType === data.type) {
                        this.entitlementData.push(e);
                    }
                })
                this.entitlementData.forEach(e => {
                    e.selected = this.data.org.entitlements.find(e1 => e1 === e.name) != null;
                })
                this.title = this.data.org?.name;
                this.userRole = this.homeService.userDetails.roles.slice();
                this.setAllSelected();
            }, 
            error: error => {
                this.loading = false;
                this.dialogRef.close(false);
                this.sharedService.handleErrors(error);
            },
            complete: () => {}
        });
    }

    /** Called when checkbox value changes. */
    changeEntitlements(value: boolean, entitlement: EntitlementTo) {
        this.dirty = true;
        switch (entitlement.name) {
            case this.iotCreateEntitlement.name:
                if (!value) {
                    this.iotSendLinkEntitlement.selected = false;
                    this.iotSendTextEntitlement.selected = false;
                }
                break;
            case this.iotSendLinkEntitlement.name:
            case this.iotSendTextEntitlement.name:
                if (value) {
                    this.iotCreateEntitlement.selected = true;
                }
        }
        this.setAllSelected();
    }

    setAllSelected() {
        this.selectAll = this.entitlementData.findIndex(e => !e.selected) === -1;
    }

    selectAllToggle(value: boolean) {
        this.dirty = this.dirty || this.data.entitlements.find(e => e.selected != value)
        this.data.entitlements.forEach(e => {
            if (e.orgType === this.data.type) {
                this.entitlementData.push(e);
            }
        })
        this.entitlementData.forEach(e => {e.selected = value;}) 
    }

    updateOrgEntitlements() {
        this.dialogRef.close(true);
        this.data.org.entitlements = this.data.entitlements.filter(e => e.selected).map(e => {
            delete e.selected;
            return e.name;
        });
        this.organizationsService.updateOrganization(this.data.org).subscribe(() => {
            this.sharedService.showAlert('success', 'Organization Entitlements updated successfully');
            this.homeService.updateUserInfo().subscribe(data => {
                //console.log(data);
            });
            this.dialogRef.close(true);
        });
    }

    resetEntitlements() {
        this.doOnConfirm(() => this.getOrg(), 'Yes, Reset');
        this.searchText = "";
    }

    close() {
        this.doOnConfirm(() => this.dialogRef.close(false), 'Yes, Close');
    }

    private doOnConfirm(action, confirmText) {
        if (this.dirty) {
            Swal.fire({
                title: 'Are you sure?',
                text: 'Your changes will be lost!',
                icon: 'warning',
                showCancelButton: true,
            confirmButtonColor: '#DD6B55',
                confirmButtonText: confirmText,
                cancelButtonText: 'No, Cancel',
            }).then(result => {
                if (result.value) {
                    action.call();
                    this.dirty = false;
                }
            });
        } else {
            action.call();
        }
    }
}

@Pipe({ name: 'search' })
export class SearchPipe implements PipeTransform {
    transform(items: any[], searchText: string): any[] {
        if (!searchText) return items;
        return items.filter(it => {
            return it.displayName.toLowerCase().includes(searchText.toLowerCase());
        });
    }
}
