export class ClientCredentialsTo {
    
    id: string;
    orgId: string;
    clientId: string;
    secret: string;
    provider: string;

    constructor(object?: any) {
        if(object) {
            this.id = object.id;
            this.orgId = object.orgId;
            this.clientId = object.clientId;
            this.secret = object.secret;
            this.provider = object.provider;
        }
    }

}