import {Point} from "./point";
import {CivicAddressTo} from './CivicAddressTo';
import {VehicleSearchRequestTo} from './vehicleSearchRequest';

export class BrokerSearchRequestTo {
    location: Point;
    phone: string
    address: CivicAddressTo;
    vehicle: VehicleSearchRequestTo
    searchTypes : string[] = [];
}
