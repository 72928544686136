import { UntypedFormControl, Validators } from "@angular/forms";
import { EntitlementTo } from "../shared/models/entitlementTo";
import { PreferenceTo } from "../shared/models/preferenceTo";

/**
 * Common base class for create and update entitlement components.
 */
export abstract class EntitlementBase {

    isLoading = false;
    fieldcheck = false;
    count = 1;
    entitlement: EntitlementTo;
    defaultValue: string  = "PSAP";
    preferenceList: Array<PreferenceTo> = [];

    readonly NAME_MIN_LENGTH = 2;
    readonly NAME_MAX_LENGTH = 20;
    readonly DISPLAY_NAME_MIN_LENGTH = 2;
    readonly DISPLAY_NAME_MAX_LENGTH = 50;
    readonly NAME = 'name';
    readonly DISPLAYNAME = 'displayName';

    name = new UntypedFormControl('', [
        Validators.minLength(this.NAME_MIN_LENGTH), Validators.maxLength(this.NAME_MAX_LENGTH)
    ]);
    displayName = new UntypedFormControl('', [
        Validators.minLength(this.DISPLAY_NAME_MIN_LENGTH), Validators.maxLength(this.DISPLAY_NAME_MAX_LENGTH), Validators.required
    ]);
    preferences = new UntypedFormControl('');

    constructor() {}

    onValChange(val: string) {
        this.entitlement.orgType = val;
    }
    
    markFormControlsAsTouched() {
        this.name.markAsTouched();
        this.displayName.markAsTouched();
        this.preferences.markAsTouched();
    }

    getErrorMessage(formControl) {
        if (formControl === this.NAME) {
            return this.name.hasError('required') ? 'You must enter a value' : 'Enter a valid name (2-20 characters)';
        } else if (formControl === this.DISPLAYNAME) {
            return this.displayName.hasError('required') ? 'You must enter a value' : 'Enter a valid Display Name (2-50 characters)';
        }
    }

    change(event) {
        if(event) {
            if (event.srcElement.value !== '') {
                this.count = this.count + 1;
            } else if (event.srcElement.value === '') {
                this.count = this.count - 1;
            }
        }
        if (this.count <= 1 || !this.fieldcheck) {
            this.fieldcheck = true;
        }
    }
    
}
