
export class EntitlementTo {

    id: number;
    name: string;
    displayName: string;
    preferences: Array<String>;
    orgType : string;

    constructor(object?: any) {
        if (object?.hasOwnProperty('id')) {
            this.id = object?.id;
        }
        this.name = object?.name;
        this.displayName = object?.displayName;
        this.orgType = object?.orgType;
        if (object?.hasOwnProperty('preferences')) {
          this.preferences = object?.preferences;
        }
    }
}
