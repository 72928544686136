// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkbox {
  margin-left: 10px;
}

.position {
  float: right;
}

.entitlements {
  width: 100%;
  height: 40vh;
  padding: 10px 20px 10px 10px;
  border: #e8e6e6 solid;
  border-radius: 4px;
  overflow-y: scroll;
}`, "",{"version":3,"sources":["webpack://./src/app/organizations/org-entitlements/org-entitlements.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,4BAAA;EACA,qBAAA;EACA,kBAAA;EACA,kBAAA;AACF","sourcesContent":[".checkbox {\n  margin-left: 10px;\n}\n\n.position {\n  float: right;\n}\n\n.entitlements {\n  width: 100%;\n  height: 40vh;\n  padding: 10px 20px 10px 10px;\n  border: #e8e6e6 solid;\n  border-radius: 4px;\n  overflow-y:scroll;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
