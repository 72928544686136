import { DatePipe } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'people-details-dialog',
    templateUrl: 'people-details-dialog.html',
    styleUrls: ['./people-details-dialog.scss']
  })
  export class HomeDataPeopleDetailsDialog {
    header = "Person Details"
    constructor(
      private datepipe: DatePipe,
      public dialogRef: MatDialogRef<HomeDataPeopleDetailsDialog>,
      @Inject(MAT_DIALOG_DATA) public data) {
        
    }
  
    cancel() {
      this.dialogRef.close();
    }

    getImage(element) {
      if (element) {
        return 'assets/img/home-data/' + element;
      }
    }
  
    toUpperCase(val) {
      return val.toUpperCase();
    }
  
    getFullName(people: any) {
      return [people.FirstName, people.MiddleInitial, people.LastName]
          .filter((val) => val).join(' ');
    }
  
    formatDoB(dob) {
      if (dob.$date.$numberLong) {
        var date = new Date(parseInt(dob.$date.$numberLong));
        return this.datepipe.transform(date, 'yyyy-MM-dd');
      } else if(dob.$date) {
        var date = new Date(dob.$date);
        return this.datepipe.transform(date, 'yyyy-MM-dd');
      } else {
          return 'XXXX-XX-XX';
      }
    }

    getTelephoneNumbers(people: any){
      var val : string;
      val = '';
      for (let telephoneNumberIndex of people.TelephoneNumbers) { 
        for (let telephoneNumber of this.data.selectedResult.TelephoneNumbers) {
          if (telephoneNumber.id === telephoneNumberIndex) {
            val += telephoneNumber.DisplayName + ' \r\n';
          }
        }      
      }
  
      return val;
    }
    
    getLocations(people: any){
      var val : string;
      val = '';
      for (let locationIndex of people.Locations) { 
        for (let location of this.data.selectedResult.Locations) {
          if (location.id === locationIndex) {
            val += location.DisplayName + ' \r\n';
          }
        }
        
      }
  
      return val;
    }
  }
