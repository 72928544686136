// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-fields-width {
  width: 100%;
}

.mat-mdc-card-title {
  padding: 16px 16px;
}`, "",{"version":3,"sources":["webpack://./src/app/text-blocks/update-text-blocks/update-text-blocks.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;AACJ;;AAEA;EACI,kBAAA;AACJ","sourcesContent":[".form-fields-width {\n    width: 100%;\n}\n\n.mat-mdc-card-title {\n    padding: 16px 16px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
