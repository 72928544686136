import { FormControl, Validators } from "@angular/forms";

/**
 * Common base class for create and update NA_Text_Message components.
 */
export abstract class PredefinedTextMessageBase {

    readonly MSG_MIN_LENGTH = 2;
    readonly MSG_MAX_LENGTH = 500;
    readonly MESSAGE = 'message';
    readonly PREFIX = 'prefix';

    fieldcheck: boolean = false;
    count: number = 1;

    message = new FormControl('', [
        Validators.minLength(this.MSG_MIN_LENGTH), Validators.maxLength(this.MSG_MAX_LENGTH), Validators.required
    ]);

    prefix = new FormControl('', Validators.required);

    markFormControlsAsTouched() {
        this.prefix.markAsTouched();
        this.message.markAsTouched();
    }

    isInValidData(): boolean {
        this.markFormControlsAsTouched();
        return this.message.invalid || this.prefix.invalid;
    }

    getErrorMessage(formControl) {
        if (formControl === this.MESSAGE) {
            return this.message.hasError('required') ? 'You must enter a value' :  'You must enter a value of minimum 2 and maximun 500 characters';
        }
        if(formControl === this.PREFIX) {
            return this.prefix.hasError('required') ? 'You must select a value' : '';
        }
    }
}