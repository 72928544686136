// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-dialog-title {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.pd-dialog-title {
  margin-top: -10px;
  margin-bottom: 10px;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/pd-dialog/pd-dialog.component.scss"],"names":[],"mappings":"AAAA;EACE,wDAAA;EACA,sBAAA;AACF;;AAEA;EACE,iBAAA;EACA,mBAAA;EACA,kBAAA;AACF","sourcesContent":[".mat-dialog-title {\n  font: 500 20px / 32px Roboto, \"Helvetica Neue\", sans-serif;\n  letter-spacing: normal;\n}\n\n.pd-dialog-title {\n  margin-top: -10px;\n  margin-bottom: 10px;\n  text-align:center;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
