import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ConfigService} from '../../assets/config/config-svc.service';
import {map} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {UserTo} from '../shared/models/userTo';
import { SharedService } from '../shared/shared.service';

@Injectable({
    providedIn: 'root'
})
export class HomeService {
    navigate = new Subject<string>();
    navigate$ = this.navigate.asObservable();
    userDetails: UserTo;

    constructor(public http: HttpClient, public sharedService: SharedService) {
    }

    getHeaders() {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'transactionId': this.sharedService.generateUUID(),
                'reportType': 'All'
            }),
            withCredentials: true
        };
        return httpOptions;
    }

    getUserInfo(): Observable<UserTo> {
        return this.http.get<UserTo>(ConfigService.config.userInfoUrl, {withCredentials: true}).pipe(map(res => this.setUsersDataInModel(res)));
    }

    updateUserInfo(): Observable<UserTo> {
        return this.http.get<UserTo>(ConfigService.config.userInfoUrl, {withCredentials: true}).pipe(map(res => this.updateUsersDataInModel(res)));
    }

    setUsersDataInModel(usersData) {
        const eachUser = new UserTo(usersData);
        this.userDetails = eachUser;
        return eachUser;
    }

    updateUsersDataInModel(usersData) {
        const eachUser = new UserTo(usersData);
        this.userDetails = eachUser;
        return eachUser;
    }

    refreshAccessToken(refreshToken) {
        return this.http.post(ConfigService.config.refreshAccessTokenUri, refreshToken,
            this.getHeaders()).pipe(map((res: any) => res));
    }
}
