// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `mat-drawer {
  background-color: #f8f9fa;
}

mat-drawer-container {
  height: 100vh;
}

.buttonMatDrawerToggle {
  position: absolute;
  top: 65px;
  left: -4px;
  border-radius: 4px;
  padding: 0;
  max-width: 30px;
  min-width: 30px;
  height: 50px;
  z-index: 4;
}

.drawer-container {
  overflow: auto;
}

.mapCenter {
  bottom: 115px;
  right: 12px !important;
  padding: 18px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
mat-button-toggle-group {
  background: white;
  margin-top: 10px;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
mat-button-toggle {
  color: black;
  padding: 1px;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.mat-button-toggle-checked {
  font-weight: 500;
}

.map {
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/dashboard/dashboard.component.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;AACJ;;AAEA;EACI,aAAA;AACJ;;AAEA;EACI,kBAAA;EACA,SAAA;EACA,UAAA;EACA,kBAAA;EACA,UAAA;EACA,eAAA;EACA,eAAA;EACA,YAAA;EACA,UAAA;AACJ;;AAEA;EACI,cAAA;AACJ;;AAEA;EACI,aAAA;EACA,sBAAA;EACA,wBAAA;AACJ;;AAEA,6HAAA;AACA;EACI,iBAAA;EACA,gBAAA;AACJ;;AAEA,6HAAA;AACA;EACI,YAAA;EACA,YAAA;AACJ;;AAEA,6HAAA;AACA;EACI,gBAAA;AACJ;;AAEA;EACI,WAAA;EACA,YAAA;AACJ","sourcesContent":["mat-drawer {\n    background-color: #f8f9fa;\n}\n\nmat-drawer-container {\n    height: 100vh;\n}\n\n.buttonMatDrawerToggle{\n    position: absolute;\n    top: 65px;\n    left: -4px;\n    border-radius: 4px;    \n    padding: 0;\n    max-width: 30px;\n    min-width: 30px;\n    height: 50px;\n    z-index: 4;\n}\n\n.drawer-container {\n    overflow: auto;\n}\n\n.mapCenter {\n    bottom: 115px;\n    right: 12px !important;\n    padding: 18px !important;\n}\n\n/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */\nmat-button-toggle-group {\n    background: white;\n    margin-top: 10px;\n}\n  \n/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */\nmat-button-toggle {\n    color: black;\n    padding: 1px;\n}\n\n/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */\n.mat-button-toggle-checked {\n    font-weight: 500;\n}\n\n.map {\n    width: 100%;\n    height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
