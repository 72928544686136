// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-fields-width {
  width: 100%;
}

.mat-mdc-card-title {
  padding: 16px 10px;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.mat-button-toggle-group {
  align-items: center;
  font-size: 14px;
  height: 40px;
}

.fa-industry {
  color: blue;
}

.fa-shield-alt {
  color: red;
}

.fa-tools {
  color: green;
}`, "",{"version":3,"sources":["webpack://./src/app/organizations/create-organization/create-organization.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA,6HAAA;AACA;EACE,mBAAA;EACA,eAAA;EACA,YAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,UAAA;AACF;;AAEA;EACE,YAAA;AACF","sourcesContent":[".form-fields-width {\n  width: 100%;\n}\n\n.mat-mdc-card-title {\n  padding: 16px 10px;\n}\n\n/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */\n.mat-button-toggle-group {\n  align-items: center;\n  font-size: 14px;\n  height: 40px;\n}\n\n.fa-industry {\n  color: blue;\n}\n\n.fa-shield-alt {\n  color: red;\n}\n\n.fa-tools {\n  color: green;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
