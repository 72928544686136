// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `mat-toolbar {
  background: #13294B;
  color: white;
}

.mat-mdc-icon-button:hover {
  background-color: #00677F;
}

.textBlock:hover {
  color: white;
}
.textBlock:hover .textblock-icon {
  color: white;
}
.textBlock .textblock-icon {
  font-size: 22px;
}

.entitlement:hover {
  color: white;
}
.entitlement:hover .entitlement-icon {
  color: white;
}
.entitlement .entitlement-icon {
  font-size: 22px;
}

::ng-deep .mat-mdc-menu-item {
  font-size: 12pt;
}

::ng-deep .mat-mdc-menu-item:hover .mdc-list-item__primary-text {
  background: #00677F;
  color: white;
}

.orgDropdown {
  background: #13294B;
  color: white;
}

:host ::ng-deep .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: #13294B !important;
  border-bottom: white solid 1px;
}
:host ::ng-deep .mdc-text-field--filled:not(.mdc-text-field--disabled) :hover {
  background-color: transparent !important;
}

:host ::ng-deep .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: white;
}

strong {
  font-size: 10pt;
}`, "",{"version":3,"sources":["webpack://./src/app/navigation/navigation.component.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,YAAA;AACF;;AAGE;EACE,yBAAA;AAAJ;;AAKE;EACE,YAAA;AAFJ;AAGI;EACE,YAAA;AADN;AAIE;EACE,eAAA;AAFJ;;AAQE;EACE,YAAA;AALJ;AAMI;EACE,YAAA;AAJN;AAOE;EACE,eAAA;AALJ;;AAUA;EACE,eAAA;AAPF;;AAUA;EACI,mBAAA;EACA,YAAA;AAPJ;;AAUA;EACE,mBAAA;EACA,YAAA;AAPF;;AAUA;EAIE,oCAAA;EACA,8BAAA;AAVF;AAME;EACE,wCAAA;AAJJ;;AAUA;EACE,YAAA;AAPF;;AAUA;EACE,eAAA;AAPF","sourcesContent":["mat-toolbar {\n  background: #13294B;\n  color: white;\n}\n\n.mat-mdc-icon-button {\n  &:hover {\n    background-color: #00677F;\n  }\n}\n\n.textBlock {\n  &:hover {\n    color: white;\n    .textblock-icon {\n      color: white;\n    }\n  }\n  .textblock-icon {\n    font-size: 22px;\n    //color: #252424;\n  }\n}\n\n.entitlement {\n  &:hover {\n    color: white;\n    .entitlement-icon {\n      color: white;\n    }\n  }\n  .entitlement-icon {\n    font-size: 22px;\n    //color: #252424;\n  }\n}\n\n::ng-deep .mat-mdc-menu-item {\n  font-size: 12pt;\n}\n\n::ng-deep .mat-mdc-menu-item:hover .mdc-list-item__primary-text {\n    background: #00677F;\n    color: white;\n}\n\n.orgDropdown {\n  background: #13294B;\n  color: white;\n}\n\n:host ::ng-deep .mdc-text-field--filled:not(.mdc-text-field--disabled) {\n  :hover {\n    background-color: transparent !important;\n  }\n  background-color: #13294B !important;\n  border-bottom: white solid 1px;\n}\n\n:host ::ng-deep .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {\n  color: white;\n}\n\nstrong {\n  font-size: 10pt;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
