// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-fields-width {
  width: 100%;
  font-size: 15px;
  padding: 0;
}

@media (min-width: 747px) {
  .input-box {
    border-right: 2px #e7e7e7 solid;
  }
}
.input-box .half {
  width: 47.5%;
}

.mat-mdc-button {
  line-height: unset;
  height: 26px;
  font-weight: 600;
  font-size: 14px;
}

::ng-deep .cdk-overlay-pane {
  max-width: 100vw !important;
  max-height: 100vh;
}

::ng-deep .cdk-overlay-pane .mat-mdc-dialog-container {
  max-width: 100vw;
  max-height: 100vh;
}

.example-large-box {
  display: grid;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.search-types {
  max-height: 50vh;
}

.search-types-list {
  max-height: 410px;
  overflow-y: auto;
}

.search-types-header {
  overflow: hidden;
}

.panel {
  overflow: hidden;
}

.delete-date {
  font-size: 26px;
  color: #707070;
}

.to-date {
  padding-left: 10px;
  padding-right: 5px;
}
.to-date .form-fields-width {
  width: 88%;
  font-size: 15px;
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/power-search/search-history/search-history-advanced-search.component.scss"],"names":[],"mappings":"AACA;EACE,WAAA;EACA,eAAA;EACA,UAAA;AAAF;;AAIE;EADF;IAEI,+BAAA;EAAF;AACF;AAEE;EACE,YAAA;AAAJ;;AAIA;EACE,kBAAA;EACA,YAAA;EACA,gBAAA;EACA,eAAA;AADF;;AAIA;EACE,2BAAA;EACA,iBAAA;AADF;;AAIA;EACE,gBAAA;EACA,iBAAA;AADF;;AAIA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;EACA,mBAAA;EACA,iBAAA;EACA,kBAAA;AADF;;AAKA;EACE,gBAAA;AAFF;;AAKA;EACE,iBAAA;EACA,gBAAA;AAFF;;AAKA;EACE,gBAAA;AAFF;;AAKA;EACE,gBAAA;AAFF;;AAKA;EACE,eAAA;EACA,cAAA;AAFF;;AAKA;EACE,kBAAA;EACA,kBAAA;AAFF;AAIE;EACE,UAAA;EACA,eAAA;EACA,UAAA;AAFJ","sourcesContent":["\n.form-fields-width {\n  width: 100%;\n  font-size: 15px;\n  padding: 0;\n}\n\n.input-box {\n  @media (min-width: 747px) {\n    border-right: 2px #e7e7e7 solid;\n  }\n\n  .half {\n    width: 47.5%;\n  }\n}\n\n.mat-mdc-button {\n  line-height: unset;\n  height: 26px;\n  font-weight: 600;\n  font-size: 14px;\n}\n\n::ng-deep .cdk-overlay-pane {\n  max-width: 100vw !important;\n  max-height: 100vh;\n}\n\n::ng-deep .cdk-overlay-pane .mat-mdc-dialog-container {\n  max-width: 100vw;\n  max-height: 100vh;\n}\n\n.example-large-box {\n  display: grid;\n  align-items: center;\n  justify-content: center;\n  margin-top: 10px;\n  margin-bottom: 10px;\n  margin-left: 10px;\n  margin-right: 10px;\n\n}\n\n.search-types {\n  max-height: 50vh;\n}\n\n.search-types-list {\n  max-height: 410px;\n  overflow-y: auto;\n}\n\n.search-types-header {\n  overflow: hidden;\n}\n\n.panel {\n  overflow: hidden;\n}\n\n.delete-date {\n  font-size: 26px;\n  color: #707070;\n}\n\n.to-date {\n  padding-left: 10px;\n  padding-right: 5px;\n\n  .form-fields-width {\n    width: 88%;\n    font-size: 15px;\n    padding: 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
