export class OrgPreference {
    name: string;
    displayName: string;
    description: string;
    regex: string;
    value: any;
    datatype: string;

    constructor(object?: any) {
        this.name = object.name;
        this.displayName = object.displayName;
        this.description = object.description;
        this.regex = object.regex;
        this.value = object.value;
        this.datatype = object.datatype;

        this.value = this.datatype === 'checkbox' ? ((this.value === true || this.value === 'true') ?  true : false) : this.value;
    }
}
