import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { CallRecord } from '../shared/models/callRecord';
import { DashboardService } from './dashboard.service';
import { SharedService } from '../shared/shared.service';
import { Alerts } from '../shared/enums/alerts';
import * as moment from 'moment';
import 'moment-timezone';
import {MapInfoWindow, MapMarker} from "@angular/google-maps";
import { MatDialog } from '@angular/material/dialog';

declare let google: any;

enum ViewMode {
    Location = 'Location',
    History = 'History',
}
@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    providers: [DashboardService]
})
export class DashboardComponent implements OnInit {

    previousMarkerPath: string = '/assets/img/mm_20_gray.png';
    redAlarmSymbolPath: string = '/assets/img/alarm-40-red.png';
    grayAlarmSymbolPath: string = '/assets/img/alarm-25-gray.png';
    redCarSymbolPath: string = '/assets/img/car-40-red.png';
    grayCarSymbolPath: string = '/assets/img/car-25-gray.png';
    orgId: string = "";
    viewMode: ViewMode = ViewMode.Location;
    showHistory: boolean;
    showLatest: boolean = true;
    showDetails: boolean;
    maxRecords: number = 10;

    map: google.maps.Map;
    mapCenter: google.maps.LatLngLiteral;
    mapZoom: number = 5;
    bounds: any = new google.maps.LatLngBounds();
    
    geoJsonObject: Object;
    callData: Map<string, Array<CallRecord>> = new Map();
    latestCallLocation: CallRecord;

    eventSource: any;

    constructor(private dashboardService: DashboardService,
                private sharedService: SharedService,
                private dialog: MatDialog,) {

    }

    ngOnInit() {
        this.sharedService.currentOrgId$.subscribe(data => {
            if(this.orgId !== data?.orgId && data.navigate === 'dashboard') {
                if (data?.orgId) {
                    this.orgId = data.orgId;
                    this.fetchOrgBoundaries(data.orgId);
                } else {
                    this.fetchOrgBoundaries();
                }
            }
        });
        this.mapCenter =  {         // current coordinates and passing into
            lat: 38.0,              // current location
            lng: 97.0,
        };
        this.showHistory = false;
        this.showLatest = true;
    }

    addPoints(callRecords : Array<CallRecord>) {
        let points = []
        callRecords.forEach( callRecord => {
            points.push(new google.maps.LatLng(callRecord.location.latitude, callRecord.location.longitude))
        })
        return points;
    }

    ngOnDestroy() {
        this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].clear();
        this.map.controls[google.maps.ControlPosition.TOP_CENTER].clear();
    }

    onMapReady(map) {
        this.map = map;
        this.map.setCenter({lat:38.0, lng:-97});
        this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(document.getElementById('mapCenter'));
        this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(document.getElementById('mapViewMode'));
    }

    centerMap() {
        this.map.fitBounds(this.bounds, 50);
    }

    setLocationMode() {
        this.viewMode = ViewMode.Location;
        this.centerMap();
        if(this.latestCallLocation && this.callData.has(this.latestCallLocation.tn)) {
            let length = this.callData.get(this.latestCallLocation.tn).length;
            this.latestCallLocation = this.callData.get(this.latestCallLocation.tn)[length-1];
        }
        this.showHistory = false;
        this.showLatest = true;
    }

    setHistoryMode() {
        this.viewMode = ViewMode.History;
        this.showHistory = true;
        this.showLatest = false;
        this.centerMap();
    }

    setCenter() {
        switch (this.viewMode) {
            case ViewMode.Location:
                this.setLocationMode();
                break;
            case ViewMode.History:
                this.setHistoryMode();
                break;
            default:
                this.setLocationMode();
                break;
        }
    }

    onViewModeToggle(toggle: any) {
        this.viewMode = toggle.value;
        this.setCenter();
    }

    clickedMarker(callRecord: CallRecord) {
        this.latestCallLocation = callRecord;
        this.showDetails = true;
        if(this.showHistory) {
            this.showLatest = false;
        }
    }

    getMarkerIcon(callLocation: CallRecord, isLatest: boolean) {
        if (isLatest) {
            return callLocation.locationType !== 'IOT' ? '' : 
                (callLocation.searchType === 'Telematics' ? this.redCarSymbolPath : this.redAlarmSymbolPath);
        }
        return callLocation.locationType !== 'IOT' ? this.previousMarkerPath : 
            (callLocation.searchType === 'Telematics' ? this.grayCarSymbolPath : this.grayAlarmSymbolPath);
    }

    onMouseOver(marker: MapMarker, infoWindow: MapInfoWindow) {
        infoWindow.open(marker);
    }

    onMouseOut(infoWindow: MapInfoWindow) {
        infoWindow.close();
    }

    getInnerHtml(callLocation: CallRecord) {
        let response = ""
        if (callLocation.tn) {
            response = response.concat("<span>Telephone: " + callLocation.tn + "</span><br>")
        }
        if (callLocation.imei) {
            response = response.concat("<span>IMEI: " + callLocation.imei + " </span><br>");
        }
        response = response.concat("<span>Time: " + this.formatDate(callLocation.locationTime) + "</span><br>");
        response = response.concat("<span>Latitude: " + callLocation.location.latitude + "</span><br>");
        response = response.concat("<span>Longitude: " + callLocation.location.longitude + "</span><br>");
        response = response.concat("<span>Sequence: " +callLocation.sequence + "</span>");
        return response;
    }

    formatPhone(number: string): string {
        return '(' + number.substring(0, 3) + ') ' + number.substring(3, 6) + '-' + number.substring(6, 10);
    }

    formatDate(date: any): string {
        return new Date(date).toLocaleString() + " " + moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).zoneAbbr();
    }

    formatCoreDate(date: any, provider: string): string {
        return (provider === "Apple" ?
                new Date(parseFloat(date)*1000).toLocaleString() : new Date(parseFloat(date)).toLocaleString()) +
                " " + moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).zoneAbbr();
    }

    styleFunc() {
        return ({
            clickable: false,
            fillColor: "#A0A070",
            fillOpacity: 0.12,
            strokeColor: "black",
            strokeWeight: 0.5
        });
    }

    fetchOrgBoundaries(orgId = "") {
        this.clearMapData();
        this.dashboardService.fetchOrgBoundaries(orgId).subscribe({
            next: data => {
                this.geoJsonObject = {
                    "type": "FeatureCollection",
                    "features": [
                        {
                            "type": "Feature",
                            "geometry": data
                        }
                    ]
                };

                this.map.data.addGeoJson(this.geoJsonObject);
                this.map.data.setStyle(this.styleFunc());
                this.adjustBounds(data);

                this.fetchDataForOrg();
            },
            error: error => {
                this.sharedService.showAlert(Alerts.ERROR, error.error.message);
            },
            complete: () => {}
        });
    }

    adjustBounds(data: any) {
        if(data.type.toLowerCase() === 'multipolygon') {
            data.coordinates.forEach(multipolygon => {
                multipolygon.forEach(polygon => {
                    polygon.forEach(coordinates => {
                        this.bounds.extend(new google.maps.LatLng(+coordinates[1], +coordinates[0]));
                    });
                });
            });
        } else if(data.type.toLowerCase() === 'polygon') {
            data.coordinates.forEach(polygon => {
                polygon.forEach(coordinates => {
                    this.bounds.extend(new google.maps.LatLng(+coordinates[1], +coordinates[0]));
                });
            });
        }
        this.map.fitBounds(this.bounds);
    }

    clearMapData() {
        this.geoJsonObject = null;
        this.dashboardService.closeEventSource();
        this.callData.clear();
        this.bounds = new google.maps.LatLngBounds();
        if(this.map) {
            this.map.setCenter({lat: 38.0, lng: -97});
            this.map.setZoom(5);
            this.clearGeofenceDataFromMap();
        }
    }

    clearGeofenceDataFromMap() {
        this.map.data.setStyle({});
        this.map.data.forEach(feature => {
            this.map.data.remove(feature);
        });
    }

    fetchDataForOrg() {
        this.eventSource?.close();
        this.eventSource = this.dashboardService.fetchDataForOrg(this.orgId, (data) => {
            let callRecord = new CallRecord(data.data);
            if(data.status === "DELETE") {
                this.callData.delete(callRecord.tn ? callRecord.tn : callRecord.imei);
                if(this.latestCallLocation && (this.latestCallLocation.tn === callRecord.tn || this.latestCallLocation.imei === callRecord.imei)) {
                    this.latestCallLocation = null;
                }
            } else if(data.status === "ADD" || data.status === "UPDATE") {
                let key = callRecord.tn ? callRecord.tn : callRecord.imei;
                if(this.callData.has(key)) {
                    let callRecords = this.callData.get(key);
                    let length = callRecords.length;
                    let currentSequenceNumber = callRecords[length-1].sequence + 1;
                    callRecord.sequence = currentSequenceNumber;
                    if(length >= this.maxRecords) {
                        callRecords.splice(0,1);
                    }
                    callRecords.push(callRecord);
                    this.callData.set(key, callRecords);
                } else {
                    callRecord.sequence = 1;
                    let callRecords: Array<CallRecord> = new Array();
                    callRecords.push(callRecord);
                    this.callData.set(key, callRecords);
                }

                if(this.showLatest && this.latestCallLocation && 
                    ((this.latestCallLocation.tn && callRecord.tn && this.latestCallLocation.tn === callRecord.tn) || 
                        (this.latestCallLocation.imei && callRecord.imei && this.latestCallLocation.imei === callRecord.imei))) {
                    this.latestCallLocation = callRecord;
                }

            }
        });
    }

    decode(content: string): string{
        return decodeURIComponent(content);
    }

    openDialogWithTemplateRef(templateRef: TemplateRef<any>) {
        this.dialog.open(templateRef);
    }

    cancel() {
        this.dialog.closeAll();  
    }

    formatCivicAddress(civicAddress):string {
        if (civicAddress) {
            let address = "";
            if (civicAddress.hno?.value) { 
                address = address + ' ' + civicAddress.hno.value;
            } else if (civicAddress.HNO){
                address = address + ' ' + civicAddress.HNO;            
            }
            if (civicAddress.hns?.value) { 
                address = address + ' ' + civicAddress.hns.value;         
            } else if (civicAddress.HNS){
                address = address + ' ' + civicAddress.HNS;            
            }
            if (civicAddress.rd?.value) { 
                address = address + ' ' + civicAddress.rd.value;       
            } else if (civicAddress.RD){
                address = address + ' ' + civicAddress.RD;            
            }
            if (civicAddress.sts?.value) { 
                address = address + ' ' + civicAddress.sts.value;         
            } else if (civicAddress.STS){
                address = address + ' ' + civicAddress.STS;            
            }
            if (civicAddress.pod?.value) { 
                address = address + ' ' + civicAddress.pod.value;        
            } else if (civicAddress.POD){
                address = address + ' ' + civicAddress.POD;            
            }
            if (civicAddress.unit?.value) { 
                address = address + ' ' + civicAddress.unit.value;           
            } else if (civicAddress.UNIT){
                address = address + ' ' + civicAddress.UNIT;            
            }
            if (civicAddress.loc?.value) { 
                address = address + ' ' + civicAddress.loc.value;       
            } else if (civicAddress.LOC){
                address = address + ' ' + civicAddress.LOC;            
            }
            address = address.trim() + '\r\n';            
            if (civicAddress.a3?.value) { 
                address = address + ' ' + civicAddress.a3.value;        
            } else if (civicAddress.A3){
                address = address + ' ' + civicAddress.A3;            
            }            
            if (civicAddress.a1?.value) { 
                address = address + ' ' + civicAddress.a1.value;          
            } else if (civicAddress.A1){
                address = address + ' ' + civicAddress.A1;            
            }
            if (civicAddress.pc?.value) {
                address = address + ' ' + civicAddress.pc.value;          
            } else if (civicAddress.PC){
                address = address + ' ' + civicAddress.PC;            
            }
            return address.trim();
        }
    }

}
