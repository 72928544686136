// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
}

.error-page {
  position: absolute;
  top: 10%;
  left: 17%;
  right: 17%;
  bottom: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}

.error-page .content {
  max-width: 600px;
  text-align: center;
}

.content h2 {
  font-size: 8vw;
  color: #000;
  max-width: 600px;
}

.content h4 {
  font-size: 2em;
  margin-bottom: 20px;
  text-transform: uppercase;
  color: #000;
  max-width: 600px;
}

.content p {
  font-size: 1.2em;
  color: #0d0d0d;
}`, "",{"version":3,"sources":["webpack://./src/app/pagenotfound/pagenotfound.component.scss"],"names":[],"mappings":"AAAA;EACI,SAAA;EACA,UAAA;EACA,aAAA;EACA,sBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,QAAA;EACA,SAAA;EACA,UAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;EACA,2CAAA;AACJ;;AAEA;EACI,gBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,cAAA;EACA,WAAA;EACA,gBAAA;AACJ;;AAEA;EACI,cAAA;EACA,mBAAA;EACA,yBAAA;EACA,WAAA;EACA,gBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,cAAA;AACJ","sourcesContent":["* {\n    margin: 0;\n    padding: 0;\n    outline: none;\n    box-sizing: border-box;\n}\n\n.error-page {\n    position: absolute;\n    top: 10%;\n    left: 17%;\n    right: 17%;\n    bottom: 20%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background: #fff;\n    box-shadow: 0px 5px 10px rgba(0,0,0,0.1) ;\n}\n\n.error-page .content {\n    max-width: 600px;\n    text-align: center;\n}\n\n.content h2 {\n    font-size: 8vw;\n    color: #000;\n    max-width: 600px;\n}\n\n.content h4 {\n    font-size: 2em;\n    margin-bottom: 20px;\n    text-transform: uppercase;\n    color: #000;\n    max-width: 600px;\n}\n\n.content p {\n    font-size: 1.2em;\n    color: #0d0d0d;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
