import {Component, OnInit} from '@angular/core';
import {VersionCheckService} from './_helpers/version-check.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {


    constructor(private versionService: VersionCheckService) {}

    ngOnInit() {
        this.versionService.initVersionCheck();
    }

    ngOnDestroy(): void {
        
    }

}
