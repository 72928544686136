import { Component, ComponentRef, TemplateRef, OnChanges, Input, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { BrokerSearch} from '../../shared/models/brokerSearch';
import { PowerSearchService } from '../power-search.service';
import { DatePipe} from '@angular/common';
import { MatDialog, MatDialogModule, MatDialogConfig} from '@angular/material/dialog';
import { EmailReportComponent } from 'src/app/email-report/email-report.component';
import { OrgEntitlementsEnum } from 'src/app/shared/enums/org-entitlements.enum';
import { HomeService} from '../../home/home.service';
import { SearchTypesService } from 'src/app/search-types/search-type.service';
import { SharedService } from 'src/app/shared/shared.service';
import { Alerts } from 'src/app/shared/enums/alerts';
import { MapHideMarkersComponent } from 'src/app/map-hide-markers/map-hide-markers.component';

import { BrowserModule } from '@angular/platform-browser';
import { ViewContainerRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { Compiler } from '@angular/core';
import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { PdDialogComponent } from 'src/app/pd-dialog/pd-dialog.component';

import * as moment from 'moment';
import 'moment-timezone';

import Swal from 'sweetalert2/dist/sweetalert2.js';
import {GoogleMapsModule, MapInfoWindow, MapMarker} from "@angular/google-maps";
import { SafeUrlPipe } from 'src/app/shared/safe-url-pipe';

declare let google: any;

@Component({
    selector: 'app-generic-results',
    templateUrl: './generic-results.component.html'
})
export class GenericResultsComponent implements OnChanges  {
    @Input() brokerSearch: BrokerSearch;
    @Input() resultList: BrokerSearch[];
    @Input() securityWord: string;
    @ViewChild('genericResultDisplay', { read: ViewContainerRef }) genericResultDisplay: ViewContainerRef;
    selectedResult: any;
    additionalData: any = {};
    currentLocation: any;
    updatedLocationLatitude: number;
    updatedLocationLongitude: number;

    map: google.maps.Map;
    mapCenter: google.maps.LatLngLiteral;
    mapZoom: number;
    mapType: String;

    showEmailResultIcon : boolean = false;
    cmpRef: ComponentRef<any>;
    uiTemplate: string;
    latPath;
    lngPath;
    resultTimePath: String;
    resultNamePath: String;
    resultNameDetailsPath: String;
    additionDataEventSource: any;
    displayMap: boolean = false;
    defaultMapZoom: number;
    defaultMapType: String;
    defaultResultLoad: boolean = false;

    constructor(private homeService : HomeService,
                private compiler: Compiler,
                private searchTypesService: SearchTypesService,
                private powerSearchService: PowerSearchService,
                public sharedService: SharedService) {
    }

    ngOnChanges() {
        this.initialize();
        this.checkShareResultEntitlement();
    }

    initialize() {
        
        this.selectedResult = null;
        this.uiTemplate = null;
        this.additionalData.results = [];
        this.additionDataEventSource?.close();
        this.resultTimePath = null;
        this.resultNamePath = null;
        this.resultNameDetailsPath = null;
	
        if (this.brokerSearch) {
            //Verify if there is an existing UI Template        
            this.searchTypesService.getSearchType(this.brokerSearch.searchType).subscribe(
                searchTypeData => {

                    this.searchTypesService.getUITemplate(searchTypeData.name).subscribe({
                        next: uiTemplate => {
                            this.uiTemplate = uiTemplate;
                            this.setSearchResults(searchTypeData);
                        },
                        error: error => {
                            this.sharedService.showAlert(Alerts.ERROR, "Failed to load UI template");
                            this.setSearchResults(searchTypeData);
                        },
                        complete: () => { }
                    });

                }
            );
        }
    }

    setSearchResults(searchTypeData: any) {
        if (this.brokerSearch.searchResult) {
            this.defaultResultLoad = true;
            let index: number = -1;
            if (this.brokerSearch.searchResult.results) {
                index = this.brokerSearch.searchResult.results.findIndex(
                    result => (result.shortWebLink && this.securityWord && result.shortWebLink.includes(this.securityWord))
                );
                if(index !== -1) {
                    this.selectedResult = this.brokerSearch.searchResult.results[index];
                } else {
                    this.selectedResult = this.brokerSearch.searchResult.results[0];
                }                      
            } else {
                this.selectedResult = this.brokerSearch.searchResult;
            }

            if (searchTypeData) {
                searchTypeData.displayAttributeList?.forEach( attribute => {
                    if (attribute.name === 'latitudePath') {
                        this.latPath = attribute.value;
                    } else if (attribute.name === 'longitudePath') {
                        this.lngPath = attribute.value;
                    } else if (attribute.name === 'resultTitlePath') {
                        this.resultNamePath = attribute.value;
                    } else if (attribute.name === 'resultTitleDetailsPath') {
                        this.resultNameDetailsPath = attribute.value;
                    } else if (attribute.name === 'resultTimePath') {
                        this.resultTimePath = attribute.value;
                    } else if (attribute.name === 'mapZoom') {
                        this.defaultMapZoom = +attribute.value;
                    } else if (attribute.name === 'mapType') {
                        this.mapType = attribute.value;
                    }
                });
            }

            let lat = this.getDataFromPath(this.latPath, this.selectedResult);
            let lng = this.getDataFromPath(this.lngPath, this.selectedResult);
            if (lat && lng) { 
                lat = parseFloat(lat);
                lng = parseFloat(lng);
                this.displayMap = true;
                this.updatedLocationLatitude = lat;
                this.updatedLocationLongitude = lng;
                this.mapZoom = this.defaultMapZoom;
                this.mapCenter = {lat: +lat, lng: +lng}; 
            }

            if (this.selectedResult?.initialLocation) {
                this.currentLocation = this.selectedResult.initialLocation;
            }
        }

        if (!this.uiTemplate) {
            this.uiTemplate = 
                `<div style="margin: 15px 16% 0px 16%;">
                    <div class="col-12 mt-3">
                        <mat-card class="mat-card-dark" style="margin: 0 20px;">
                        <div class="row col-12" style="margin:0; padding:0; font-size: 16px;">
                            <div class="col-1" style="padding: 0;">
                                <i style="text-align:center;display:block;color:rgb(255, 255, 255)" class="fas fa-search-location fa-2x"></i>
                            </div>
                            <div class="col-10" style="padding-right: 0;">
                                <h5 style="color:white;padding-left: 0px;">
                                    {{brokerSearch.searchType}}
                                    <mat-icon class="ml-1" *ngIf="brokerSearch.sensitiveData" 
                                            style="color:white;font-size: 16px;"
                                            matTooltip="Sensitive Data">lock</mat-icon>
                                </h5>
                                <div class="row col-11" style="margin:0; padding: 0;" *ngIf="resultList.length > 0">
                                <mat-select id="resultDropdown" class="mat-select-dark" [(value)]="brokerSearch">
                                    <mat-option *ngFor="let result of resultList" 
                                                (onSelectionChange)="onSearchChange($event)" [value]="result">
                                        {{formatDate(result.timestamp, true)}}
                                    </mat-option>
                                </mat-select>
                                </div>
                            </div>  
                            <div class="col-1" *ngIf="brokerSearch.dataFound" style="padding:0;">
                                <i class="fas fa-check fa-lg" style="color: rgb(42, 143, 42);"></i>
                            </div>
                            <div class="col-1" *ngIf="!brokerSearch.dataFound && !brokerSearch.errorFound" style="padding:0;">
                                <i class="fas fa-check fa-lg" style="color: gray;"></i>
                            </div>
                            <div class="col-1" *ngIf="brokerSearch.errorFound" style="padding:0;">
                                <i class="fas fa-exclamation-circle fa-lg" style="color: rgb(134, 41, 41);"></i>
                            </div>
                        </div>  
                        </mat-card>
                    </div>
                    <div class="col-12 mt-3" *ngIf="brokerSearch.searchResult.results">
                        <mat-card class="mat-card-light" style="margin: 0 20px;">
                        <div class="col-12" style="margin:0; font-size: 16px;">
                            <mat-select id="eventDropdown" class="mat-select-light" [(value)]="selectedResult" style="color: black !important;"
                                        matTooltip="{{getDisplayName(selectedResult, false)}}"
                                        [ngStyle]="{'font-weight': (selectedResult && selectedResult.shortWebLink && securityWord && selectedResult.shortWebLink.includes(securityWord)) ? 
                                                        'bolder':'normal'}">
                                <ng-container *ngIf="brokerSearch.searchResult.results">
                                    <mat-option *ngFor="let result of brokerSearch.searchResult.results" 
                                                (onSelectionChange)="onResultChange($event)" [value]="result"
                                                matTooltip="{{showDetails(result)}}" style="color: black !important;"
                                                [ngStyle]="{'font-weight': (result.shortWebLink && securityWord && result.shortWebLink.includes(securityWord)) ? 'bolder':'normal'}">
                                        <ng-container *ngIf="result.status === 'IN_PROGRESS'; then greenDot; else redDot"></ng-container>&nbsp;
                                        <ng-template #greenDot><i class="fas fa-circle fa-xs" style="color:rgb(94, 179, 86)"></i></ng-template>
                                        <ng-template #redDot><i class="fas fa-circle fa-xs" style="color:rgba(255, 0, 0, 0.801)"></i></ng-template> 
                                        {{getDisplayName(result, true)}}
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </div>
                        </mat-card>
                    </div>
                    <div class="col-12 mt-3">
                        <mat-card class="mat-card-light" style="margin: 0 20px; padding:30px">
                            <div class="row" style="margin:0;">
                                <div class="col-12 pb-2" style="border-bottom-style:solid; border-width: 1px; border-color: gray;">
                                    <span style="float:left; font-size: 16px; font-weight: normal;">
                                        <h5>Search Criteria:</h5>
                                        <span *ngIf="brokerSearch.searchRequest.phone" style="display: block;">
                                            {{formatPhone(brokerSearch.searchRequest.phone)}}
                                        </span>
                                        <span *ngIf="brokerSearch.searchRequest.location" style="display: block;">
                                            {{brokerSearch.searchRequest.location.latitude}}, {{brokerSearch.searchRequest.location.longitude}}, {{brokerSearch.searchRequest.location.altitude}}
                                        </span>
                                        <span *ngIf="brokerSearch.searchRequest.address" style="display: block;">
                                            {{formatCivicAddress(brokerSearch.searchRequest.address)}}
                                        </span>
                                    </span>
                                    <span *ngIf="brokerSearch.dataFound" style="float:right;">
                                        <button *ngIf="showEmailResultIcon" (click)="EmailStaticReport()"
                                                class="btn btn-link action-button float-right"
                                                style="font-size: 20px; padding: 0px 10px; border: #582d82 solid 2px;"
                                                matTooltip="Email Search Results">
                                            <i class="fas fa-envelope" style="color:#00677F;"></i>
                                        </button>
                                    </span>
                                </div>
                                <div class="col-12 mt-2" *ngIf="!brokerSearch.dataFound">
                                    <span *ngIf="!brokerSearch.dataFound && !brokerSearch.errorFound" style="float:left; font-size: 16px; font-weight:normal">
                                        No data found
                                    </span>
                                    <span *ngIf="brokerSearch.errorFound" style="float:left; font-size: 16px; font-weight:normal">
                                        Error: {{brokerSearch.errorText}}
                                    </span>
                                </div>
                                <div class="col-12 mt-3" *ngIf="brokerSearch.dataFound" style="display:inline-grid;">
                                    <h5>Search Result</h5>
                                    <ngx-json-viewer [json]="selectedResult" style="overflow:auto"></ngx-json-viewer>
                                    <br>
                                    <div *ngIf="brokerSearch.additionalDataAvailable">
                                        <h5>Additional Data</h5>
                                        <ngx-json-viewer [json]="additionalData"></ngx-json-viewer>
                                    </div>
                                </div>
                            </div>
                            
                        </mat-card>
                    </div>
                </div>`;
        }

        this.generateResultDisplay();
    }
    
    ngOnDestroy() {
        this.additionDataEventSource?.close();
        this.genericResultDisplay?.clear();
    }

    getDataFromPath(path, result) {
        var data_type = path.slice(0, path.indexOf("."));
        if (data_type=="searchResult") {
            return this.jsonPathToValue(result, path.slice(path.indexOf(".") + 1));
        } else if (data_type=="searchRequest") {
            return this.jsonPathToValue(this.brokerSearch.searchRequest, path.slice(path.indexOf(".") + 1));
        }
    }

    jsonPathToValue(jsonData, path) {
        if (!(jsonData instanceof Object) || typeof (path) === "undefined") {
            throw "Not valid argument:jsonData:" + jsonData + ", path:" + path;
        }
        path = path.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
        path = path.replace(/^\./, ''); // strip a leading dot
        var pathArray = path.split('.');
        for (var i = 0, n = pathArray.length; i < n; ++i) {
            var key = pathArray[i];
            if (key in jsonData) {
                if (jsonData[key] !== null) {
                    jsonData = jsonData[key];
                } else {
                    return null;
                }
            } else {
                return null;
            }
        }
        return jsonData;
    } 

    checkShareResultEntitlement() {
        this.showEmailResultIcon = false;
        if(this.brokerSearch.sensitiveData) {
            this.showEmailResultIcon = false;
        } else if (this.homeService.userDetails && this.homeService.userDetails.org.clientId) {
            this.homeService.userDetails.org.entitlements.forEach( entitlement => {
                if (entitlement === OrgEntitlementsEnum.SHARE_RESULTS) {
                    this.showEmailResultIcon = true;
                }
            });
        }
    }
    
    private generateResultDisplay() {

        //Declare dynamic component
        @Component({
            template: this.uiTemplate
        })
        class GenericResultDisplayComponent {

            public previousIconUrl = {
                url : '/assets/img/GrayDot.png',
                anchor: new google.maps.Point(10,10)
            };
            map: google.maps.Map;

            constructor(public _parent: GenericResultsComponent,
                        private dialog: MatDialog,
                        private datepipe: DatePipe) {
            }

            onSearchChange(event: any){
                if (event.source.selected && this._parent.brokerSearch.uniqueSearchId !== event.source.value.uniqueSearchId) {
                    let searchResult = event.source.value;
                    if(searchResult.sensitiveData && !searchResult.sensitiveDataSearchDone) {
                        this._parent.additionDataEventSource?.close();
                        this.doSensitiveDataSearch(searchResult);
                    } else {
                        this._parent.additionDataEventSource?.close();
                        this._parent.brokerSearch = searchResult;
                        this._parent.setSearchResults(null);
                    }
                }
            }

            doSensitiveDataSearch(searchData: BrokerSearch) {
                Swal.fire({
                    title: 'Please Wait...',
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.showLoading()
                    }
                });
                this._parent.powerSearchService.sensitiveDataSearchForId(searchData.uniqueSearchId).subscribe({
                    next: data => {
                        Swal.close();
                        this.showSensitiveResult(searchData, data);
                    },
                    error: error => {
                        Swal.close();
                        this._parent.sharedService.showAlert(Alerts.ERROR, error.error);
                    },
                    complete: () => { }
                });
            }
        
            showSensitiveResult(searchData: BrokerSearch, sensitiveData: any) {
                let bs = new BrokerSearch().deserialize(sensitiveData);
                bs.sensitiveDataSearchDone = true;
                searchData = bs;
                let index = this._parent.resultList.findIndex(
                    result => (result.uniqueSearchId === bs.uniqueSearchId)
                );
                if(index !== -1) {
                    this._parent.resultList[index] = searchData;
                }
                this._parent.brokerSearch = searchData;
                this._parent.setSearchResults(null);
            }
            
            onResultChange(event){
                if (event.source.selected && (this._parent.defaultResultLoad || this._parent.selectedResult !== event.source.value)) {
                    this._parent.selectedResult = event.source.value;
                    this._parent.defaultResultLoad = false;
        
                    if(this._parent.displayMap) {
                        let lat = this._parent.getDataFromPath(this._parent.latPath, this._parent.selectedResult);
                        let lng = this._parent.getDataFromPath(this._parent.lngPath, this._parent.selectedResult);
                        if (lat && lng) { 
                            lat = parseFloat(lat);
                            lng = parseFloat(lng);
                            this._parent.updatedLocationLatitude = lat;
                            this._parent.updatedLocationLongitude = lng;
                            this._parent.mapZoom = this._parent.defaultMapZoom;
                            this._parent.mapCenter = {lat: +lat, lng: +lng}; 
                        } 
                    }
        
                    if (this._parent.selectedResult?.initialLocation) {
                        this._parent.currentLocation = this._parent.selectedResult.initialLocation;
                    }
        
                    if (this._parent.brokerSearch.additionalDataAvailable) {
                        let index: number = -1;
                        if (this._parent.brokerSearch.searchResult.results) {
                            index = this._parent.brokerSearch.searchResult.results.findIndex(
                                result => (JSON.stringify(this._parent.selectedResult) === JSON.stringify(result))
                            );                            
                        }
                        this._parent.additionalData.results = [];
                        this._parent.additionDataEventSource?.close();
                        this._parent.additionDataEventSource = this._parent.powerSearchService.additionalDataSearch(this._parent.brokerSearch.uniqueSearchId, index, result => { 
                            if(result.eventStatus) {
                                this._parent.selectedResult.status = result.eventStatus;
                            }
                            this._parent.additionalData.results.push(result);

                            //Update display
                            this._parent.generateResultDisplay();
                        });
                    }
                }
            }
        
            onMapReady(map) {
                this._parent.map = map;
                this.map = this._parent.map;
                this._parent.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(document.getElementById('mapCenter'));
            } 
        
            showDetails(result) {
                let displayName: string = "";
                if(result.shortWebLink) {
                    displayName = result.shortWebLink.substring(result.shortWebLink.lastIndexOf("/")+1, result.shortWebLink.length);
                }
                if (this._parent.resultNameDetailsPath) {
                    if(displayName) {
                        displayName += ' - ';
                    }
                    displayName += this._parent.getDataFromPath(this._parent.resultNameDetailsPath, result);
                }
                if (this._parent.resultNamePath) {
                    if(displayName) {
                        displayName += ' - ';
                    }           
                    displayName += this._parent.getDataFromPath(this._parent.resultNamePath, result);
                }
                return displayName;
            }
        
            getDisplayName(result, showLess) {
                let displayName: string;
                if (this._parent.resultNamePath) {
                    displayName = this._parent.getDataFromPath(this._parent.resultNamePath, result);
                    if(displayName.length > 15 && showLess) {
                        displayName = displayName.substring(0, 15) + "...";
                    }
                    if (this._parent.resultTimePath) {
                        displayName += ' - ' + this.formatTime(this._parent.getDataFromPath(this._parent.resultTimePath, result));
                    }
                    return displayName;
                }
                return this._parent.brokerSearch.provider;
            }

            EmailStaticReport() {
                const dialogConfig = new MatDialogConfig();
                dialogConfig.autoFocus = true;
                dialogConfig.data = {
                    searchResultId: String(this._parent.brokerSearch.uniqueSearchId),
                    linkOnly: true
                };
                this.dialog.open(EmailReportComponent, dialogConfig);
            }

            openDialogWithTemplateRef(templateRef: TemplateRef<any>) {
                this.dialog.open(templateRef);
            }

            cancel() {
                this.dialog.closeAll();  
            }

            formatTime(time) {
                if (time.$numberLong) {
                    var date = new Date(parseInt(time.$numberLong));
                    return this.datepipe.transform(date, 'MM/dd/yyyy hh:mm:ss a');
                } else if (time && time != "Invalid Date") {
                    var date = new Date(time);
                    return this.datepipe.transform(date, 'MM/dd/yyyy hh:mm:ss a');
                } else {
                    return 'XX/XX/XX';
                }
            }

            formatLatLng(latLng) {
                return parseFloat(latLng);
            }

            parseToInt(data) {
                return parseInt(data);
            }

            parseToFloat(data) {
                return parseFloat(data);
            }

            formatDate(date: any, showTimezone: boolean): string {
                return this.datepipe.transform(new Date(date), 'MM/dd/yyyy hh:mm:ss a') + (showTimezone ? " " + moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).zoneAbbr() : "");
            }
            
            formatCivicAddress(civicAddress):string {
                if (civicAddress) {
                    let address = "";
                    if (civicAddress.hno?.value) { 
                        address = address + ' ' + civicAddress.hno.value;
                    } else if (civicAddress.HNO){
                        address = address + ' ' + civicAddress.HNO;            
                    }
                    if (civicAddress.hns?.value) { 
                        address = address + ' ' + civicAddress.hns.value;         
                    } else if (civicAddress.HNS){
                        address = address + ' ' + civicAddress.HNS;            
                    }
                    if (civicAddress.rd?.value) { 
                        address = address + ' ' + civicAddress.rd.value;       
                    } else if (civicAddress.RD){
                        address = address + ' ' + civicAddress.RD;            
                    }
                    if (civicAddress.sts?.value) { 
                        address = address + ' ' + civicAddress.sts.value;         
                    } else if (civicAddress.STS){
                        address = address + ' ' + civicAddress.STS;            
                    }
                    if (civicAddress.pod?.value) { 
                        address = address + ' ' + civicAddress.pod.value;        
                    } else if (civicAddress.POD){
                        address = address + ' ' + civicAddress.POD;            
                    }
                    if (civicAddress.unit?.value) { 
                        address = address + ' ' + civicAddress.unit.value;           
                    } else if (civicAddress.UNIT){
                        address = address + ' ' + civicAddress.UNIT;            
                    }
                    if (civicAddress.loc?.value) { 
                        address = address + ' ' + civicAddress.loc.value;       
                    } else if (civicAddress.LOC){
                        address = address + ' ' + civicAddress.LOC;            
                    }
                    address = address.trim() + '\r\n';            
                    if (civicAddress.a3?.value) { 
                        address = address + ' ' + civicAddress.a3.value;        
                    } else if (civicAddress.A3){
                        address = address + ' ' + civicAddress.A3;            
                    }            
                    if (civicAddress.a1?.value) { 
                        address = address + ' ' + civicAddress.a1.value;          
                    } else if (civicAddress.A1){
                        address = address + ' ' + civicAddress.A1;            
                    }
                    if (civicAddress.pc?.value) {
                        address = address + ' ' + civicAddress.pc.value;          
                    } else if (civicAddress.PC){
                        address = address + ' ' + civicAddress.PC;            
                    }
                    return address.trim();
                }
            }

            formatPhone(number: string): string {
                let formattedNumber = number.replace("+","").trim();
                if(formattedNumber.startsWith("1")) {
                    return '1 (' + formattedNumber.substring(1, 4) + ') ' + formattedNumber.substring(4, 7) + '-' + formattedNumber.substring(7, 11);
                } else {
                    return '(' + formattedNumber.substring(0, 3) + ') ' + formattedNumber.substring(3, 6) + '-' + formattedNumber.substring(6, 10);
                }
            }

            filterAdditionalDataType(type){
                if(this._parent.additionalData?.results) {
                    return this._parent.additionalData.results.filter(x => x[type] != null);
                } else {
                    return [];
                }
            }

            addPoints(lat, lng, pointArray) {
                let points = []
                if(lat && lng) {
                    points.push(new google.maps.LatLng(lat,lng));
                }
                pointArray.forEach( point => {
                    points.push(new google.maps.LatLng(point.locationUpdate.geoLocation.latitude, point.locationUpdate.geoLocation.longitude))
                })
                return points;
            }

            onMouseOver(marker: MapMarker, infoWindow: MapInfoWindow) {
                infoWindow.open(marker);
            }
        
            onMouseOut(infoWindow: MapInfoWindow) {
                infoWindow.close();
            }

            centerMap() {
                if(this._parent.updatedLocationLatitude) {
                    this._parent.mapCenter.lat = this._parent.updatedLocationLatitude;
                }
                if(this._parent.updatedLocationLongitude) {
                    this._parent.mapCenter.lng = this._parent.updatedLocationLongitude;
                }
                this._parent.map.setCenter(this._parent.mapCenter);
                this._parent.map.setZoom(this._parent.mapZoom);
            }

            updateMapCenterLat(currentLocation) {
                this._parent.currentLocation = currentLocation;
                this._parent.updatedLocationLatitude = currentLocation.latitude;
                return currentLocation.latitude;
            }

            updateMapCenterLng(currentLocation) {
                this._parent.currentLocation = currentLocation;
                this._parent.updatedLocationLongitude =  currentLocation.longitude;
                return currentLocation.longitude;
            }
            
        }

        //Declare dynamic module
        @NgModule({ 
            imports: [
                GoogleMapsModule,
                BrowserModule,
                MatCardModule,
                MatProgressSpinnerModule,
                MatMenuModule,
                MatIconModule,
                MatToolbarModule,
                MatButtonModule,
                MatFormFieldModule,
                MatInputModule,
                MatSelectModule,
                MatSortModule,
                MatTableModule,
                MatCheckboxModule,
                MatPaginatorModule,
                MatDialogModule,
                MatGridListModule,
                MatProgressBarModule,
                MatSnackBarModule,
                MatListModule,
                MatChipsModule,
                MatTabsModule,
                MatBadgeModule,
                MatAutocompleteModule,
                MatSlideToggleModule,
                MatTooltipModule,
                MatChipsModule,
                MatExpansionModule,
                MatButtonToggleModule,
                MatNativeDateModule,
                MatSidenavModule,
                NgxJsonViewerModule
            ], 
            declarations: [
                GenericResultDisplayComponent,
                MapHideMarkersComponent,
                PdDialogComponent,
                SafeUrlPipe
            ],
            schemas: [CUSTOM_ELEMENTS_SCHEMA],
        })
        class TemplateModule { }

        //Compile module
        this.compiler.compileModuleAndAllComponentsAsync(TemplateModule)
        .then((factories) => {
            //Clear the display
            this.genericResultDisplay?.clear();
            //Destroy the cmpRef
            if(this.cmpRef) {
                this.cmpRef.destroy();
            }
            const f = factories.componentFactories[0];
            this.cmpRef = this.genericResultDisplay?.createComponent(f.componentType);
            this.cmpRef.instance.brokerSearch = this.brokerSearch;
            this.cmpRef.instance.resultList = this.resultList;
            this.cmpRef.instance.selectedResult = this.selectedResult;
            this.cmpRef.instance.currentLocation = this.currentLocation;
            this.cmpRef.instance.additionalData = this.additionalData;
            this.cmpRef.instance.showEmailResultIcon = this.showEmailResultIcon;
            this.cmpRef.instance.mapCenter = this.mapCenter;
            this.cmpRef.instance.map = this.map;
            this.cmpRef.instance.mapZoom = this.mapZoom;
            this.cmpRef.instance.mapType = this.mapType;
            this.cmpRef.instance.displayMap = this.displayMap;
            this.cmpRef.instance.updatedLocationLatitude = this.updatedLocationLatitude;
            this.cmpRef.instance.updatedLocationLongitude = this.updatedLocationLongitude;
        })          
    }

}
