import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {BrokerSearch} from '../../shared/models/brokerSearch';
import { PowerSearchService } from '../power-search.service';
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {EmailReportComponent} from "../../email-report/email-report.component";
import { OrgEntitlementsEnum } from 'src/app/shared/enums/org-entitlements.enum';
import {HomeService} from '../../home/home.service';
import { DBHRecord } from 'src/app/shared/models/dbhRecord';
import * as moment from 'moment';
import 'moment-timezone';
import { DatePipe } from '@angular/common';
import {MapInfoWindow, MapMarker} from "@angular/google-maps";

declare let google: any;

enum ViewMode {
    Location = 'Location',
    History = 'History',
}

@Component({
    selector: 'app-dbh-results',
    templateUrl: './dbh-results.component.html',
    styleUrls: ['./dbh-results.component.scss']
})
export class DbhResultsComponent implements OnInit {
    
    @Input() brokerSearch: BrokerSearch;
    @Input() resultList: BrokerSearch[];    
    selectedBrokerSearchResult: BrokerSearch;
    additionDataEventSource: any;
    viewMode : ViewMode;
    dbhLocationSequence : number;
    latestDbhLocation: DBHRecord;
    additionalDataResults:DBHRecord[] = [];
    points : google.maps.LatLng[] = [];
    showLatestDbhData: boolean = true;
    autoAdjust: boolean = true;

    map: google.maps.Map;
    mapCenter: google.maps.LatLngLiteral;
    mapZoom: number = 5;
    mapType: string;

    previousIconUrl: string = '/assets/img/mm_20_gray.png';
    showEmailResultIcon : boolean = false;
    maxRecordsOnMap : number = 100;

    constructor(private powerSearchService: PowerSearchService,
                private dialog: MatDialog,
                private datepipe: DatePipe,
                private homeService : HomeService) {
    }

    ngOnInit() {
        this.initialize();
        this.checkShareResultEntitlement();
    }

    initialize() {
        this.additionDataEventSource?.close();
        this.selectedBrokerSearchResult = this.brokerSearch;
    }

    onResultChange(event: any){
        if (event.source.selected) {
            this.additionDataEventSource?.close();
            this.selectedBrokerSearchResult = event.source.value;
            this.setDbhLocation();
        }
    }

    setDbhLocation() {
        if (this.selectedBrokerSearchResult?.searchResult) {
            this.dbhLocationSequence = 1;
            this.additionalDataResults = [];
            this.latestDbhLocation = new DBHRecord(this.selectedBrokerSearchResult.searchResult);
            this.latestDbhLocation.sequence = this.dbhLocationSequence;
            this.additionalDataResults.push(this.latestDbhLocation);
            this.dbhLocationSequence++;
            this.mapZoom = 16;
            this.mapCenter = {lat: +this.latestDbhLocation.location.latitude, lng: +this.latestDbhLocation.location.longitude};
            this.mapType = 'roadmap';
            this.viewMode = ViewMode.Location;
            
            setTimeout(() => {
                this.centerMap();
            }, 1000);
            this.getStreamedLocations();
        }
    }

    onMapReady(map) {
        this.map = map;
        this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(document.getElementById('mapCenter'));
        this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(document.getElementById('mapViewMode'));
        this.map.controls[google.maps.ControlPosition.RIGHT_TOP].push(document.getElementById('autoAdjust'));
    }

    centerMap() {
        if(this.latestDbhLocation) {
            this.mapCenter = {lat: +this.latestDbhLocation.location.latitude, lng: +this.latestDbhLocation.location.longitude};
        }
        this.map.setCenter(this.mapCenter);
        this.map.setZoom(this.mapZoom);
    }

    ngOnDestroy() {
        this.additionDataEventSource?.close();
        this.map.controls[google.maps.ControlPosition.TOP_CENTER].clear();
    }

    onMouseOver(marker: MapMarker, infoWindow: MapInfoWindow) {
        infoWindow.open(marker);
    }

    onMouseOut(infoWindow: MapInfoWindow) {
        infoWindow.close();
    }
    
    onMarkerClick(dbhLocation: DBHRecord) {
        if (this.viewMode === ViewMode.History) {
            this.showLatestDbhData = false;
            this.latestDbhLocation = dbhLocation;
        }
    }

    setLocationMode() {
        this.viewMode = ViewMode.Location;
        this.latestDbhLocation = this.additionalDataResults[this.additionalDataResults.length - 1];
        this.showLatestDbhData = true;
        if(this.autoAdjust) {
            this.centerMap();
        }
    }

    setHistoryMode() {
        this.viewMode = ViewMode.History;
        if(this.autoAdjust) {
            if (this.additionalDataResults.length === 1) {
                this.centerMap();
            } else {
                let bonds: google.maps.LatLngBounds = new google.maps.LatLngBounds();
                this.additionalDataResults.forEach(dbhLocation => {
                    bonds.extend(new google.maps.LatLng(+dbhLocation.location.latitude, +dbhLocation.location.longitude));
                });
                this.map.fitBounds(bonds, 100);
            }
        }
    }

    setCenter() {
        switch (this.viewMode) {
            case ViewMode.Location:
                this.setLocationMode();
                break;
            case ViewMode.History:
                this.setHistoryMode();
                break;
            default:
                this.setLocationMode();
                break;
        }
    }

    onViewModeToggle(toggle) {
        this.viewMode = toggle.value;
        this.setCenter();
    }

    changeAutoAdjust(checked) {
        if(checked) {
            this.setCenter();
        }
    }

    checkShareResultEntitlement() {
        this.showEmailResultIcon = false;
        if (this.homeService.userDetails && this.homeService.userDetails.org.clientId) {
            this.homeService.userDetails.org.entitlements.forEach( entitlement => {
                if (entitlement === OrgEntitlementsEnum.SHARE_RESULTS) {
                    this.showEmailResultIcon = true;
                }
            });
        }
    }

    EmailStaticReport() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            searchResultId: String(this.selectedBrokerSearchResult.uniqueSearchId),
            linkOnly: true
        }
        this.dialog.open(EmailReportComponent, dialogConfig);
    }

    formatPhone(number: string): string {
        return '(' + number.substring(0, 3) + ') ' + number.substring(3, 6) + '-' + number.substring(6, 10);
    }

    formatDate(date: any, showTimezone: boolean): string {
        if (date && date !== "Invalid Date") {
            return this.datepipe.transform(new Date(date), 'MM/dd/yyyy hh:mm:ss a') + (showTimezone ? " " + moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).zoneAbbr() : "");
        }
        return "";
    }

    addPoints(dbhRecords : Array<DBHRecord>) {
        let points = []
        dbhRecords.forEach( dbhRecord => {
            points.push(new google.maps.LatLng(dbhRecord.location.latitude, dbhRecord.location.longitude))
        })
        return points;
    }

    getStreamedLocations() {
        //Request Location from additionalData
        if (this.selectedBrokerSearchResult?.additionalDataAvailable) {
            this.additionDataEventSource?.close();
            this.additionDataEventSource = this.powerSearchService.additionalDataSearch(this.selectedBrokerSearchResult.uniqueSearchId , -1, result => {
                var newLocation = new DBHRecord(result);
                if (this.isNewLocation(newLocation)) {
                    if(this.additionalDataResults.length >= this.maxRecordsOnMap) {
                        this.additionalDataResults.shift();
                    }

                    this.orderLocationsAndMarkSequense(newLocation);

                    if (this.showLatestDbhData) {
                        this.latestDbhLocation = this.additionalDataResults[this.additionalDataResults.length - 1];;
                    }

                    if(this.autoAdjust) {
                        let lastDbhLocation = this.additionalDataResults[this.additionalDataResults.length - 1]; 
                        //Save new location in mapCenter variable. Map will be recentered when the user press on 'Center Map' or when the location is outside of the bounds-20%
                        this.mapCenter = {lat: +lastDbhLocation.location.latitude, lng: +lastDbhLocation.location.longitude};
                        
                        //Verify that the location is in the padded bounds, if not recenter the map on the latest location
                        var latlng = new google.maps.LatLng(this.mapCenter);
                        if (this.map.getBounds() != null) {
                            var bounds = this.paddedBounds(100, 100, 100, 100);
                            if (!bounds.contains(latlng)) {
                                this.setCenter();
                            }
                        }
                    }
                }
            });
        }
    }

    orderLocationsAndMarkSequense(newDbhLocation: DBHRecord){
        this.additionalDataResults.push(newDbhLocation);
        this.additionalDataResults.sort( (r1, r2) => {
            if (new Date(r1.locationTime).getTime() < new Date(r2.locationTime).getTime()) {
                return -1;
            } else if (new Date(r1.locationTime).getTime() > new Date(r2.locationTime).getTime()) {
                return 1;
            } 
            return 0;
        });
        let sequence = 1;
        this.additionalDataResults.forEach( dbhRecord => {
            dbhRecord.sequence = sequence;
            ++sequence;
        });
    }

    isNewLocation(newDbhLocation: DBHRecord) {
        let lastDbhLocation = this.additionalDataResults[this.additionalDataResults.length - 1];
        return this.formatDate(lastDbhLocation.locationTime, false) !== this.formatDate(newDbhLocation.locationTime,false) &&
            (lastDbhLocation.location.latitude !== newDbhLocation.location.latitude || 
            lastDbhLocation.location.longitude !== newDbhLocation.location.longitude);
    }

    /**
     * Need to cull markers if all at the same point.  The logic needs to be defined, so this method always
     * returns false for now.   Just basing it on distance is insufficient since uncertainty or other params
     * might change.
     */
    decimateLocation(newLocation: DBHRecord) : boolean {
//         var distanceInMetersWithPreviousLocation = this.getDistanceFromLatLongInMeters(+newLocation.location.latitude, +newLocation.location.longitude,
//            +this.latestDbhLocation.location.latitude, +this.latestDbhLocation.location.longitude);
        return false;
    }

    getDistanceFromLatLongInMeters(lat1: number, lon1: number, lat2: number, lon2: number): number {
        var deg2Rad = deg => {
            return deg * Math.PI / 180;
        }

        var r = 6371; // Radius of the earth in km
        var dLat = deg2Rad(lat2 - lat1);
        var dLon = deg2Rad(lon2 - lon1);
        var a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(deg2Rad(lat1)) * Math.cos(deg2Rad(lat2)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = r * c * 1000; // Distance in meters
        return d;
    }

    paddedBounds(npad, spad, epad, wpad) {
        var SW = this.map.getBounds().getSouthWest();
        var NE =  this.map.getBounds().getNorthEast();
        var topRight =  this.map.getProjection().fromLatLngToPoint(NE);
        var bottomLeft =  this.map.getProjection().fromLatLngToPoint(SW);
        var scale = Math.pow(2,  this.map.getZoom());

        var SWtopoint =  this.map.getProjection().fromLatLngToPoint(SW);
        var SWpoint = new google.maps.Point(((SWtopoint.x - bottomLeft.x) * scale) + wpad, ((SWtopoint.y - topRight.y) * scale) - spad);
        var SWworld = new google.maps.Point(SWpoint.x / scale + bottomLeft.x, SWpoint.y / scale + topRight.y);
        var pt1 =  this.map.getProjection().fromPointToLatLng(SWworld);

        var NEtopoint =  this.map.getProjection().fromLatLngToPoint(NE);
        var NEpoint = new google.maps.Point(((NEtopoint.x - bottomLeft.x) * scale) - epad, ((NEtopoint.y - topRight.y) * scale) + npad);
        var NEworld = new google.maps.Point(NEpoint.x / scale + bottomLeft.x, NEpoint.y / scale + topRight.y);
        var pt2 = this.map.getProjection().fromPointToLatLng(NEworld);

        return new google.maps.LatLngBounds(pt1, pt2);
    }

    formatCoreDate(date: any, provider: string): string {
        return (provider === "Apple" ? 
                new Date(parseFloat(date)*1000).toLocaleString() : new Date(parseFloat(date)).toLocaleString()) + 
                " " + moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).zoneAbbr();
    }

    decode(content: string): string{
        return decodeURIComponent(content);
    }

}
