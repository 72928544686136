import { Component, Input, OnInit } from '@angular/core';
import { BrokerSearch } from '../../shared/models/brokerSearch';
import { DatePipe } from '@angular/common';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EmailReportComponent } from 'src/app/email-report/email-report.component';
import { HomeDataPeopleDetailsDialog } from './details/people-details-dialog';
import { HomeDataLocationDetailsDialog } from './details/location-details-dialog';
import { OrgEntitlementsEnum } from 'src/app/shared/enums/org-entitlements.enum';
import { HomeService } from '../../home/home.service';

declare let google: any;
@Component({
    selector: 'app-home-data-results',
    templateUrl: './home-data-results.component.html',
    styleUrls: ['./home-data-results.component.scss']
})
export class HomeDataResultsComponent implements OnInit {

    @Input() brokerSearch: BrokerSearch;
    selectedResult: any;
    selectedLocation: any;
    mapCenter : any;
    mapZoom : number;
    mapType: string;
    map: any;
    showEmailResultIcon : boolean = false;

    constructor(public datepipe: DatePipe,
                private dialog: MatDialog,
                private homeService : HomeService) {
    }

    ngOnInit() {
        this.initialize();
        this.checkShareResultEntitlement();
    }

    initialize() {
        if (this.brokerSearch) {
            this.selectedResult = this.brokerSearch.searchResult.results[0];
            this.selectedLocation = this.selectedResult.Locations[0];
            this.mapZoom = 19;
            this.mapType = 'roadmap';
            this.mapCenter = {lat: +this.selectedLocation.Latitude, lng: +this.selectedLocation.Longitude};
        }
    }


    onMapReady(map) {
        this.map = map;
        this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(document.getElementById('mapCenter'));
        document.getElementById('dropdown_'+this.brokerSearch.provider).appendChild(document.getElementById('locationDropdown'));
    }

    centerMap() {
        this.map.setCenter(this.mapCenter);
        this.map.setZoom(this.mapZoom);
    }

    ngOnDestroy() {
        document.getElementById('dropdown_'+this.brokerSearch.provider)?.removeChild(document.getElementById('locationDropdown'));
    }

    onLocationChange(event){
        if (event.source.selected) {
            this.selectedLocation = event.source.value;

            this.mapZoom = 19;
            this.mapCenter = {lat: +this.selectedLocation.Latitude, lng: +this.selectedLocation.Longitude};
        }
    }

    checkShareResultEntitlement() {
        this.showEmailResultIcon = false;
        if (this.homeService.userDetails && this.homeService.userDetails.org.clientId) {
            this.homeService.userDetails.org.entitlements.forEach( entitlement => {
                if (entitlement === OrgEntitlementsEnum.SHARE_RESULTS) {
                    this.showEmailResultIcon = true;
                }
            });
        }
    }

    EmailStaticReport() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            searchResultId: String(this.brokerSearch.uniqueSearchId),
            linkOnly: true
        }
        this.dialog.open(EmailReportComponent, dialogConfig);
    }


    header() {
        const req = this.brokerSearch.searchRequest;
        return `Phone: ${this.formatPhone(req.phone)}`;
    }

    formatPhone(number: string): string {
        return '(' + number.substring(0, 3) + ') ' + number.substring(3, 6) + '-' + number.substring(6, 10);
    }
}
