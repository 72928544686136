import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {Patterns} from "../shared/enums/patterns";
import {Roles} from "../shared/enums/roles";

/**
 * Common base class for create and update user components.
 */
export abstract class UserBase {

    readonly USERNAME_MIN_LENGTH = 1;
    readonly USERNAME_MAX_LENGTH = 50;
    readonly FIRSTNAME_MIN_LENGTH = 1;
    readonly FIRSTNAME_MAX_LENGTH = 50;
    readonly LASTNAME_MIN_LENGTH = 1;
    readonly LASTNAME_MAX_LENGTH = 50;
    readonly EMAIL_MIN_LENGTH = 8;
    readonly EMAIL_MAX_LENGTH = 60;

    orgName = new UntypedFormControl('', [Validators.required]);
    roles = new UntypedFormControl([], [Validators.required]);
    userName = new UntypedFormControl('', [
        Validators.minLength(this.USERNAME_MIN_LENGTH), Validators.maxLength(this.USERNAME_MAX_LENGTH),
        Validators.pattern(Patterns.ALPHANUMERICPLUSSPECIAL)
    ]);
    firstName = new UntypedFormControl('', [
        Validators.minLength(this.FIRSTNAME_MIN_LENGTH), Validators.maxLength(this.FIRSTNAME_MAX_LENGTH),
        Validators.pattern(Patterns.ALPHANUMERICPLUS)
    ]);
    lastName = new UntypedFormControl('', [
        Validators.minLength(this.LASTNAME_MIN_LENGTH), Validators.maxLength(this.LASTNAME_MAX_LENGTH),
        Validators.pattern(Patterns.ALPHANUMERICPLUS)
    ]);
    email = new UntypedFormControl('', [
        Validators.email,
        Validators.minLength(this.EMAIL_MIN_LENGTH), Validators.maxLength(this.EMAIL_MAX_LENGTH),
    ]);
    phone = new UntypedFormControl('', [
        Validators.required, 
        Validators.pattern(Patterns.PHONE)
    ]);
    phoneNotRequired = new UntypedFormControl('', [
        Validators.pattern(Patterns.PHONE)
    ]);
    
    header: string;
    title: string;
    rolesList: string[] = [Roles.SUPER, Roles.ADMIN, Roles.SEARCH, Roles.VIEW];
    userRoles = Roles;
    loading: boolean = false;
    fieldcheck:boolean = false;
    count: number = 1;

    readonly ORGNAME = 'orgName';
    readonly EMAIL = 'email';
    readonly USERNAME = 'userName';
    readonly FIRSTNAME = 'firstName';
    readonly LASTNAME = 'lastName';
    readonly ROLES = 'roles';
    readonly PHONE = 'phone';
    readonly PHONENOTREQUIRED = 'phoneNotRequired';

    constructor () { }
    
    getErrorMessage(formControl) {
        if (formControl === this.ORGNAME) {
            return this.orgName.hasError('required') ? 'You must select an organization' : '';
        } else if (formControl === this.EMAIL) {
            return this.email.hasError('required') ? 'You must enter a value' :
                this.email.hasError('email') ? 'Not a valid email' : 'Email must be between 8 and 60 characters';
        } else if (formControl === this.USERNAME) {
            return this.userName.hasError('required') ? 'You must enter a value' : 'Enter a valid username (1-50 characters)';
        } else if (formControl === this.FIRSTNAME) {
            return (this.firstName.hasError('required') && (this.firstName.touched || this.firstName.dirty)) ? 
                'You must enter a value' : 'Enter a valid firstname (1-50 characters)';
        } else if (formControl === this.LASTNAME) {
            return this.lastName.hasError('required') ? 'You must enter a value' : 'Enter a valid lastname (1-50 characters)';
        } else if (formControl === this.ROLES) {
            return this.roles.hasError('required') ? 'You must select a role ' : '';
        } else if (formControl === this.PHONE) {
            return this.phone.hasError('required') ? 'You must enter a phone number ' : 'Enter a valid phone number';
        } else if (formControl === this.PHONENOTREQUIRED) {
            return this.phoneNotRequired.hasError('required') ? 'You must enter a phone number ' : 'Enter a valid phone number';
        }
    }

    change(event) {
        if(event) {
            if (event.srcElement.value !== '') {
                this.count = this.count + 1;
            } else if (event.srcElement.value === '') {
                this.count = this.count - 1;
            }
        }
        if (this.count <= 1 || this.fieldcheck === false) {
            this.fieldcheck = !this.fieldcheck;
        }
    }

    markFormControlsAsTouched() {
        this.orgName.markAsTouched();
        this.userName.markAsTouched();
        this.firstName.markAsTouched();
        this.lastName.markAsTouched();
        this.email.markAsTouched();
        this.roles.markAsTouched();
        this.phone.markAsTouched();
    }
}
