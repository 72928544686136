// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.green-status {
  font-style: italic;
  font-weight: 700;
  color: green;
}

.red-status {
  font-style: italic;
  font-weight: 700;
  color: red;
}

.mat-button-toggle-group {
  align-items: center;
  font-size: 14px;
  font-weight: normal;
  height: 40px;
}

.mat-button-toggle-checked {
  background-color: #8de2cf;
  font-weight: 500;
}

.mat-button-toggle-disabled {
  background-color: #c9ede6 !important;
  color: #b5adad !important;
  cursor: not-allowed !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
mat-button-toggle-group {
  background: white;
  margin-top: 10px;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
mat-button-toggle {
  color: black;
  padding: 1px;
}`, "",{"version":3,"sources":["webpack://./src/app/geofence/show-result/show-result.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,gBAAA;EACA,YAAA;AACJ;;AAEA;EACI,kBAAA;EACA,gBAAA;EACA,UAAA;AACJ;;AAEA;EACI,mBAAA;EACA,eAAA;EACA,mBAAA;EACA,YAAA;AACJ;;AAEA;EACI,yBAAA;EACA,gBAAA;AACJ;;AACA;EACI,oCAAA;EACA,yBAAA;EACA,8BAAA;AAEJ;;AAAA,6HAAA;AACA;EACI,iBAAA;EACA,gBAAA;AAGJ;;AAAA,6HAAA;AACA;EACI,YAAA;EACA,YAAA;AAGJ","sourcesContent":[".green-status {\n    font-style: italic;\n    font-weight: 700;\n    color: green;\n}\n\n.red-status {\n    font-style: italic;\n    font-weight: 700;\n    color: red;\n}\n\n.mat-button-toggle-group {\n    align-items: center;\n    font-size: 14px;\n    font-weight: normal;\n    height: 40px;\n    //margin-top: 15px;\n}\n.mat-button-toggle-checked {\n    background-color: #8de2cf;\n    font-weight: 500;\n}\n.mat-button-toggle-disabled {\n    background-color: #c9ede6 !important;\n    color: #b5adad !important;\n    cursor: not-allowed !important;\n}\n/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */\nmat-button-toggle-group {\n    background: white;\n    margin-top: 10px;\n}\n  \n/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */\nmat-button-toggle {\n    color: black;\n    padding: 1px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
