import { Injectable } from '@angular/core';
import {ConfigService} from '../../assets/config/config-svc.service';
import {HttpClient} from '@angular/common/http';
import {SharedService} from '../shared/shared.service';
import { Alerts } from '../shared/enums/alerts';
import { Observable } from 'rxjs';
import { SSE } from 'sse.js';

@Injectable({
    providedIn: 'root'
})
export class DashboardService {

    source: any;

    constructor(public http: HttpClient, 
                public sharedService: SharedService) {
    }

    ngOnDestroy() {
        this.closeEventSource()
    }

    closeEventSource() {
        if(this.source && this.source.readyState === this.source.OPEN) {
            this.source.close();
        }
    }

    fetchDataForOrg(orgId: string, callback: any) : any {
        let url: string = '/call-stream' + (orgId ? '?OrganizationId=' + orgId : '');
        this.source = new SSE(
            `${ConfigService.config.brokerServiceUrl}`+ url,
            {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem(this.sharedService.ID_TOKEN),
                    'Content-Type': 'application/json'
                },
                withCredentials: true
            });
        this.source.addEventListener("EOD", e => {
            this.source.close();
        });
        this.source.addEventListener("error", e => {
            if (this.sharedService.isTokenExpired()) {
                this.sharedService.handleTokenExpiration(this.sharedService.tokenExpiryTitle, this.sharedService.tokenExpiryMsg);
            } else if(this.source.readyState === this.source.OPEN) {
                this.sharedService.showAlert(Alerts.ERROR, 'Stopped receiving dashboard data.');
            }
            this.source.close();
        });
        this.source.addEventListener("message", e => { 
            let result = JSON.parse(e["data"]);
            callback(result.data);
        });
        this.source.stream();
        return this.source;
    } 

    fetchOrgBoundaries(orgId: any = ""): Observable<any> {
        let url = "/v1/org-boundaries";
        if (orgId) {
            url += "?OrganizationId=" + orgId;
        }
        return this.http.get<any>(`${ConfigService.config.geofenceServiceUrl}`+ url, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem(this.sharedService.ID_TOKEN),
                'Content-Type': 'application/json'
            },
            withCredentials: true
        });
    }
}
