export class Prefix {
    
    prefixId: string;
    prefix: string;
    description: string;

    constructor(object?: any) {
        if(object) {
            this.prefixId = object.prefixId;
            this.prefix = object.prefix;
            this.description = object.description;
        }
    }
}