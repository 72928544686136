// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-1 {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 700px;
  padding-left: 30px !important;
}

.card-1:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.card-2 {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 600px;
  padding-left: 30px !important;
}

.card-2:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.bg {
  background: linear-gradient(135deg, #00b2e1, #95ce5c);
}

.bg1 {
  height: 100vh;
  background-size: 100% 100%;
  background-position: 50%;
  background-image: url('white-clipped-logo1.svg');
}

button {
  margin: 0 auto;
  display: block;
}

mat-card {
  margin: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/short-web-link/short-web-link.component.scss"],"names":[],"mappings":"AACA;EACI,wEAAA;EACA,qDAAA;EACA,YAAA;EACA,6BAAA;AAAJ;;AAGA;EACI,4EAAA;AAAJ;;AAGA;EACI,wEAAA;EACA,qDAAA;EACA,YAAA;EACA,6BAAA;AAAJ;;AAGA;EACI,4EAAA;AAAJ;;AAGA;EACI,qDAAA;AAAJ;;AAGA;EACI,aAAA;EACA,0BAAA;EACA,wBAAA;EACA,gDAAA;AAAJ;;AAGA;EACI,cAAA;EACA,cAAA;AAAJ;;AAGA;EACI,YAAA;AAAJ","sourcesContent":["\n.card-1 {\n    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);\n    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);\n    width: 700px;\n    padding-left: 30px !important;\n}\n  \n.card-1:hover {\n    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);\n}\n\n.card-2 {\n    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);\n    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);\n    width: 600px;\n    padding-left: 30px !important;\n}\n  \n.card-2:hover {\n    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);\n}\n  \n.bg {\n    background: linear-gradient(135deg,#00b2e1,#95ce5c);\n}\n  \n.bg1 {\n    height: 100vh;\n    background-size: 100% 100%;\n    background-position: 50%;\n    background-image: url('../../assets/img/white-clipped-logo1.svg');\n}\n  \nbutton {\n    margin: 0 auto;\n    display: block;\n}\n\nmat-card {\n    margin: auto;\n}\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
