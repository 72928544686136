import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ConfigService } from 'src/assets/config/config-svc.service';
import { WebSocketService } from './websocket.service';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Patterns } from '../shared/enums/patterns';
import { SharedService } from '../shared/shared.service';
import { CookieService } from '../shared/cookie.service';

@Component({
    selector: 'app-zero-touch',
    templateUrl: './zero-touch.component.html',
    styleUrls: ['./zero-touch.component.scss']
})
export class ZeroTouchComponent implements OnInit {

    stationId = new UntypedFormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(30), Validators.pattern(Patterns.ALPHANUMERIC)]);
    securityWord: string;
    tn: string;
    drawerOpened: boolean = true;
    isConnected: boolean = false;
    isCallTerminated: boolean = false;
    stompClient = null;
    wsSubscription: Subscription;

    readonly STATION_ID = 'stationId';

    constructor(private webSocketService: WebSocketService,
                private sharedService: SharedService,
                private cookieService: CookieService) { 

    }

    ngOnInit(): void {
        let stationId = this.cookieService.getCookie(this.STATION_ID);
        if(stationId) {
            this.stationId.setValue(stationId);
        }
    }

    ngOnDestroy(): void {
        if(this.wsSubscription) {
            this.wsSubscription.unsubscribe();
        }
        if (this.stompClient !== null) {
            this.stompClient.disconnect();
        }
    }

    connect(): void {
        if (this.isNotValidData()) {
            return;
        }
        if(this.stationId.value) {
            this.cookieService.setCookie( {name: this.STATION_ID, value: this.stationId.value} );
            // Open connection with server socket
            this.stompClient = this.webSocketService.connect(ConfigService.config.pdUiServiceUrl);
            const that = this;
            this.stompClient.connect({}, (frame: any) => {
                let orgId = this.sharedService.getLoggedInUser().org.clientId;
                let topic = '/queue/message/' + orgId + "/" + this.stationId.value; 
                that.wsSubscription = that.stompClient.subscribe(topic, (message: { body: string; }) => {
                    let messageBody = JSON.parse(message?.body);
                    if(messageBody?.recordType === 'call-disconnected' && this.securityWord === messageBody?.securityWord) {
                        this.isCallTerminated = true;
                    } else if(messageBody?.recordType === 'call-connected') {
                        this.clearResult();
                        setTimeout(() => {
                            this.securityWord = messageBody.securityWord;
                            this.tn = messageBody.data.tn;
                        }, 500);
                        
                    }
                });
                this.isConnected = true;

            }, (err: any) => {
                console.log(err);
            });
        }
    }

    disconnect(): void {
        if(this.wsSubscription) {
            this.wsSubscription.unsubscribe();
        }
        if (this.stompClient !== null) {
            this.stompClient.disconnect();
        }
        this.isConnected = false;
        this.clearResult();
    }

    clearResult(): void {
        this.securityWord = undefined;
        this.isCallTerminated = false;
    }

    toggle(): void {
        this.drawerOpened = !this.drawerOpened;
        let target = document.getElementById('stationIdContainer');
        if (target) {
            if (this.drawerOpened) {
                target.style.height = target.scrollHeight+"px";
            } else {
                target.style.height = "0px";
            }
        }
    }

    getErrorMessage(formControl: any): string {
        if (formControl === this.STATION_ID) {
            return this.stationId.hasError('required') ? 'You must enter a value' : 'Not a valid Position Id';
        }
    }

    markFormControlsAsTouched(): void {
        this.stationId.markAsTouched();
    }

    isNotValidData(): boolean {
        this.markFormControlsAsTouched();  
        return this.stationId.invalid;
    }

    formatTn(number: string): string {
        return '(' + number.substring(0, 3) + ') ' + number.substring(3, 6) + '-' + number.substring(6, 10);
    }
    
}
