import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ConfigService} from '../../assets/config/config-svc.service';
import { SharedService } from '../shared/shared.service';
import { CookieService } from '../shared/cookie.service';

@Injectable({
    providedIn: 'root'
})
export class LoginService {

  readonly REDIRECT_URL = 'redirectUrl';

  constructor(public router: Router, public sharedService: SharedService, private cookieService: CookieService) {
  }

  public signIn() {
    const loginUrl = `${ConfigService.config.redirectUri}/${ConfigService.config.federatedSSOName}?redirect_uri=${window.location.origin}/appconsole`;
    window.open(loginUrl, '_self');
  }

  
  public signInAndRedirect(loginRedirectUri) {
    const loginUrl = `${ConfigService.config.redirectUri}/${ConfigService.config.federatedSSOName}?redirect_uri=${window.location.origin}/${loginRedirectUri}`;
    window.open(loginUrl, '_self');
  }

  public signOut() {
    const logoutUrl = `${ConfigService.config.logoutUrl}?registration_id=${ConfigService.config.federatedSSOName}&logout_uri=${ConfigService.config.logoutRedirectUri}`
    this.cookieService.deleteCookie(this.REDIRECT_URL);
    window.open(logoutUrl, '_self');
  }

  
  public signOutAndRedirectToLogin() {
    const logoutUrl = `${ConfigService.config.logoutUrl}?registration_id=${ConfigService.config.federatedSSOName}&logout_uri=${ConfigService.config.loginRedirectUri}`
    window.open(logoutUrl, '_self');
  }

  public signOutAndRedirect(redirectUrl) {
    if(redirectUrl) {
      this.cookieService.setCookie( {name: this.REDIRECT_URL, value: redirectUrl, expireDays: 0.0416666667} );
    }
    const logoutUrl = `${ConfigService.config.logoutUrl}?registration_id=${ConfigService.config.federatedSSOName}&logout_uri=${ConfigService.config.loginRedirectUri}`
    window.open(logoutUrl, '_self');

  }
}
