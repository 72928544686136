import { SupplementalDataProviderConfigTo } from "./supplementalDataProviderConfigTo";

export class SupplementalDataProviderTo {

    name : string
    supplementalDataProviderConfigs: SupplementalDataProviderConfigTo[];

    constructor(object?: any) {
        this.name = object?.name;
        this.supplementalDataProviderConfigs = [];
        object.supplementalDataProviderConfigs?.forEach(element => {
            this.supplementalDataProviderConfigs.push(new SupplementalDataProviderConfigTo(element));
        });
    }

}