import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { EntitlementsService } from '../entitlements.service';
import { SharedService } from '../../shared/shared.service';
import { Alerts } from '../../shared/enums/alerts';
import { EntitlementBase } from '../entitlement-base';
import { EntitlementTo } from 'src/app/shared/models/entitlementTo';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
    selector: 'app-create-entitlements',
    templateUrl: './create-entitlements.component.html',
    styleUrls: ['./create-entitlements.component.scss']
})
export class CreateEntitlementsComponent extends EntitlementBase implements OnInit {

    constructor(public dialogRef: MatDialogRef<CreateEntitlementsComponent>,
                public entitlementService: EntitlementsService, 
                public sharedService: SharedService) {
        super();
    }

    ngOnInit() {
        this.entitlement = new EntitlementTo();
        this.entitlement.orgType = this.defaultValue;
        this.entitlement.preferences = [];
        this.getAllPreferences();
    }

    getAllPreferences() {
        this.isLoading = true;
        this.entitlementService.getAllPreferences().subscribe({
            next: data => {
                this.preferenceList = data;
                this.preferenceList.sort((a, b) => a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase() ? -1 : 1);
                this.isLoading = false;
            },
            error: error => {
                this.isLoading = false;
            },
            complete: () => {}
        });
    }

    createEntitlement() {
        this.markFormControlsAsTouched();
        if (this.name.valid && this.displayName.valid) {
            let newEntitlement = new EntitlementTo(this.entitlement);
            this.isLoading = true;
            this.entitlementService.createEntitlement(newEntitlement).subscribe({
                next: data => {
                    this.sharedService.showAlert(Alerts.SUCCESS, 'Entitlement created successfully');
                    this.isLoading = false;
                    this.dialogRef.close(true);
                },
                error: error => {
                    this.isLoading = false;
                    this.sharedService.handleErrors(error,
                        [{
                            "condition": (error.status === 400 && Object.keys(error.error).length > 0),
                            "msg": error.error[Object.keys(error.error)[0]]
                        }],
                        "Unable to create entitlement");
                },
                complete: () => { }
            });
        }
    }

    resetEntitlement() {
        if (this.fieldcheck) {
            Swal.fire({
                title: 'Are you sure?',
                text: 'Reset the values?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes, Reset',
                cancelButtonText: 'No, Cancel',
            }).then((result) => {
                if (result.value) {
                    this.entitlement = new EntitlementTo();
                    this.entitlement.orgType = this.defaultValue;
                }
            });
        } else {
            this.entitlement = new EntitlementTo();
        }
    }

    close() {
        if (this.fieldcheck) {
            Swal.fire({
                title: 'Are you sure?',
                text: 'Close the create Entitlement dialog box?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes, Close',
                cancelButtonText: 'No, Cancel',
            }).then((result) => {
                if (result.value) {
                    this.dialogRef.close(false);
                }
            });
        } else {
            this.dialogRef.close(false);
        }
    }

}
