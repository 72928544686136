export class PredefinedTextMessage {
    
    id: string;
    messageId: number;
    message: string;
    prefixId: string;

    constructor(object?: any) {
        if(object) {
            this.id = object.id;
            this.messageId = object.messageId;
            this.message = object.message;
            this.prefixId = object.prefixId;
        }
    }
}