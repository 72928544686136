import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigService } from 'src/assets/config/config-svc.service';
import { PredefinedTextMessage } from '../shared/models/predefinedTextMessage';
import { Prefix } from '../shared/models/prefix';
import { SharedService } from '../shared/shared.service';
import {Page} from "puppeteer";

@Injectable({
    providedIn: 'root'
})
export class PredefinedTextMessageService {

    updatePredefinedTextMessageList = new Subject<void>();
    updatePredefinedTextMessageList$ = this.updatePredefinedTextMessageList.asObservable();

    constructor(public http: HttpClient, public sharedService: SharedService) {
    }

    getHeaders() {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'transactionId': this.sharedService.generateUUID(),
                'reportType': 'All'
            }),
            withCredentials: true
        };
        return httpOptions;
    }

    getOptions(body: any) {
        let httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'transactionId': this.sharedService.generateUUID(),
                'reportType': 'All'
            }),
            withCredentials: true,
            body: body
        };
        return httpOptions;
    }

    getAllPredefinedTextMessages(filterValue, sortActive, sortDirection, pageIndex, pageSize): Observable<Page> {
        let url = ConfigService.config.iotGwServiceUrl + '/v1/admin/predefined-msg?SEARCH_TERM=' + encodeURIComponent(filterValue) + 
                '&sortActive=' + sortActive + '&sortDirection=' + sortDirection +  '&page=' + pageIndex + '&size=' + pageSize;
        return this.http.get<Page>(url, this.getHeaders())
            .pipe(map((predefinedTextMessages => predefinedTextMessages)));
    }
  
    addPredefinedTextMessage(payload) {
        return this.http.post(ConfigService.config.iotGwServiceUrl + '/v1/admin/predefined-msg', payload, this.getHeaders()).pipe(map((res: any) => res));
    }

    updatePredefinedTextMessage(payload) {
        return this.http.put(ConfigService.config.iotGwServiceUrl + '/v1/admin/predefined-msg', payload, this.getHeaders()).pipe(map((res: any) => res));
    }

    deletePredefinedTextMessage(msgId) {
        return this.http.delete(ConfigService.config.iotGwServiceUrl + '/v1/admin/predefined-msg/' + msgId, this.getHeaders()).pipe(map((res: any) => res));
    }

    deleteSelectedPredefinedTextMessages(msgIds: Array<number>) {
        return this.http.delete(ConfigService.config.iotGwServiceUrl + '/v1/admin/predefined-msg/all', this.getOptions(msgIds)).pipe(map((res: any) => res));
    }

    getAllPrefixes(): Observable<Prefix[]> {
        return this.http.get<Prefix[]>(ConfigService.config.iotGwServiceUrl + '/v1/admin/prefix',
             this.getHeaders()).pipe(map((prefixes => this.setPrefixInModel(prefixes))));
    }

    setPredefinedTextMessageInModel(predefinedTextMessages) {
        let predefinedTextMsg = [];
        if (predefinedTextMessages) {
            predefinedTextMessages.forEach(element => {
                let textBlock = new PredefinedTextMessage(element);
                predefinedTextMsg.push(textBlock);
            });
        }
        return predefinedTextMsg;
    }

    setPrefixInModel(prefix) {
        let prefixes = [];
        if (prefix) {
            prefix.forEach(element => {
                let prefix = new Prefix(element);
                prefixes.push(prefix);
            });
        }
        return prefixes;
    }
}
