import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {ProviderTo} from '../shared/models/providerTo';
import {Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {ConfigService} from '../../assets/config/config-svc.service';
import {HomeService} from '../home/home.service';
import {SharedService} from '../shared/shared.service';


@Injectable({
    providedIn: 'root'
})
export class ProvidersService {
    updateProviderList = new Subject<void>();
    updateProviderList$ = this.updateProviderList.asObservable();

    constructor(public http: HttpClient, public homeService: HomeService, public sharedService: SharedService) {
    }

    getHeaders() {
        return this.getHeadersForContent('application/json', 'json')
    }

    getHeadersForContent(contentType, responseType) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': contentType,
                'transactionId': this.sharedService.generateUUID(),
                'reportType': 'All'
            }),
            withCredentials: true,
            responseType: responseType
        };
        if (!contentType) {
            httpOptions.headers.append('Content-Type', 'application/json');
        } else {
            httpOptions.headers.append('ContentType', contentType);
        }
        return httpOptions;
    }

    public createProvider(payload) {
        return this.http.post(ConfigService.config.brokerServiceUrl + '/provider/create', payload, this.getHeaders()).pipe(map((res: any) => res));

    }

    public updateProvider(payload) {
        return this.http.put(ConfigService.config.brokerServiceUrl + '/provider/update', payload, this.getHeaders()).pipe(map((provider: any) => provider));
    }

    public getAllProviders(): Observable<ProviderTo[]> {
        return this.http.get<ProviderTo[]>(ConfigService.config.brokerServiceUrl + '/provider/read-all',
            this.getHeaders()).pipe(map((providers => this.setProvidersDataInModel(providers))));
    }

    public getProviders(searchTypeName): Observable<ProviderTo[]> {
        return this.http.get<ProviderTo[]>(ConfigService.config.brokerServiceUrl + '/provider/read-by-searchtype?SearchTypeName=' + searchTypeName,
            this.getHeaders()).pipe(map((providers => this.setProvidersDataInModel(providers))));
    }

    public deleteProvider(name) {
        return this.http.delete(ConfigService.config.brokerServiceUrl + '/provider/delete?ProviderName=' + name, this.getHeaders()).pipe(map((res: any) => res));
    }

    setProvidersDataInModel(providersData) {
        const providerTo = [];
        if (providersData) {
            providersData.forEach(element => {
                const eachProvider = new ProviderTo(element);
                providerTo.push(eachProvider);
            });
        }
        return providerTo;
    }

}
