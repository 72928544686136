import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {LoginComponent} from './login/login.component';
import {ListUsersComponent} from './users/list-users/list-users.component';
import { ShortWebLinkComponent } from './short-web-link/short-web-link.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { CreatePolygonComponent } from './create-polygon/create-polygon.component';

const routes: Routes = [
    {path: '', redirectTo: 'session/login', pathMatch: 'full'},
    {path: 'session/:action', component: LoginComponent},
    {path: 'appconsole', component: HomeComponent},
    {path: 'shortweblink/:securityWord', component: ShortWebLinkComponent},
    {path: 'user', component: ListUsersComponent},
    {path: 'create-polygon', component: CreatePolygonComponent},
    {path: 'PageNotFound', component:PagenotfoundComponent},
    {path: '**', redirectTo: '/PageNotFound'}];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
