import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ConfigService } from 'src/assets/config/config-svc.service';
import { SharedService } from '../shared/shared.service';
import { Page } from 'puppeteer';

@Injectable({
    providedIn: 'root'
})
export class GeofenceService {

    constructor(private http: HttpClient,
                private sharedService: SharedService) { }

    getHeaders() {
        return this.getHeadersForContent('application/json', 'json')
    }

    getHeadersForContent(contentType, responseType) {
        const httpOptions = {
            headers: new HttpHeaders({
                'transactionId': this.sharedService.generateUUID(),
                'reportType': 'All'
            }),
            withCredentials: true,
            responseType: responseType
        };
        if (contentType) {
            httpOptions.headers.append('ContentType', contentType);
        }
        return httpOptions;
    }

    getGeofenceList(listStatus: string, statusFilters: string[], filterValue: string, 
                    sortActive: string, sortDirection: string, pageIndex: number, pageSize: number): Observable<Page> {
        return this.http.get<Page>(ConfigService.config.geofenceServiceUrl + '/v1/int/geofence-list?' + 
            'listStatus=' + listStatus + '&statusFilters=' + statusFilters + '&searchParam=' + encodeURIComponent(filterValue) + 
            '&sortActive=' + sortActive + '&sortDirection=' + sortDirection +  '&page=' + pageIndex + '&size=' + pageSize,
            this.getHeaders())
            .pipe(map((geofences => geofences)));
    }

    getGeofenceData(gId: string) {
        return this.http.get(ConfigService.config.geofenceServiceUrl + '/v2/int/geofence/' + gId,
                            this.getHeaders())
                        .pipe(map((res: any) => res));
    }

    uploadGeofenceFile(payload: any) {
        return this.http.post(ConfigService.config.geofenceServiceUrl + '/v1/int/generate-geofence', 
                              payload, 
                              this.getHeadersForContent(undefined, 'json')).pipe(map((res: any) => res));
    }

    verifyGeofenceList(data: any) {
        return this.http.post(ConfigService.config.geofenceServiceUrl + '/v1/int/geofence-list/verify',
                            data,
                            this.getHeaders())
                        .pipe(map((res: any) => res));
    }

    deleteGeofence(data: any) {
        return this.http.post(ConfigService.config.geofenceServiceUrl + '/v1/int/geofence-list/delete',
                            data,
                            this.getHeaders())
                        .pipe(map((res: any) => res));
    }

    updateGeofences(data: any) {
        return this.http.post(ConfigService.config.geofenceServiceUrl + '/v1/int/geofence-list/update', 
                            data,
                            this.getHeaders())
                        .pipe(map((res: any) => res));
    }

    markGeofencesAsPending(data: any) {
        return this.http.post(ConfigService.config.geofenceServiceUrl + '/v1/int/mark-geofences-as-pending', 
                            data,
                            this.getHeaders())
                        .pipe(map((res: any) => res));
    }

    markGeofencesAsStaging(data: any) {
        return this.http.post(ConfigService.config.geofenceServiceUrl + '/v1/int/mark-geofences-as-staging', 
                            data,
                            this.getHeaders())
                        .pipe(map((res: any) => res));
    }
}
