import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-show-result',
  templateUrl: './show-result.component.html',
  styleUrls: ['./show-result.component.scss']
})
export class ShowResultComponent implements OnInit {

    resultList: Array<any> = [];
    resultColumns: string[] = ['geofenceId', 'status', 'message'];
    dataSource = new MatTableDataSource<any>(this.resultList);
    selection = new SelectionModel<any>(true, []);
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    paginatorLength: number;

    failedCount: number = 0;
    ignoredCount: number = 0;
    successfulCount: number = 0;
    defaultFilters = ['FAILED','IGNORED','SUCCESSFUL'];
    statusFilters: Array<any> = ['FAILED', 'IGNORED', 'SUCCESSFUL', 'NONE'];
    filterCheckboxes: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(this.statusFilters);
    
    constructor(@Inject(MAT_DIALOG_DATA) private data,
                private dialogRef: MatDialogRef<ShowResultComponent>,
                private cdref: ChangeDetectorRef,) { 
    }

    ngOnInit() {
        this.resultList = this.data
        this.getStatusCount();
        this.showResponse();
        this.filterCheckboxes.subscribe((newFilterValue: any[]) => {
            this.dataSource.filter = newFilterValue.join(',');
        });
    }

    getStatusCount() {
        this.resultList.forEach( result => {
            if (result.status === this.defaultFilters[0]) {
                this.failedCount += 1;
            } else if (result.status === this.defaultFilters[1]) {
                this.ignoredCount += 1;
            } else if (result.status === this.defaultFilters[2]) {
                this.successfulCount += 1;
            }
        });
    }

    showResponse() {
        if (this.resultList.length > 20) {
            this.paginatorLength = this.resultList.length;
        } else {
            this.paginatorLength = 20;
        }
        const currentFilter = this.dataSource.filter;
        this.dataSource = new MatTableDataSource<any>(this.resultList);
        this.dataSource.filter = currentFilter;
        
        this.dataSource.filterPredicate = (data: any, filter: any) => {
            let result = false;
            if (filter.length > 0) {
                let filters = filter.split(',');
                if (filters.includes(data.status)) {
                    result = true;
                    filters.map(f => {
                        if (!this.statusFilters.includes(f)) {
                            if (data.geofenceId && data.geofenceId.trim().toLowerCase().indexOf(f.trim().toLowerCase()) !== -1 ||
                                data.message && data.message.trim().toLowerCase().indexOf(f.trim().toLowerCase()) !== -1 ||
                                data.status && data.status.trim().toLowerCase().indexOf(f.trim().toLowerCase()) !== -1) {
                                result = true;
                            } else {
                                result = false;
                            }
                        }
                    });
                }
            }
            return result;
        }

        setTimeout(() => this.dataSource.paginator = this.paginator);
        this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => {
            if (typeof data[sortHeaderId] === 'string') {
                return data[sortHeaderId].toLocaleLowerCase();
            }
            return data[sortHeaderId];
        };
        this.dataSource.sort = this.sort;
    }
    
    ngAfterContentChecked() {
        this.cdref.detectChanges();
    }

    close() {
        this.dialogRef.close(true);
    }

    syncPrimaryPaginator(event: PageEvent) {
        this.paginator.pageIndex = event.pageIndex;
        this.paginator.pageSize = event.pageSize;
        this.paginator.page.emit(event);
    }

    onValChange(e) {
        if (e.source._checked) {
            if (!this.filterCheckboxes.value.includes(e.value)) {
                this.filterCheckboxes.next(this.filterCheckboxes.value.concat(e.value));
            }
        } else {
            this.filterCheckboxes.next(this.filterCheckboxes.value.filter((a: any) => a !== e.value));
        }
        if (this.filterCheckboxes.value.length === 0) {
            this.filterCheckboxes.next(this.statusFilters);
        }
    }
}
