export enum BrowserConstants {
    CHROME = 'Chrome',
    FIREFOX = 'Firefox',
    SAFARI = 'Safari',
    EDGE = 'Edge',
    WINDOWS_OS = 'Windows',
    LINUX_OS = 'Linux',
    MAC_OS = 'Mac OS',
    CHROME_FIREFOX_RELOAD_CMD_FOR_WINDOWS = 'Ctrl + Shift + R',
    CHROME_FIREFOX_RELOAD_CMD_FOR_MAC = 'Command + Shift + R',
    SAFARI_EMPTY_CACHE_CMD_FOR_MAC = 'Option + Command + E',
    SAFARI_RELOAD_CMD_FOR_MAC = 'Command + R',
    OTHER_BROWSER_RELOAD_CMD = 'Ctrl + F5'
}