import {RelativeDateTo} from './relativeDate';
import {LatitudeLongitudeTo} from './latitudeLongitudeTo';

/**
 * Based on Revised Civic Location Format for Presence Information Data Format Location Object (PIDF-LO)
 * (https://tools.ietf.org/html/rfc5139)
 */
export class CivicAddressTo {
    country: string;
    A1: string; // State or province
    A2: string; // County
    A3: string; // City
    A4: string; // City division
    A5: string; // Neighborhood
    A6: string; // Group of Streets
    PRM: string; // Road pre-modifier
    PRD: string; // street direction prefix
    RD: string; //  street name
    STS: string; // street suffix (ie: street, ave, rd)
    POD: string; //street  directional suffix
    POM: string; // Road post-modifier
    RDSEC: string; // road section
    RDBR: string; // Road branch
    RDSUBBR: string; // Road sub-branch
    HNO: string;  //house number
    HNS: string; // house number extension (eg: 1/2)
    LMK: string; // Landmark orVanity address
    LOC: string; // location
    FLR: string;
    NAM: string;
    PC: string;
    BLD: string;
    UNIT: string;
    ROOM: string;
    SEAT: string;
    PLC: string;
    PCN: string; // Postal community name
    POBOX: string;
    ADDCODE: string;

    constructor(object?: any) {
        if (!object) {
            return;
        }

        this.HNO = object.number;
        this.HNS = object.fraction;
        this.PRD = object.prefix;
        this.RD = object.street;
        this.STS = object.type;
        this.UNIT = object.sec_unit_num;
        this.A3 = object.city;
        this.A1 = object.state;
        this.PC = object.zip;
    }

    format():string {
        var address = "";
        address = address + this.getVal("hno");
        address = address + this.getVal("hns");
        address = address + this.getVal("prm");
        address = address + this.getVal("prd");
        address = address + this.getVal("rd");
        address = address + this.getVal("sts");
        address = address + this.getVal("unit", "#");
        address = address.trim() + ", ";
        address = address + this.getVal("a3");
        address = address.trim() + ", ";
        address = address + this.getVal("a1");
        address = address + this.getVal("pc");
        return address.trim();
    }

    private getVal(property: string, optionalPrefix?:string): string {
        const prefix = optionalPrefix ? optionalPrefix : "";
        return this[property] ? (prefix + this[property] + ' ') : "";
    }

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }
}
