import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CookieService {

    constructor() {}

    getCookie(name: string): string {
        let cookieList: Array<string> = document.cookie.split(';');
        let length: number = cookieList.length;
        let cookieName = `${name}=`;
        let cookie: string;

        for (let i: number = 0; i < length; i += 1) {
            cookie = cookieList[i].replace(/^\s+/g, '');
            if (cookie.indexOf(cookieName) === 0) {
                return cookie.substring(cookieName.length, cookie.length);
            }
        }
        return null;
    }

    deleteCookie(cookieName: any): void {
        this.setCookie({ name: cookieName, value: '', expireDays: -1 });
    }

    /**
     * Expires default 1 day
     * If params.session is set and true expires is not added
     * If params.path is not set or value is not greater than 0 its default value will be root "/"
     * Secure flag can be activated only with https implemented
     * Examples of usage:
     * {service instance}.setCookie({name:'token',value:'abcd12345', session:true }); <- This cookie will not expire
     * {service instance}.setCookie({name:'userName',value:'John Doe', secure:true }); <- If page is not https then secure will not apply
     * {service instance}.setCookie({name:'niceCar', value:'red', expireDays:10 }); <- For all this examples if path is not provided default will be root
     */
    setCookie(params: any): void {
        let expiryDate: Date = new Date();
        expiryDate.setTime(
            expiryDate.getTime() +
            (params.expireDays ? params.expireDays : 1) * 24 * 60 * 60 * 1000
        );
        document.cookie = (params.name ? params.name : '') + '=' + (params.value ? params.value : '') + ';' + 
                          (params.session ? '' : 'expires=' + expiryDate.toUTCString() + ';') +
                          'path=' + (params.path && params.path.length > 0 ? params.path : '/') + ';' +
                          (location.protocol === 'https:' && params.secure && params.secure == true ? 'secure' : '');
    }
}