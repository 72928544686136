import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Config} from "./Config";

@Injectable()
export class ConfigService {
    static config: Config;
    configUrl = 'assets/config.json';

    constructor(private http: HttpClient) {
    }

    getConfig() {
        return this.http.get<Config>(this.configUrl)
            .toPromise()
            .then(data => {
                ConfigService.config = {...data};
            });
    }
}
