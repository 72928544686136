import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {HomeService} from '../../home/home.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {EmailReportComponent} from '../../email-report/email-report.component';
import {BrokerSearch} from '../../shared/models/brokerSearch';
import {PhoneTo} from '../../shared/models/phoneTo';
import {CivicAddressTo} from '../../shared/models/CivicAddressTo';
import {OrgEntitlementsEnum} from 'src/app/shared/enums/org-entitlements.enum';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import 'moment-timezone';

/**
 *   This component displays results for based on Commercial Search Providers (eg: Lexis-Nexis).   
 *   This includes AddressSearch and PhoneSearch.
 */
@Component({
    selector: 'app-commercial-results',
    templateUrl: './commercial-results.component.html',
    styleUrls: ['./commercial-results.component.scss']
})
export class CommercialResultsComponent implements OnInit {
    
    @Input() brokerSearch: BrokerSearch;
    @Input() resultList: BrokerSearch[];
    @Input() webCrawlerData;
    @Output() valueChange = new EventEmitter();
    physicalAttributesString: string;
    threatScoreColor = '';
    displayLoading = true;
    showWebcrawler = false;
    showEmailResultIcon : boolean = false;

    readonly COMMERCIAL_ADDRESS_SEARCH: string = "Commercial Address";
    readonly COMMERCIAL_PHONE_SEARCH: string = "Commercial Phone";

    constructor(private homeService: HomeService,
                private dialog: MatDialog,
                private datepipe: DatePipe) {
    }

    ngOnInit() {
        this.checkShareResultEntitlement();
    }

    ngOnChanges(changes: any) {
        if (changes.hasOwnProperty('brokerSearch')) {
            if (this.brokerSearch
                && this.brokerSearch.searchResult
                && this.brokerSearch.searchResult.people.length > 0
                && this.brokerSearch.searchResult.people[0].hasOwnProperty('webThreatScore')) {
                this.displayLoading = false;
            }
        }
    }

    ngOnDestroy() {
        this.displayLoading = true;
        this.valueChange.emit('');
    }

    onSearchChange(event: any){
        if (event.source.selected && this.brokerSearch.uniqueSearchId !== event.source.value.uniqueSearchId) {
            this.brokerSearch = event.source.value;
        }
    }

    physicalAttributes(criminalRecords): string {
        this.physicalAttributesString = '';
        if (criminalRecords !== undefined && criminalRecords.length != null &&
            criminalRecords.length && criminalRecords[0].physicalAttributes) {
            for (const criminalRecord of criminalRecords) {
                this.physicalAttributesString = '';
                this.toMap(criminalRecord.physicalAttributes).forEach((v, k) => {
                    if (this.physicalAttributesString) {
                        this.physicalAttributesString += ', ';
                    }
                    this.physicalAttributesString += (k + '/' + v.toLowerCase());
                });
            }
        }
        return this.physicalAttributesString;
    }

    toMap(item: any): Map<string, string> {
        const map = new Map();
        Object.keys(item).filter(k => item[k] != null && k !== 'sourceType').forEach(k => map.set(k, item[k]));
        return map;
    }

    fullName(name) {
        return [name.firstName, name.middleName, name.lastName, name.nameSuffix]
            .filter((val) => val).join(' ');
    }

    age(dob) {
        if (dob && dob.age) {
            return ', ' + dob.age + ' years old';
        } else {
            return '';
        }
    }

    getPhones(phones) {
        let i = 0;
        let retval = '';
        for (const phone of phones) {
            i = i + 1;
            if (i <= 3) {
                if (retval !== '') {
                    retval += ', ';
                }
                retval += this.getPhone(phone);
                if (phone.phoneType) {
                    retval += (phone.phoneType.startsWith('WIRELINE') ? ' (Wireline)' : ' (Unknown)');
                }
            }
        }
        return retval;
    }

    getPhone(phone: PhoneTo) {
        if (phone) {
            return this.formatPhone(phone.number);
        } else {
            return ' ';
        }
    }

    formatDate(date: any, showTimezone: boolean): string {
        return this.datepipe.transform(new Date(date), 'MM/dd/yyyy hh:mm:ss a') + (showTimezone ? " " + moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).zoneAbbr() : "");
    }

    formatPhone(number: string): string {
        return '(' + number.substring(0, 3) + ') ' + number.substring(3, 6) + '-' + number.substring(6, 10);
    }

    formatAddress(untypedAddress): string {
        const address: CivicAddressTo = new CivicAddressTo().deserialize(untypedAddress);
        return address.format().toUpperCase();
    }

    getAddress(addr): string {
        if (addr) {
            var address = "";
            if (addr.hno){
                address = address + ' ' + addr.hno;
            }
            if (addr.hns){
                address = address + ' ' + addr.hns;
            }
            if (addr.prd){
                address = address + ' ' + addr.prd;
            }
            if (addr.rd){
                address = address + ' ' + addr.rd;
            }
            if (addr.sts){
                address = address + ' ' + addr.sts;
            }
            if (addr.pod){
                address = address + ' ' + addr.pod;
            }
            if (addr.unit){
                address = address + ', ' + addr.unit;
            }
            if (addr.loc){
                address = address + ' ' + addr.loc;
            }
            if (addr.a3){
                address = address + ', ' + addr.a3;
            }
            if (addr.a1){
                address = address + ', ' + addr.a1;
            }
            if (addr.pc){
                address = address + ' ' + addr.pc;
            }
            return address.trim();
        }
    }

    getDateLastReported(dateLastReported) {
        if (dateLastReported) {
            return dateLastReported.month + '/' + dateLastReported.day + '/' + dateLastReported.year;
        } else {
            return 'unknown';
        }
    }

    showAlert() {
        //console.log('showAlert');
        alert('the alert');
    }

    callerName(name) {
        return [name.firstName, name.middleName, name.lastName, name.nameSuffix]
            .filter((val) => val).join(' ');
    }

    aliasName(name) {
        let middleName = name.middleName;
        if (middleName) {
            middleName = middleName.charAt(0);
        }
        return [name.lastName + ', ' + name.firstName, middleName]
            .filter((val) => val).join(' ');
    }

    getDOB(dob) {
        if (dob) {
            return dob.month + '/' + 'XX' + '/' + dob.year;
        } else {
            return 'unknown';
        }
    }

    filterItemsOfType(state, data, type) {
        if (type === 'Nearby') {
            return data.filter(item => item.addresses[0] && item.addresses[0].a1 === state);
        } else {
            return data.filter(item => item.addresses[0] && item.addresses[0].a1 !== state);
        }
    }

    getThreatScoreColor(threatLevel) {
        if (threatLevel == "THREAT_LOW") {
            this.threatScoreColor = 'green';
        } else if (threatLevel == "THREAT_MEDIUM") {
            this.threatScoreColor = '#ffbf00';
        } else if (threatLevel == "THREAT_HIGH") {
            this.threatScoreColor = 'red';
        } else {
            this.threatScoreColor = 'black';
        }
        return this.threatScoreColor;
    }

    checkShareResultEntitlement() {
        this.showEmailResultIcon = false;
        if (this.homeService.userDetails && this.homeService.userDetails.org.clientId) {
            this.homeService.userDetails.org.entitlements.forEach( entitlement => {
                if (entitlement === OrgEntitlementsEnum.SHARE_RESULTS) {
                    this.showEmailResultIcon = true;
                }
            });
        }
    }

    EmailStaticReport() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            searchResultId: String(this.brokerSearch.uniqueSearchId)
        };
        this.dialog.open(EmailReportComponent, dialogConfig);
    }

    getSearchCriteria() {
        const req = this.brokerSearch.searchRequest;
        switch (this.brokerSearch.searchType) {
            case 'Commercial Phone':
                return this.formatPhone(req.phone);
            case 'Commercial Address':
                return this.formatAddress(req.address);
        }
    }

}
