import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { saveAs } from 'file-saver';

@Component({
    selector: 'app-download-kml-file',
    templateUrl: './download-kml-file.component.html',
    styleUrls: ['./download-kml-file.component.scss']
})
export class DownloadKmlFileComponent implements OnInit {

    @ViewChild('autosize') autosize: CdkTextareaAutosize;

    name: string;
    description: string;
    coordinates: any = [];

    constructor(@Inject(MAT_DIALOG_DATA) private data,
                private dialogRef: MatDialogRef<DownloadKmlFileComponent>) {
    }

    ngOnInit() { 
        this.coordinates = this.data;
    }

    close() {
        this.dialogRef.close(false);
    }

    download() {
        let xmlData = this.createXml();
        var filename = this.name.replace(" ", "-") + ".kml";
        var bb = new Blob([xmlData], {type: 'text/xml'});
        saveAs(bb, filename);
        this.dialogRef.close(false);
    }

    createXml() {
        let parser = new DOMParser();
        let xml = `<?xml version="1.0" encoding="UTF-8"?>
                   <kml></kml>`;
        let xmlDoc = parser.parseFromString(xml, "application/xml");

        let kmlElement = xmlDoc.getElementsByTagName("kml");
        kmlElement[0].setAttribute("xmlns","http://www.opengis.net/kml/2.2");

        let documentElement = xmlDoc.createElement("Document");

        let placeMarkElement = xmlDoc.createElement("PlaceMark");
        
        let polygonElement = xmlDoc.createElement("Polygon");

        let outerBoundaryElement = xmlDoc.createElement("outerBoundaryIs");

        let linearRingElement = xmlDoc.createElement("LinearRing");

        let tessellateElement = xmlDoc.createElement("tessellate");
        tessellateElement.innerHTML = "1";

        let coordinatesElement = xmlDoc.createElement("coordinates");
        let value: string = "";
        for(let coordinate of this.coordinates) {
            value += coordinate.lng + ",";
            value += coordinate.lat + ",";
            value += 0 + "\n";
        }
        coordinatesElement.innerHTML = value;

        linearRingElement.appendChild(tessellateElement);
        linearRingElement.appendChild(coordinatesElement);
        outerBoundaryElement.appendChild(linearRingElement);
        polygonElement.appendChild(outerBoundaryElement);
        if(this.name) {
            let placeMarkName = xmlDoc.createElement("name");
            placeMarkName.innerHTML = this.name;
            placeMarkElement.appendChild(placeMarkName);
        }
        if(this.description) {
            let placeMarkDescription = xmlDoc.createElement("description");
            placeMarkDescription.innerHTML = this.description;
            placeMarkElement.appendChild(placeMarkDescription);
        }
        placeMarkElement.appendChild(polygonElement);
        if(this.name) {
            let documentName = xmlDoc.createElement("name");
            documentName.innerHTML = this.name;
            documentElement.appendChild(documentName);
        }
        documentElement.appendChild(placeMarkElement);
        kmlElement[0].appendChild(documentElement);
        
        let serializer = new XMLSerializer();
        let xmlString = serializer.serializeToString(xmlDoc);
        let format = require('xml-formatter');
        xmlString = format(xmlString, {
            indentation: '  ', 
            filter: (node) => node.type !== 'Comment', 
            collapseContent: true, 
            lineSeparator: '\n'
        });
        return xmlString;
    }

}