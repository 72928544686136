import {Injectable} from "@angular/core";
import { Stomp } from '@stomp/stompjs';
import * as SockJS from 'sockjs-client/dist/sockjs';

@Injectable({
    providedIn: 'root'
})
export class WebSocketService {

    // Open connection with the back-end websocket
    connect(url: string): any {
        let socket = new SockJS(url);
        let stompClient = Stomp.over(socket);
        return stompClient;
    }
}