import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Alerts } from 'src/app/shared/enums/alerts';
import { SharedService } from 'src/app/shared/shared.service';
import { GeofenceService } from '../geofence.service';

import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
    selector: 'app-update-geofence',
    templateUrl: './update-geofence.component.html',
    styleUrls: ['./update-geofence.component.scss']
})
export class UpdateGeofenceComponent implements OnInit {

    fieldcheck: boolean = false;
    count: number = 1;
    status: string;
    selectedGeofences: Array<any> = [];
    selectedGeofence: any;
    resetData: Array<any> = [];
    capabilities: Array<any> = [
        { "name": "Location 2D", "status": "disabled" },
        { "name": "Location 3D", "status": "disabled" },
        { "name": "Geofence Query", "status": "disabled" },
        { "name": "ADR Data", "status": "disabled" }
    ];
    statuses: Array<string> = ["none", "disabled", "enabled", "active", "suspended"];

    constructor(@Inject(MAT_DIALOG_DATA) private data,
                public dialogRef: MatDialogRef<UpdateGeofenceComponent>,
                public dialog: MatDialog,
                private sharedService: SharedService,
                private geofenceService: GeofenceService) { 
    }

    ngOnInit() {
        this.selectedGeofences = this.data.geofences;
        this.status = this.data.status;
        if(this.selectedGeofences.length === 1) {
            this.selectedGeofence = this.selectedGeofences[0];
            if(this.selectedGeofence.capabilities) {
                this.capabilities.forEach(capability => {
                    let isExist = false;
                    this.selectedGeofence.capabilities.forEach(cap => {
                        if(capability.name === cap.name) {
                            capability.status = cap.status;
                            isExist = true;
                        }
                    });
                    if(!isExist) {
                        capability.status = "none";
                    }
                });
                this.resetData = JSON.parse(JSON.stringify(this.capabilities));
            }
            
        }
    }

    updateGeofence() {
        let geofenceIds: Array<string> = [];
        this.selectedGeofences.forEach(geofence => {
            geofenceIds.push(geofence.geofenceId);
        });
        let capabilities: Array<any> = [];
        this.capabilities.forEach(capability => {
            if(capability.status !== "none") {
                capabilities.push(capability);
            }
        });
        let data: any = {
            geofenceIds: geofenceIds,
            capabilities: capabilities,
            status: this.status
        };
        Swal.fire({
            title: 'Please Wait...',
            text: 'Updating geofence capabilities',
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });
        this.geofenceService.updateGeofences(data).subscribe({
            next: data => {
                Swal.close();
                this.sharedService.showAlert(Alerts.SUCCESS, "Geofence(s) updated successfully");
                this.dialogRef.close(true);
            },
            error: error => {
                Swal.close();
                this.sharedService.showAlert(Alerts.ERROR, error.error.message);
                this.dialogRef.close(false);
            },
            complete: () => { }
        });
    }

    resetGeofence() {
        if (this.fieldcheck === true) {
            Swal.fire({
                title: 'Are you sure?',
                text: 'Your changes will be lost!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes, Reset',
                cancelButtonText: 'No, Cancel',
            }).then((result) => {
                if (result.value) {
                    this.capabilities = JSON.parse(JSON.stringify(this.resetData));
                    this.fieldcheck = false;
                }
            });
        } else {
            this.capabilities = JSON.parse(JSON.stringify(this.resetData));
        }
    }

    close() {
        if (this.fieldcheck === true) {
            Swal.fire({
                title: 'Are you sure?',
                text: 'Your changes will not be saved !',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes, Close',
                cancelButtonText: 'No, Cancel',
            }).then((result) => {
                if (result.value) {
                    this.dialogRef.close(false);
                    this.fieldcheck = false;
                }
            });
        } else {
            this.dialogRef.close(false);
        }
    }

    change(event) {
        if (event.srcElement.value !== '') {
            this.count = this.count + 1;
        } else if (event.srcElement.value === '') {
            this.count = this.count - 1;
        }
        if (this.count <= 1 || this.fieldcheck === false) {
            this.fieldcheck = !this.fieldcheck;
        }
    }
}

