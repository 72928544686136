// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `th {
  padding: 0.5rem;
}

td {
  padding: 0.5rem;
  white-space: pre;
}`, "",{"version":3,"sources":["webpack://./src/app/power-search/home-data-results/details/location-details-dialog.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;AACJ;;AAEE;EACE,eAAA;EACA,gBAAA;AACJ","sourcesContent":["th {\n    padding: 0.5rem;\n  }\n  \n  td {\n    padding: 0.5rem;\n    white-space: pre;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
