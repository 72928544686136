// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-fields-width {
  width: 100%;
}

.sso-user {
  margin: 10px 0px -5px -3px;
  color: rgba(0, 0, 0, 0.54);
}

.roleSelector {
  width: 470px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/users/select-roles/select-roles.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AAEA;EACE,0BAAA;EACA,0BAAA;AACF;;AAEA;EACE,uBAAA;AACF","sourcesContent":[".form-fields-width {\n  width: 100%;\n}\n\n.sso-user {\n  margin: 10px 0px -5px -3px;\n  color: rgba(0, 0, 0, 0.54);\n}\n\n.roleSelector {\n  width : 470px !important\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
