// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mapLocationDropdown {
  margin: 10px;
}

.selectLocation {
  background-color: white;
  border: none;
  border-radius: 0.5;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  width: 300px;
  height: 40px;
  font-size: 16px;
  padding: 10px;
  outline: none;
}

.optionLocation {
  background-color: white;
  border: none;
  border-radius: 0;
  width: 150px;
  height: 40px;
  font-size: 16px;
  padding: 10px;
}

.buttonLinkPeople {
  padding: 0;
  border: 0;
}

th {
  padding: 0.5rem;
}

td {
  padding: 0.5rem;
  white-space: pre;
}`, "",{"version":3,"sources":["webpack://./src/app/power-search/home-data-results/home-data-results.component.scss"],"names":[],"mappings":"AACA;EACE,YAAA;AAAF;;AAGA;EACE,uBAAA;EACA,YAAA;EACA,kBAAA;EACA,+CAAA;EACA,YAAA;EACA,YAAA;EACA,eAAA;EACA,aAAA;EACA,aAAA;AAAF;;AAGA;EACE,uBAAA;EACA,YAAA;EACA,gBAAA;EACA,YAAA;EACA,YAAA;EACA,eAAA;EACA,aAAA;AAAF;;AAGA;EACE,UAAA;EACA,SAAA;AAAF;;AAGA;EACE,eAAA;AAAF;;AAGA;EACE,eAAA;EACA,gBAAA;AAAF","sourcesContent":["\n.mapLocationDropdown {\n  margin: 10px;\n}\n\n.selectLocation{\n  background-color: white;\n  border: none;\n  border-radius: 0.5;\n  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;    \n  width: 300px;\n  height: 40px;\n  font-size: 16px;\n  padding: 10px;\n  outline: none;\n}\n\n.optionLocation{\n  background-color: white;\n  border: none;\n  border-radius: 0;\n  width: 150px;\n  height: 40px;\n  font-size: 16px;\n  padding: 10px;\n}\n\n.buttonLinkPeople{\n  padding: 0;\n  border: 0;\n}\n\nth {\n  padding: 0.5rem;\n}\n\ntd {\n  padding: 0.5rem;\n  white-space: pre;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
