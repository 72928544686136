export enum Patterns {
    URL='^https?://[^\\s]+$',
    ALPHANUMERIC='[A-Z,a-z,0-9]+',
    ALPHANUMERICPLUS='[A-Z,a-z,0-9.-]+',
    ALPHANUMERICPLUSSPECIAL='([A-Za-z0-9_.-]+)',
    SCHEMA_FILE='^.*\.(json|xsd|zip)$',
    JSON_FILE='^.*\.(json)$',
    HTML_FILE='^.*\.(html)$',
    XSLT_FILE='^.*\.(xslt)$',
    PHONE='^\\+?(?:[0-9]?){6,14}[0-9]$',
    TEXT_BLOCK_NAME='^[^,]+$'
}
