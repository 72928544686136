// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.border {
  border: 1px solid black;
}

.col-12.mt-3.ng-star-inserted {
  margin-bottom: 16px !important;
}

.example-button-row button {
  margin-right: 8px;
}

.title-center {
  margin: 0 auto;
}

.medium-tall {
  min-height: 40px !important;
  height: 40px !important;
}

.mapDivCenter {
  margin-top: 10px;
}

.bg-white {
  background-color: white !important;
  border: 1px solid white !important;
  color: black !important;
}

.link {
  color: #582D82;
  cursor: pointer;
}

.warning {
  background-color: orange;
}

.scrollbar {
  height: 40vh;
}

.crawlerDataHeight {
  max-height: 52vh;
}`, "",{"version":3,"sources":["webpack://./src/app/power-search/commercial-results/commercial-results.component.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;AACF;;AAEA;EACE,8BAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,cAAA;AACF;;AAEA;EACE,2BAAA;EACA,uBAAA;AACF;;AAGA;EACE,gBAAA;AAAF;;AAGA;EACE,kCAAA;EACA,kCAAA;EACA,uBAAA;AAAF;;AAIA;EACE,cAAA;EACA,eAAA;AADF;;AAIA;EACE,wBAAA;AADF;;AAIA;EACE,YAAA;AADF;;AAIA;EACE,gBAAA;AADF","sourcesContent":[".border {\r\n  border: 1px solid black;\r\n}\r\n\r\n.col-12.mt-3.ng-star-inserted {\r\n  margin-bottom: 16px !important;\r\n}\r\n\r\n.example-button-row button {\r\n  margin-right: 8px;\r\n}\r\n\r\n.title-center {\r\n  margin: 0 auto;\r\n}\r\n\r\n.medium-tall {\r\n  min-height: 40px !important;\r\n  height: 40px !important;\r\n}\r\n\r\n\r\n.mapDivCenter {\r\n  margin-top: 10px;\r\n}\r\n\r\n.bg-white {\r\n  background-color: white !important;\r\n  border: 1px solid white !important;\r\n  color: black !important;\r\n\r\n}\r\n\r\n.link {\r\n  color: #582D82;\r\n  cursor: pointer;\r\n}\r\n\r\n.warning {\r\n  background-color: orange;\r\n}\r\n\r\n.scrollbar {\r\n  height: 40vh;\r\n}\r\n\r\n.crawlerDataHeight {\r\n  max-height: 52vh;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
