// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-mdc-header-cell {
  font-weight: bold;
  font-size: 14px;
}

.custom-label {
  font-size: 14px;
  color: #4d4d4d;
}

.cursor {
  cursor: pointer;
}

.data {
  background-color: white;
}

.nodata {
  color: #a0a0a0;
  pointer-events: none;
}

.form-fields-width {
  width: 100%;
  font-size: 15px;
  padding: 0;
}

.date-filter {
  line-height: 25px;
}`, "",{"version":3,"sources":["webpack://./src/app/power-search/search-history/search-history.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,eAAA;AACF;;AAEA;EACE,eAAA;EACA,cAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,uBAAA;AACF;;AASA;EACE,cAAA;EACA,oBAAA;AANF;;AASA;EACE,WAAA;EACA,eAAA;EACA,UAAA;AANF;;AASA;EACE,iBAAA;AANF","sourcesContent":[".mat-mdc-header-cell {\n  font-weight: bold;\n  font-size: 14px;\n}\n\n.custom-label {\n  font-size: 14px;\n  color:  #4d4d4d;;\n}\n\n.cursor {\n  cursor: pointer;\n}\n\n.data {\n  background-color: white;\n}\n\n// ::ng-deep .fulfilling-bouncing-circle-spinner {\n//   margin-top: 50px;\n//   height: 50px;\n//   width: 50px;\n//   animation-duration: 4000ms;\n// }\n\n.nodata {\n  color: #a0a0a0;\n  pointer-events: none;\n}\n\n.form-fields-width {\n  width: 100%;\n  font-size: 15px;\n  padding: 0;\n}\n\n.date-filter {\n  line-height: 25px;\n}\n\n// ::ng-deep .mdc-floating-label {\n//   top: 40px !important;\n//   left: -15px !important;\n// }\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
