// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `tr.cursor-pointer:hover:not(.data-selected) {
  background: whitesmoke;
}

.data-selected {
  background: #e5e6e8 !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.mat-button-toggle-group {
  align-items: center;
  font-size: 14px;
  font-weight: normal;
  height: 40px;
}

.fas {
  font-size: 14px;
  font-style: inherit;
  font-family: "Font Awesome 5 Free", sans-serif;
  display: revert;
  vertical-align: text-bottom;
}

.fa-industry {
  color: blue;
}

.fa-shield-alt {
  color: red;
}

.fa-tools {
  color: green;
}

.fa-building {
  font-size: 24px;
}`, "",{"version":3,"sources":["webpack://./src/app/organizations/list-parent-organizations/list-parent-organizations.component.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;AACF;;AAEA;EACE,8BAAA;AACF;;AAEA,6HAAA;AACA;EACE,mBAAA;EACA,eAAA;EACA,mBAAA;EACA,YAAA;AACF;;AAEA;EACE,eAAA;EACA,mBAAA;EACA,8CAAA;EACA,eAAA;EACA,2BAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,UAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,eAAA;AACF","sourcesContent":["tr.cursor-pointer:hover:not(.data-selected) {\n  background: whitesmoke;\n}\n\n.data-selected {\n  background: #e5e6e8 !important;\n}\n\n/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */\n.mat-button-toggle-group {\n  align-items: center;\n  font-size: 14px;\n  font-weight: normal;\n  height: 40px;\n}\n\n.fas {\n  font-size: 14px;\n  font-style: inherit;\n  font-family: 'Font Awesome\\ 5 Free', sans-serif;\n  display: revert;\n  vertical-align: text-bottom;\n}\n\n.fa-industry {\n  color: blue;\n}\n\n.fa-shield-alt {\n  color: red;\n}\n\n.fa-tools {\n  color: green;\n}\n\n.fa-building {\n  font-size: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
