import { UntypedFormControl, Validators } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { Alerts } from "../shared/enums/alerts";
import { Patterns } from "../shared/enums/patterns";
import { UsageEnum } from "../shared/enums/usageEnum";
import { SearchTypeTo } from "../shared/models/searchTypeTo";
import { TemplateFileTo } from "../shared/models/templateFileTo";
import { TextBlock } from "../shared/models/textBlock";
import { TextblockFileTo } from "../shared/models/textblockFileTo";
import { SharedService } from "../shared/shared.service";

/**
 * Common base class for create and update search type components.
 */
export abstract class SearchTypeBase {
    nameFC = new UntypedFormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(20)]);
    entitlementFC = new UntypedFormControl('', [Validators.required]);
    responseTypeFC = new UntypedFormControl('', [Validators.required]);
    telephoneUsageFC = new UntypedFormControl([], [Validators.required]);
    addressUsageFC = new UntypedFormControl([], [Validators.required]);
    incidentUsageFC = new UntypedFormControl([], [Validators.required]);
    searchTypeUsageFC = new UntypedFormControl([], [Validators.required]);
    locationUsageFC = new UntypedFormControl([], [Validators.required]);
    responseSchemaFileFC = new UntypedFormControl('', [Validators.required, Validators.pattern(Patterns.SCHEMA_FILE)]);
    responseSchemaBaseFilePathFC = new UntypedFormControl('', [Validators.required]);
    uiTemplateFileFC = new UntypedFormControl('', [Validators.pattern(Patterns.HTML_FILE)]);
    fieldcheck: boolean = false;
    tab1Error: boolean = false;
    tab3Error: boolean = false;

    entitlementList: any[] = [];
    textBlockList: TextBlock[] = [];
    selectedTextBlock: string;
    responseTypes: string[] = ["JSON", "XML"];
    usageEnumList: string[] = [UsageEnum.MANDATORY, UsageEnum.NOT_USED, UsageEnum.OPTIONAL];
    
    searchTypeData: SearchTypeTo;
    textblocksFileDisplayedColumns: string[] = ['default', 'name', 'sourceFilename', 'options'];
    textblocksFileDataSource;

    readonly NAME: string = 'name';
    readonly ENTITLEMENT: string = 'entitlement';
    readonly RESPONSETYPE: string = 'responseType';
    readonly RESPONSESCHEMAFILE: string = 'responseSchemaFile';
    readonly RESPONSESCHEMABASEFILEPATH: string = 'responseSchemaBaseFilePath';
    readonly UITEMPLATEFILE: string = 'uiTemplateFile';
    readonly LOCATIONUSAGE: string = 'locationUsage';
    readonly TELEPHONEUSAGE: string = 'telephoneUsage';
    readonly ADDRESSUSAGE: string = 'addressUsage';
    readonly INCIDENTUSAGE: string = 'incidentUsage';
    readonly SEARCHTYPEUSAGE: string = "searchTypeUsage"

    constructor(protected sharedService: SharedService) {

    };

    getErrorMessage(formControl) {
        if (formControl === this.NAME) {
            return this.nameFC.hasError('required') ? 'You must enter a value' : 'Enter a valid name (2-20 characters)';
        } else if (formControl === this.ENTITLEMENT) {
            return this.entitlementFC.hasError('required') ? 'You must select a value' : 'Enter a valid entitlement';
        } else if (formControl === this.RESPONSETYPE) {
            return this.responseTypeFC.hasError('required') ? 'You must select a value' : 'Enter a valid responseType';
        } else if (formControl === this.RESPONSESCHEMAFILE) {
            return this.responseSchemaFileFC.hasError('required') ? 'You must upload a schema file' : 'Upload a valid response schema file (.xsd, .json or .zip)';
        } else if (formControl === this.RESPONSESCHEMABASEFILEPATH) {
            return this.responseSchemaBaseFilePathFC.hasError('required') ? 'You must enter a value' : 'Enter a valid path';
        } else if (formControl === this.LOCATIONUSAGE) {
            return this.locationUsageFC.hasError('required') ? 'You must select a value' : '';
        } else if (formControl === this.TELEPHONEUSAGE) {
            return this.telephoneUsageFC.hasError('required') ? 'You must select a value' : '';
        } else if (formControl === this.ADDRESSUSAGE) {
            return this.addressUsageFC.hasError('required') ? 'You must select a value' : '';
        } else if (formControl === this.INCIDENTUSAGE) {
            return this.incidentUsageFC.hasError('required') ? 'You must select a value' : '';
        } else if (formControl === this.SEARCHTYPEUSAGE) {
            return this.searchTypeUsageFC.hasError('required') ? 'You must select a value' : '';
        } else if (formControl === this.UITEMPLATEFILE) {
            return this.uiTemplateFileFC.hasError('required') ? 'You must enter a value' : 'Upload a valid uiTemplate file (.html)';
        }
    }

    markFormControlsAsTouched() {
        this.nameFC.markAsTouched();
        this.entitlementFC.markAsTouched();
        this.responseTypeFC.markAsTouched();
        this.locationUsageFC.markAsTouched();
        this.telephoneUsageFC.markAsTouched();
        this.addressUsageFC.markAsTouched();
        this.incidentUsageFC.markAsTouched();
        this.searchTypeUsageFC.markAsTouched();
        this.responseSchemaFileFC.markAsTouched();
        this.responseSchemaBaseFilePathFC.markAsTouched();
        this.uiTemplateFileFC.markAsTouched();
    }

    onTemplateFileUpload(files: any, templateFile: TemplateFileTo) {
        if (files && files.length > 0) {
            templateFile.sourceFilename = files[0].name;
            templateFile.sourceFile = files.item(0);
        
            if (templateFile.name=="ResponseSchema") {
                templateFile.destinationFilename = templateFile.sourceFilename;
                if(templateFile.sourceFile.type=="application/xml") {
                    this.searchTypeData.responseType = "XML";
                    this.searchTypeData.responseSchemaBaseFilePath = "";
                } else if(templateFile.sourceFile.type=="application/json") {
                    this.searchTypeData.responseType = "JSON";
                    this.searchTypeData.responseSchemaBaseFilePath = "";
                }
            } if (templateFile.name=="UITemplate") {
                templateFile.destinationFilename = templateFile.sourceFilename;
            }
            this.fieldcheck = true;
        }
    }
    
    removeTemplateFile(templateFile: TemplateFileTo) {
        templateFile.sourceFile = null;
        templateFile.sourceFilename = '';
    }

    isZipFile(fileName: string) : boolean {
        return fileName?.endsWith('.zip');
    }

    onTextblocksFileUpload(files: any, textblock: TextblockFileTo) {
        if (files && files.length > 0) {
            textblock.sourceFilename = files[0].name;
            textblock.sourceFile = files.item(0);
            textblock.destinationFilename = textblock.sourceFilename;
        }
    }

    addTextblocks() {
        if(this.selectedTextBlock) {
            this.searchTypeData.textblocksFileList.push(
                new TextblockFileTo({
                    name:this.selectedTextBlock, 
                    sourceFilename:'', 
                    destinationFilename:'', 
                    sourceFile: null,
                    defaultTemplate: false
                })
            );
            this.textblocksFileDataSource = new MatTableDataSource<TextblockFileTo>(this.searchTypeData.textblocksFileList);
            this.selectedTextBlock = null;
        }
    }

    deleteTextblocks(textblocksFile: TextblockFileTo) {
        const index = this.searchTypeData.textblocksFileList.indexOf(textblocksFile);
        if (index > -1) {
            this.searchTypeData.textblocksFileList.splice(index, 1);
         }
        this.textblocksFileDataSource = new MatTableDataSource<TextblockFileTo>(this.searchTypeData.textblocksFileList);
    }

    isTextblocksListInvalid(): boolean {
        let isInvalid: boolean = false;
        let fileNames = new Set<string>();
        let textblockNames = new Set<string>();
        this.searchTypeData.textblocksFileList.forEach( (textblock) => {
            if (textblock.name === '' || textblock.sourceFilename === '' || textblock.destinationFilename === '') {
                this.sharedService.showAlert(Alerts.ERROR, "Please verify empty values in the 'Text Blocks' tab");
                isInvalid = true;
            }
            //Verify that there is no duplicate textblock
            if(textblockNames.has(textblock.name)) {
                this.sharedService.showAlert(Alerts.ERROR, "Duplicate Textblock Name detected in the 'Text Blocks' tab");
                isInvalid =  true;
            } else {
                fileNames.add(textblock.name);
            }
            //Verify that there is no duplicate file name
            if(fileNames.has(textblock.destinationFilename)) {
                this.sharedService.showAlert(Alerts.ERROR, "Duplicate Source File Name detected in the 'Text Blocks' tab");
                isInvalid =  true;
            } else {
                fileNames.add(textblock.destinationFilename);
            }
        });
        return isInvalid;
    }

    isValidData(isCreateAction: boolean): boolean {
        this.markFormControlsAsTouched();
        this.tab1Error = (isCreateAction && this.nameFC.invalid) || this.entitlementFC.invalid || this.responseTypeFC.invalid || this.locationUsageFC.invalid || 
                         this.telephoneUsageFC.invalid || this.addressUsageFC.invalid || this.incidentUsageFC.invalid || 
                         this.searchTypeUsageFC.invalid || this.responseSchemaFileFC.invalid || this.uiTemplateFileFC.invalid ||
                         (this.isZipFile(this.searchTypeData.responseSchemaFile.sourceFilename) &&  this.responseSchemaBaseFilePathFC.invalid);

        this.tab3Error = this.isTextblocksListInvalid();
        
        if(this.tab1Error) {
            this.sharedService.showAlert(Alerts.ERROR, 'Please provide the correct details to create search type');
            this.markFormControlsAsTouched();
            return false;
        }
        if(this.tab3Error) {
            this.markFormControlsAsTouched();
            return false;
        }
        return true;
    }

    pathnames() {
        return Object.keys(this.searchTypeData.attributePathList);
    }

}