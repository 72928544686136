// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.drawerContainer {
  border-bottom: solid 1px #dfdfdf;
  box-shadow: 0 0px 1px -5px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.1411764706), 0 1px 1px rgba(0, 0, 0, 0.1215686275);
  height: 88px;
  overflow: hidden;
  transition: height 0.5s ease;
}

.drawerToggler {
  position: absolute;
  margin-left: 75px;
  z-index: 4;
  padding: 0px;
  min-width: 50px;
  max-width: 50px;
  min-height: 25px;
  max-height: 25px;
  border-top: none;
  border-radius: 0px 0px 4px 4px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), -2px 2px 0px 0px rgba(0, 0, 0, 0.14), 2px 2px 0px 0px rgba(0, 0, 0, 0.12);
}

.primary-btn {
  background-color: #00677F !important;
  color: white !important;
  cursor: pointer !important;
}

.disable-btn {
  background-color: #89b5c0 !important;
  color: white !important;
  cursor: not-allowed !important;
}

.warn-btn {
  background-color: #dd6b55 !important;
  color: white !important;
  cursor: pointer !important;
}`, "",{"version":3,"sources":["webpack://./src/app/zero-touch/zero-touch.component.scss"],"names":[],"mappings":"AAAA;EACI,gCAAA;EACA,2HAAA;EACA,YAAA;EACA,gBAAA;EACA,4BAAA;AACJ;;AAEA;EACI,kBAAA;EACA,iBAAA;EACA,UAAA;EACA,YAAA;EACA,eAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,gBAAA;EACA,8BAAA;EACA,yHAAA;AACJ;;AACA;EACI,oCAAA;EACA,uBAAA;EACA,0BAAA;AAEJ;;AACA;EACI,oCAAA;EACA,uBAAA;EACA,8BAAA;AAEJ;;AACA;EACI,oCAAA;EACA,uBAAA;EACA,0BAAA;AAEJ","sourcesContent":[".drawerContainer {\n    border-bottom: solid 1px #dfdfdf;\n    box-shadow: 0 0px 1px -5px #0003, 0 2px 2px #00000024, 0 1px 1px #0000001f;\n    height: 88px;\n    overflow: hidden;\n    transition: height 0.5s ease;\n}\n\n.drawerToggler {\n    position: absolute;\n    margin-left: 75px;\n    z-index: 4;\n    padding: 0px;\n    min-width: 50px;\n    max-width: 50px;\n    min-height: 25px;\n    max-height: 25px;\n    border-top: none;\n    border-radius: 0px 0px 4px 4px;\n    box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 20%), -2px 2px 0px 0px rgb(0 0 0 / 14%), 2px 2px 0px 0px rgb(0 0 0 / 12%);\n}\n.primary-btn {\n    background-color: #00677F !important;\n    color: white !important;\n    cursor: pointer !important;\n}\n\n.disable-btn {\n    background-color: #89b5c0 !important;\n    color: white !important;\n    cursor: not-allowed !important;\n}\n\n.warn-btn {\n    background-color: #dd6b55 !important;\n    color: white !important;\n    cursor: pointer !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
