// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.details-container {
  min-width: 50vh;
  min-height: 30vh;
  max-height: 70vh;
  overflow-y: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/power-search/commercial-locate-results/details/commercial-details-dialog.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,gBAAA;AACF","sourcesContent":[".details-container {\n  min-width: 50vh;\n  min-height: 30vh;\n  max-height: 70vh;\n  overflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
