export class VehicleSearchRequestTo {
    vin: String;
    plate: String;
    state = null;

    constructor(object: any) {
        if (object.hasOwnProperty('vin') && object.vin)
            this.vin = object.vin;
        if (object.hasOwnProperty('plate') && object.plate)
            this.plate = object.plate;
        if (object.hasOwnProperty('state') && object.state)
            this.state = object.state;
    }
}
