import {Component, Inject, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BrokerSearch } from 'src/app/shared/models/brokerSearch';
import { CivicAddressTo } from 'src/app/shared/models/CivicAddressTo';
import { PhoneTo } from 'src/app/shared/models/phoneTo';

@Component({
    selector: 'commercial-details-dialog',
    templateUrl: 'commercial-details-dialog.html',
    styleUrls: ['./commercial-details-dialog.scss']
})
export class CommercialSearchDetailsDialog implements OnInit {
    header = "Commercial Locate Details"
    // Fix for expansion panel flicker -- see: // https://stackblitz.com/edit/angular-issue13870-workaround
    disableAnimation = true;
    brokerSearch: BrokerSearch;
    threatScoreColor = '';    
    physicalAttributesString: string;
    
    constructor(
      public dialogRef: MatDialogRef<CommercialSearchDetailsDialog>,
      @Inject(MAT_DIALOG_DATA) public data) {
        this.brokerSearch = this.data.brokerSearch;
    }

    ngOnInit():void {}
    ngAfterViewInit(): void {
        // timeout required to avoid the dreaded 'ExpressionChangedAfterItHasBeenCheckedError'
        setTimeout(() => this.disableAnimation = false);
    }

  
    cancel() {
      this.dialogRef.close();
    }
      
    addressLabel(): string {
        return 'Phone Owner\'s Address';
    }



    getThreatScoreColor(threatLevel) {
        if (threatLevel == "THREAT_LOW") {
            this.threatScoreColor = 'green';
        } else if (threatLevel == "THREAT_MEDIUM") {
            this.threatScoreColor = '#ffbf00';
        } else if (threatLevel == "THREAT_HIGH") {
            this.threatScoreColor = 'red';
        } else {
            this.threatScoreColor = 'black';
        }
        return this.threatScoreColor;
    }
      
    fullName(name) {
        return [name.firstName, name.middleName, name.lastName, name.nameSuffix]
            .filter((val) => val).join(' ');
    }

    age(dob) {
        if (dob && dob.age) {
            return ', ' + dob.age + ' years old';
        } else {
            return '';
        }
    }

    callerName(name) {
        return [name.firstName, name.middleName, name.lastName, name.nameSuffix]
            .filter((val) => val).join(' ');
    }

    callerResidence(city, state) {
        return [city, state]
            .filter((val) => val).join(', ');
    }

    aliasName(name) {
        let middleName = name.middleName;
        if (middleName) {
            middleName = middleName.charAt(0);
        }
        return [name.lastName + ', ' + name.firstName, middleName]
            .filter((val) => val).join(' ');
    }

    getDOB(dob) {
        if (dob) {
            return dob.month + '/' + 'XX' + '/' + dob.year;
        } else {
            return 'unknown';
        }
    }

    
    getPhones(phones) {
        let i = 0;
        let retval = '';
        for (const phone of phones) {
            i = i + 1;
            if (i <= 3) {
                if (retval !== '') {
                    retval += '\r\n';
                }
                retval += this.getPhone(phone);
                if (phone.phoneType) {
                    retval += (phone.phoneType.startsWith('WIRELINE') ? ' (Wireline)' : ' (Unknown)');
                }
            }
        }
        return retval;
    }

    getPhone(phone: PhoneTo) {
        if (phone) {
            return this.formatPhone(phone.number);
        } else {
            return ' ';
        }
    }

    getRequestedPhone() {
        return this.formatPhone(this.brokerSearch.searchRequest.phone);
    }

    formatPhone(number: string): string {
        return '(' + number.substring(0, 3) + ') ' + number.substring(3, 6) + '-' + number.substring(6, 10);
    }

    getAddress(addr): string {
        if (addr) {
            var address = "";
            if (addr.hno){
                address = address + ' ' + addr.hno;
            }
            if (addr.hns){
                address = address + ' ' + addr.hns;
            }
            if (addr.prd){
                address = address + ' ' + addr.prd;
            }
            if (addr.rd){
                address = address + ' ' + addr.rd;
            }
            if (addr.sts){
                address = address + ' ' + addr.sts;
            }
            if (addr.pod){
                address = address + ' ' + addr.pod;
            }
            if (addr.unit){
                address = address + ', ' + addr.unit;
            }
            if (addr.loc){
                address = address + ' ' + addr.loc;
            }
            if (addr.a3){
                address = address + ', ' + addr.a3;
            }
            if (addr.a1){
                address = address + ', ' + addr.a1;
            }
            if (addr.pc){
                address = address + ' ' + addr.pc;
            }
            return address.trim();
        }
    }

    getDateLastReported(dateLastReported) {
        if (dateLastReported) {
            return dateLastReported.month + '/' + dateLastReported.day + '/' + dateLastReported.year;
        } else {
            return 'unknown';
        }
    }
    

    physicalAttributes(criminalRecords): string {
        this.physicalAttributesString = '';
        if (criminalRecords !== undefined && criminalRecords.length != null &&
            criminalRecords.length && criminalRecords[0].physicalAttributes) {
            for (const criminalRecord of criminalRecords) {
                this.physicalAttributesString = '';
                this.toMap(criminalRecord.physicalAttributes).forEach((v, k) => {
                    if (this.physicalAttributesString) {
                        this.physicalAttributesString += ', ';
                    }
                    this.physicalAttributesString += (k + '/' + v.toLowerCase());
                });
            }
        }
        return this.physicalAttributesString;
    }
    
    filterItemsOfType(state, data, type) {
        if (type === 'Nearby') {
            return data.filter(item => item.addresses[0] && item.addresses[0].a1 === state);
        } else {
            return data.filter(item => item.addresses[0] && item.addresses[0].a1 !== state);
        }
    }

    toMap(item: any): Map<string, string> {
        const map = new Map();
        Object.keys(item).filter(k => item[k] != null && k !== 'sourceType').forEach(k => map.set(k, item[k]));
        return map;
    }

  }
