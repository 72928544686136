
export class SearchSummary  {
    
    uniqueSearchId: string;
    searchType: string;
    provider: string;
    incidentId: string;
    searchRequest: any;
    sensitiveData: boolean;
    dataFound: boolean;
    errorFound: boolean;
    errorText: string;
    sensitiveDataSearchDone: boolean;
    userId: string;
    orgId: string;
    timestamp: any;

    formatSearchType():string {
        switch (this.searchType) {
            case 'Commercial Address': return "Commercial Data Address Search";
            case 'Commercial Phone': return "Commercial Data Phone Search";
            case 'Commercial Vehicle': return "Commercial Vehicle Search";
            case 'Commercial Locate': return "Commercial Data Call Locate";
            case 'Safety Shield': return "Safety Shield Search";
            case 'Device-Based Hybrid': return "DBH Search";
            case 'Home Data': return "Home Data Search";
            case 'Alarm': return "Alarm Event Search";
        }
    }

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }
}
