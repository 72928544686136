export class SupplementalDataProviderConfigTo {

    requestTemplate: any;
    inputAdditionalData: boolean;
    inputSourceArray: boolean;
    sourcePath: string;
    destinationPath: string;

    constructor(object?: any) {
        this.requestTemplate = object?.requestTemplate;
        this.inputAdditionalData = object?.inputAdditionalData;
        this.inputSourceArray = object?.inputSourceArray;
        this.sourcePath = object?.sourcePath;
        this.destinationPath = object?.destinationPath;
    }

}