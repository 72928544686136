import { UntypedFormControl, Validators } from "@angular/forms";
import { Patterns } from "../shared/enums/patterns";
import { TextBlock } from "../shared/models/textBlock";

/**
 * Common base class for create and update text block components.
 */
export abstract class TextBlockBase {

    readonly NAME_MIN_LENGTH = 5;
    readonly NAME_MAX_LENGTH = 50;
    readonly DESCRIPTION_MAX_LENGTH = 500;
    readonly NAME = 'name';
    readonly DESCRIPTION = 'description';

    fieldcheck: boolean = false;
    count: number = 1;

    name = new UntypedFormControl('', [
        Validators.minLength(this.NAME_MIN_LENGTH), Validators.maxLength(this.NAME_MAX_LENGTH), Validators.required,
        Validators.pattern(Patterns.TEXT_BLOCK_NAME)
    ]);

    description = new UntypedFormControl('', [Validators.maxLength(this.DESCRIPTION_MAX_LENGTH)]);

    markFormControlsAsTouched() {
        this.name.markAsTouched();
    }

    isInValidData(textBlock: TextBlock): boolean {
        this.markFormControlsAsTouched();
        return this.name.invalid;
    }

    getErrorMessage(formControl) {
        if (formControl === this.NAME) {
            return this.name.hasError('required') ? 'You must enter a value' :  'Please enter a valid name';
        } else if (formControl === this.DESCRIPTION) {
            return this.name.hasError('required') ? 'You must enter a value' :  'Maximum length is '+ this.DESCRIPTION_MAX_LENGTH + ' for this field';
        }
    }
}