import {Component, Input, OnInit, SimpleChange, TemplateRef} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-pd-dialog',
  templateUrl: './pd-dialog.component.html',
  styleUrls: ['./pd-dialog.component.scss']
})
export class PdDialogComponent implements OnInit {
  @Input()
  contentTemplate: TemplateRef<any>;
  @Input()
  actionTemplate: TemplateRef<any>;
  @Input()
  dialogRef: MatDialogRef<any>;
  @Input()
  header
  @Input()
  title
  @Input()
  loading: boolean

  constructor() { }

  ngOnInit() {
    //console.log("pd-dlg: loading: " + this.loading);
  }
  ngOnChanges(changes: SimpleChange) {
    if (changes.hasOwnProperty('loading')) {
      this.loading = changes['loading'].currentValue;
    }
  }
}
